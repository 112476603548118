/*
	Это файл, содержащий пути подключения всех стилей.
	Стили в данном файле не пишется.

*/
/*
 * Third party
 */
* {
  box-sizing: border-box;
}
html {
  font-weight: normal;
}
:link,
:visited {
  text-decoration: none;
  color: inherit;
}
ul,
ol {
  list-style: none;
}
h1,
h2,
h3,
h4,
h5,
h6,
pre,
code {
  font-size: 1em;
}
ul,
ol,
li,
h1,
h2,
h3,
h4,
h5,
h6,
pre,
form,
body,
html,
p,
blockquote,
fieldset,
input {
  margin: 0;
  padding: 0;
}
a img,
:link img,
:visited img,
button {
  border: none;
}
address {
  font-style: normal;
}
input:focus,
input:active,
button {
  outline: none;
}
button {
  cursor: pointer;
}
/*
 * Custom
 */
/* 
	Файл переменных
 */
@font-face {
  font-family: 'Open Sans';
  font-weight: 300;
  font-style: normal;
  src: url('../fonts/Open-Sans-300/Open-Sans-300.eot');
  src: url('../fonts/Open-Sans-300/Open-Sans-300.eot?#iefix') format('embedded-opentype'), local('Open Sans Light'), local('Open-Sans-300'), url('../fonts/Open-Sans-300/Open-Sans-300.woff2') format('woff2'), url('../fonts/Open-Sans-300/Open-Sans-300.woff') format('woff'), url('../fonts/Open-Sans-300/Open-Sans-300.ttf') format('truetype'), url('../fonts/Open-Sans-300/Open-Sans-300.svg#OpenSans') format('svg');
}
@font-face {
  font-family: 'Open Sans';
  font-weight: 400;
  font-style: normal;
  src: url('../fonts/Open-Sans-regular/Open-Sans-regular.eot');
  src: url('../fonts/Open-Sans-regular/Open-Sans-regular.eot?#iefix') format('embedded-opentype'), local('Open Sans'), local('Open-Sans-regular'), url('../fonts/Open-Sans-regular/Open-Sans-regular.woff2') format('woff2'), url('../fonts/Open-Sans-regular/Open-Sans-regular.woff') format('woff'), url('../fonts/Open-Sans-regular/Open-Sans-regular.ttf') format('truetype'), url('../fonts/Open-Sans-regular/Open-Sans-regular.svg#OpenSans') format('svg');
}
@font-face {
  font-family: 'Open Sans';
  font-weight: 600;
  font-style: normal;
  src: url('../fonts/Open-Sans-600/Open-Sans-600.eot');
  src: url('../fonts/Open-Sans-600/Open-Sans-600.eot?#iefix') format('embedded-opentype'), local('Open Sans Semibold'), local('Open-Sans-600'), url('../fonts/Open-Sans-600/Open-Sans-600.woff2') format('woff2'), url('../fonts/Open-Sans-600/Open-Sans-600.woff') format('woff'), url('../fonts/Open-Sans-600/Open-Sans-600.ttf') format('truetype'), url('../fonts/Open-Sans-600/Open-Sans-600.svg#OpenSans') format('svg');
}
@font-face {
  font-family: 'Open Sans';
  font-weight: 700;
  font-style: normal;
  src: url('../fonts/Open-Sans-700/Open-Sans-700.eot');
  src: url('../fonts/Open-Sans-700/Open-Sans-700.eot?#iefix') format('embedded-opentype'), local('Open Sans Bold'), local('Open-Sans-700'), url('../fonts/Open-Sans-700/Open-Sans-700.woff2') format('woff2'), url('../fonts/Open-Sans-700/Open-Sans-700.woff') format('woff'), url('../fonts/Open-Sans-700/Open-Sans-700.ttf') format('truetype'), url('../fonts/Open-Sans-700/Open-Sans-700.svg#OpenSans') format('svg');
}
@font-face {
  font-family: 'Open Sans';
  font-weight: 800;
  font-style: normal;
  src: url('../fonts/Open-Sans-800/Open-Sans-800.eot');
  src: url('../fonts/Open-Sans-800/Open-Sans-800.eot?#iefix') format('embedded-opentype'), local('Open Sans Extrabold'), local('Open-Sans-800'), url('../fonts/Open-Sans-800/Open-Sans-800.woff2') format('woff2'), url('../fonts/Open-Sans-800/Open-Sans-800.woff') format('woff'), url('../fonts/Open-Sans-800/Open-Sans-800.ttf') format('truetype'), url('../fonts/Open-Sans-800/Open-Sans-800.svg#OpenSans') format('svg');
}
@font-face {
  font-family: 'Open Sans';
  font-weight: 300;
  font-style: italic;
  src: url('../fonts/Open-Sans-300italic/Open-Sans-300italic.eot');
  src: url('../fonts/Open-Sans-300italic/Open-Sans-300italic.eot?#iefix') format('embedded-opentype'), local('Open Sans Light Italic'), local('Open-Sans-300italic'), url('../fonts/Open-Sans-300italic/Open-Sans-300italic.woff2') format('woff2'), url('../fonts/Open-Sans-300italic/Open-Sans-300italic.woff') format('woff'), url('../fonts/Open-Sans-300italic/Open-Sans-300italic.ttf') format('truetype'), url('../fonts/Open-Sans-300italic/Open-Sans-300italic.svg#OpenSans') format('svg');
}
@font-face {
  font-family: 'Open Sans';
  font-weight: 400;
  font-style: italic;
  src: url('../fonts/Open-Sans-italic/Open-Sans-italic.eot');
  src: url('../fonts/Open-Sans-italic/Open-Sans-italic.eot?#iefix') format('embedded-opentype'), local('Open Sans Italic'), local('Open-Sans-italic'), url('../fonts/Open-Sans-italic/Open-Sans-italic.woff2') format('woff2'), url('../fonts/Open-Sans-italic/Open-Sans-italic.woff') format('woff'), url('../fonts/Open-Sans-italic/Open-Sans-italic.ttf') format('truetype'), url('../fonts/Open-Sans-italic/Open-Sans-italic.svg#OpenSans') format('svg');
}
@font-face {
  font-family: 'Open Sans';
  font-weight: 600;
  font-style: italic;
  src: url('../fonts/Open-Sans-600italic/Open-Sans-600italic.eot');
  src: url('../fonts/Open-Sans-600italic/Open-Sans-600italic.eot?#iefix') format('embedded-opentype'), local('Open Sans Semibold Italic'), local('Open-Sans-600italic'), url('../fonts/Open-Sans-600italic/Open-Sans-600italic.woff2') format('woff2'), url('../fonts/Open-Sans-600italic/Open-Sans-600italic.woff') format('woff'), url('../fonts/Open-Sans-600italic/Open-Sans-600italic.ttf') format('truetype'), url('../fonts/Open-Sans-600italic/Open-Sans-600italic.svg#OpenSans') format('svg');
}
@font-face {
  font-family: 'Open Sans';
  font-weight: 700;
  font-style: italic;
  src: url('../fonts/Open-Sans-700italic/Open-Sans-700italic.eot');
  src: url('../fonts/Open-Sans-700italic/Open-Sans-700italic.eot?#iefix') format('embedded-opentype'), local('Open Sans Bold Italic'), local('Open-Sans-700italic'), url('../fonts/Open-Sans-700italic/Open-Sans-700italic.woff2') format('woff2'), url('../fonts/Open-Sans-700italic/Open-Sans-700italic.woff') format('woff'), url('../fonts/Open-Sans-700italic/Open-Sans-700italic.ttf') format('truetype'), url('../fonts/Open-Sans-700italic/Open-Sans-700italic.svg#OpenSans') format('svg');
}
@font-face {
  font-family: 'Open Sans';
  font-weight: 800;
  font-style: italic;
  src: url('../fonts/Open-Sans-800italic/Open-Sans-800italic.eot');
  src: url('../fonts/Open-Sans-800italic/Open-Sans-800italic.eot?#iefix') format('embedded-opentype'), local('Open Sans Extrabold Italic'), local('Open-Sans-800italic'), url('../fonts/Open-Sans-800italic/Open-Sans-800italic.woff2') format('woff2'), url('../fonts/Open-Sans-800italic/Open-Sans-800italic.woff') format('woff'), url('../fonts/Open-Sans-800italic/Open-Sans-800italic.ttf') format('truetype'), url('../fonts/Open-Sans-800italic/Open-Sans-800italic.svg#OpenSans') format('svg');
}
@font-face {
  font-family: 'Open Sans Condensed';
  font-weight: 300;
  font-style: normal;
  src: url('../fonts/Open-Sans-Condensed-300/Open-Sans-Condensed-300.eot');
  src: url('../fonts/Open-Sans-Condensed-300/Open-Sans-Condensed-300.eot?#iefix') format('embedded-opentype'), local('Open Sans Cond Light'), local('Open-Sans-Condensed-300'), url('../fonts/Open-Sans-Condensed-300/Open-Sans-Condensed-300.woff2') format('woff2'), url('../fonts/Open-Sans-Condensed-300/Open-Sans-Condensed-300.woff') format('woff'), url('../fonts/Open-Sans-Condensed-300/Open-Sans-Condensed-300.ttf') format('truetype'), url('../fonts/Open-Sans-Condensed-300/Open-Sans-Condensed-300.svg#OpenSansCondensed') format('svg');
}
@font-face {
  font-family: 'Open Sans Condensed';
  font-weight: 700;
  font-style: normal;
  src: url('../fonts/Open-Sans-Condensed-700/Open-Sans-Condensed-700.eot');
  src: url('../fonts/Open-Sans-Condensed-700/Open-Sans-Condensed-700.eot?#iefix') format('embedded-opentype'), local('Open Sans Condensed Bold'), local('Open-Sans-Condensed-700'), url('../fonts/Open-Sans-Condensed-700/Open-Sans-Condensed-700.woff2') format('woff2'), url('../fonts/Open-Sans-Condensed-700/Open-Sans-Condensed-700.woff') format('woff'), url('../fonts/Open-Sans-Condensed-700/Open-Sans-Condensed-700.ttf') format('truetype'), url('../fonts/Open-Sans-Condensed-700/Open-Sans-Condensed-700.svg#OpenSansCondensed') format('svg');
}
@font-face {
  font-family: 'Open Sans Condensed';
  font-weight: 300;
  font-style: italic;
  src: url('../fonts/Open-Sans-Condensed-300italic/Open-Sans-Condensed-300italic.eot');
  src: url('../fonts/Open-Sans-Condensed-300italic/Open-Sans-Condensed-300italic.eot?#iefix') format('embedded-opentype'), local('Open Sans Cond Light Italic'), local('Open-Sans-Condensed-300italic'), url('../fonts/Open-Sans-Condensed-300italic/Open-Sans-Condensed-300italic.woff2') format('woff2'), url('../fonts/Open-Sans-Condensed-300italic/Open-Sans-Condensed-300italic.woff') format('woff'), url('../fonts/Open-Sans-Condensed-300italic/Open-Sans-Condensed-300italic.ttf') format('truetype'), url('../fonts/Open-Sans-Condensed-300italic/Open-Sans-Condensed-300italic.svg#OpenSansCondensed') format('svg');
}
.bgBlackNone {
  background-image: url('../images/metr.png') !important;
}
.bgWhiteNone {
  background-image: url('../images/metrbl3.png') !important;
}
.bgNone {
  background: none !important;
}
@media screen and (min-width: 721px) {
  .body_desktop {
    display: block;
  }
  .body_mobile {
    display: none;
  }
  html,
  body {
    margin: 0;
    padding: 0;
    min-width: 960px;
    min-height: 100%;
    font-family: 'Open Sans', sans-serif;
    transition: all 0.3s ease;
  }
  html .wrap,
  body .wrap {
    min-width: 960px;
    max-width: 960px;
    margin: auto;
  }
  html h1 {
    text-transform: uppercase;
    font-weight: 700;
    font-size: 59px;
    text-align: center;
    letter-spacing: 1px;
  }
  html .rel {
    position: relative;
  }
  #full {
    position: relative;
    top: 0;
  }
  #mini {
    position: fixed;
    top: 0;
  }
  #fakemenu {
    height: 159px;
  }
  .menu-holder {
    height: 159px;
    width: 100%;
    background-color: white;
    z-index: 100;
  }
  .menu-holder nav {
    color: black;
    position: relative;
    width: 100%;
  }
  .menu-holder nav ul.menu .underl {
    letter-spacing: -0.5px;
  }
  .menu-holder nav ul.menu li:first-letter {
    text-transform: uppercase;
  }
  .menu-holder nav ul.menu li {
    font-family: 'Open Sans Condensed', sans-serif;
    float: left;
    font-size: 13.6px;
    font-weight: 700;
  }
  .menu-holder nav ul.menu li a {
    padding-bottom: 5px;
  }
  .menu-holder nav ul.menu .item {
    position: absolute;
    top: 63px;
  }
  .menu-holder nav ul.menu .it1 {
    left: 9px;
  }
  .menu-holder nav ul.menu .it2 {
    left: 165px;
  }
  .menu-holder nav ul.menu .it3 {
    left: 310px;
  }
  .menu-holder nav ul.menu .it4 {
    left: 568px;
  }
  .menu-holder nav ul.menu .it5 {
    left: 690px;
  }
  .menu-holder nav ul.menu li.logo {
    position: absolute;
    left: 431px;
    top: 28px;
  }
  .menu-holder nav ul.menu li.tel {
    position: absolute;
    top: 49px;
    left: 784px;
    font-size: 20px;
  }
  .menu-holder nav ul.menu .freezam {
    position: absolute;
    top: 28px;
    padding-bottom: 2px;
    left: 12px;
    font-family: Open-Sans-Condensed-300, sans-serif;
    font-weight: 300;
    font-size: 15px;
    border-bottom: 1px solid #ed1c24;
    letter-spacing: -0.3px;
    cursor: pointer;
  }
  .menu-holder nav ul.menu .flag {
    margin-left: 15px;
    margin-top: 6px;
    float: right;
    width: 8px;
    height: 42px;
  }
  .menu-holder nav ul.menu .flag .f {
    width: 8px;
    height: 14px;
  }
  .menu-holder nav ul.menu .flag .f1 {
    background-color: #000;
  }
  .menu-holder nav ul.menu .flag .f2 {
    background-color: #ff3d3d;
  }
  .menu-holder nav ul.menu .flag .f3 {
    background-color: #fec01d;
  }
  .menu-holder nav ul.menu .underl:hover {
    border-bottom: 2px solid #ed1c24;
    padding-bottom: 5px;
  }
  .menu-holder .bttn {
    display: none;
  }
  .menu-holder .infinity-active {
    border-bottom: 2px solid #ed1c24;
  }
  .menumini {
    height: 50px!important;
    width: 100%;
    background-color: white;
    z-index: 100;
  }
  .menumini nav {
    position: relative;
    color: black;
  }
  .menumini nav ul.menu li {
    font-family: 'Open Sans Condensed', sans-serif;
    float: left;
    font-size: 13px;
    font-weight: 700;
    letter-spacing: -0.7px;
  }
  .menumini nav ul.menu li:first-letter {
    text-transform: uppercase;
  }
  .menumini nav ul.menu li a {
    padding-bottom: 2px;
  }
  .menumini nav ul.menu .item {
    position: absolute;
    top: 15px;
  }
  .menumini nav ul.menu .it1 {
    left: 162px;
  }
  .menumini nav ul.menu .it2 {
    left: 294px;
  }
  .menumini nav ul.menu .it3 {
    left: 416px;
  }
  .menumini nav ul.menu .it4 {
    left: 508px;
  }
  .menumini nav ul.menu .it5 {
    left: 610px;
  }
  .menumini nav ul.menu .logo {
    position: absolute;
    top: 0px;
    left: 42px;
    height: 82px;
    width: 82px;
    background-color: white;
    border-radius: 50%;
    margin: 0;
  }
  .menumini nav ul.menu .logo object {
    margin-top: 13px;
    margin-left: 10px;
  }
  .menumini nav ul.menu .logo svg {
    margin: 12px 0 0 11px;
  }
  .menumini nav ul.menu .logo img {
    position: absolute;
    top: -8px;
    left: -9px;
  }
  .menumini nav ul.menu li.tel {
    position: absolute;
    top: 13px;
    left: 707px;
    font-size: 18px;
    letter-spacing: 0px;
  }
  .menumini nav ul.menu .underl:hover {
    border-bottom: 2px solid #ed1c24;
  }
  .menumini nav ul.menu .act-menu {
    border-bottom: 2px solid #ed1c24;
  }
  .menumini nav .bttn {
    position: absolute;
    top: 10px;
    right: -21px;
    height: 30px;
    font-size: 13px;
    font-weight: 700;
    padding: 3px 8px 5px 8px;
    letter-spacing: -0.2px;
    border: 2px solid #fec01d;
    border-radius: 16px;
    cursor: pointer;
    transition: background 0.3s ease;
  }
  .menumini nav .bttn:hover {
    background-color: #fec01d;
    transition: background 0.3s ease;
  }
  #bgtnx {
    height: 880px;
    background: url('../images/bgtnx.jpg') repeat-x center 0px;
  }
  #bgtnx h1 {
    margin: 0;
    padding-top: 248px;
    padding-left: 20px;
    font-size: 80px;
  }
  #bgtnx .tnx {
    padding: 70px 0 0 9px;
    text-align: center;
    font-size: 47px;
    letter-spacing: 1.6px;
    line-height: 55px;
  }
  .bl1 {
    width: 100%;
    height: 920px;
    background: url('../images/bg1.jpg') repeat-x center center;
    color: white;
  }
  .bl1 header {
    height: 438px;
  }
  .bl1 header h1:first-of-type {
    margin-top: 34px;
  }
  .bl1 header .flat {
    margin: 42px 215px 0;
    padding: 14px 0 15px;
    text-transform: uppercase;
    font-weight: 700;
    font-size: 40px;
    text-align: center;
    letter-spacing: 1px;
    border: 3px solid #fec01d;
    border-radius: 24px;
    font-style: normal;
  }
  .bl1 header ul {
    margin: 48px 0 0 281px;
    letter-spacing: -1px;
    display: block;
    height: 35px;
  }
  .bl1 header ul li {
    padding-right: 17px;
    float: left;
    text-transform: uppercase;
    font-weight: bold;
    font-size: 24px;
  }
  .bl1 header ul li:nth-child(even) {
    color: #fec01d;
    margin-top: -1px;
  }
  .bl1 header ul li:first-of-type {
    padding-right: 17px;
  }
  .bl1 header ul li:nth-of-type(3) {
    padding-right: 28px;
  }
  .bl1 button {
    margin: 58px 0 0 349px;
    text-transform: uppercase;
    color: black;
    background: #fec01d;
    font-weight: bold;
    font-size: 16px;
    padding: 15px 23px 13px 23px;
    border-radius: 15px;
    transition: background 0.3s ease;
  }
  .bl1 button:hover {
    background: white;
    transition: background 0.3s ease;
  }
  .bl1 h2,
  .bl1 h3 {
    text-align: center;
    color: #000000;
  }
  .bl1 h2 {
    text-transform: uppercase;
    font-size: 28px;
    font-weight: 700;
    margin-top: 34px;
  }
  .bl1 h3 {
    font-size: 16px;
    margin-top: 7px;
    font-size: 17px;
    letter-spacing: -0.6px;
  }
  .bl1 form {
    margin-top: 22px;
    margin-left: 84px;
    height: 50px;
  }
  .bl1 form .eq-inp {
    float: left;
    margin-right: 20px;
    background: #fff;
    height: 41px;
    width: 220px;
    border: 2px solid white;
    border-radius: 9px;
    font-style: italic;
    padding-left: 43px;
  }
  .bl1 form .eq-inp::-webkit-input-placeholder {
    color: #6f6f6f;
  }
  .bl1 form .eq-inp::-moz-placeholder {
    color: #6f6f6f;
  }
  .bl1 form .eq-inp:-moz-placeholder {
    color: #6f6f6f;
  }
  .bl1 form .eq-inp:-ms-input-placeholder {
    color: #6f6f6f;
  }
  .bl1 form .name-1bl {
    background: #ffffff url("data:img/png;base64,iVBORw0KGgoAAAANSUhEUgAAABEAAAASCAMAAACKJ8VmAAAABGdBTUEAALGPC/xhBQAAACBjSFJNAAB6JgAAgIQAAPoAAACA6AAAdTAAAOpgAAA6mAAAF3CculE8AAAAwFBMVEUAAAAjHyAjHyAjHyAjHyAjHyAjHyAjHyAjHyAjHyAjHyAjHyAjHyAjHyAjHyAjHyAjHyAjHyAjHyAjHyAjHyAjHyAjHyAjHyAjHyAjHyAjHyAjHyAjHyAjHyAjHyAjHyAjHyAjHyAjHyAjHyAjHyAjHyAjHyAjHyAjHyAjHyAjHyAjHyAjHyAjHyAjHyAjHyAjHyAjHyAjHyAjHyAjHyAjHyAjHyAjHyAjHyAjHyAjHyAjHyAjHyAjHyAjHyAAAAALMCp0AAAAPnRSTlMADo3n5YwP0/HMEJelDKeUBPLmBxurrBr+xsjES0zDPPv6Y5Y7T+vqTgtQbD6fwMfQnTqEglMJTTEzz/zg31VNLOMAAAABYktHRACIBR1IAAAACXBIWXMAAAsSAAALEgHS3X78AAAAoklEQVQY022OaROBUBSGT4oil8hSiJKtkH3n/f8/SzV13WY8H84755k5C1GCVJKhlCXiVFRoVQ1qjRsddUasgWYujFbbjMOUNSMzHXTT7KGfGWbZaQ4slo8NMYqrgzHfPHE9fep77ux3fr4AsFyRQBCuw4B3m22026fYh2MiThA5E12gOFc+q+BGtnUX9j3iD7yneIHUFyEqmAh/zNsvGP/zBdVTEzbEuGgdAAAAAElFTkSuQmCC") no-repeat 11px 9px;
  }
  .bl1 form .tel-1bl {
    background: #ffffff url("data:img/png;base64,iVBORw0KGgoAAAANSUhEUgAAABIAAAARCAMAAADnhAzLAAAABGdBTUEAALGPC/xhBQAAACBjSFJNAAB6JgAAgIQAAPoAAACA6AAAdTAAAOpgAAA6mAAAF3CculE8AAABGlBMVEUAAAAjHyAjHyAjHyAjHyAjHyAjHyAjHyAjHyAjHyAjHyAjHyAjHyAjHyAjHyAjHyAjHyAjHyAjHyAjHyAjHyAjHyAjHyAjHyAjHyAjHyAjHyAjHyAjHyAjHyAjHyAjHyAjHyAjHyAjHyAjHyAjHyAjHyAjHyAjHyAjHyAjHyAjHyAjHyAjHyAjHyAjHyAjHyAjHyAjHyAjHyAjHyAjHyAjHyAjHyAjHyAjHyAjHyAjHyAjHyAjHyAjHyAjHyAjHyAjHyAjHyAjHyAjHyAjHyAjHyAjHyAjHyAjHyAjHyAjHyAjHyAjHyAjHyAjHyAjHyAjHyAjHyAjHyAjHyAjHyAjHyAjHyAjHyAjHyAjHyAjHyAjHyAjHyAAAADH6WXQAAAAXHRSTlMAEpXg3Iaz+qufd3r9rMHDIB7zv2wu/thQ6dMC0OgDFPAtcgbS710M/BfAZnSTFbb3bxmmtUeZaxOl3edPEa5pqD/R7FOxh38418uwGK+A8uF9AaG846qPl6PicL/JvJQAAAABYktHRACIBR1IAAAACXBIWXMAAAsSAAALEgHS3X78AAAAx0lEQVQY01XO1RLCMBAF0EWLOwUKhQLFrXhxd3fL/38HKQPTkIebnTOTuwFQKFVqDfwdLaXTIj0pBmQE0JjMBFmsNpxKZJfJ4ZTShdwy0R4cXh/jlynABiHEMWGiK4Lro3wsTlAiyQKk0o4MYVlTDiBfEIpQKvOV6sdqyTqAqKP0PNdottoSdbpCD2cftQYQHo7GkokTaoqv2RxHabH8PO006NX6W71RfYftbn84SoNROP32Vs/05Xq7B9BKlD/TfjDs8/wCeANOKhepdIQ2gwAAAABJRU5ErkJggg==") no-repeat 11px 11px;
  }
  .bl1 form .metr {
    width: 140px;
    background: #ffffff url("data:img/png;base64,iVBORw0KGgoAAAANSUhEUgAAAGkAAAASCAMAAABvuQAKAAAABGdBTUEAALGPC/xhBQAAACBjSFJNAAB6JgAAgIQAAPoAAACA6AAAdTAAAOpgAAA6mAAAF3CculE8AAABWVBMVEUAAAAjHyAjHyAjHyAjHyAjHyAjHyBvb28jHyAjHyBvb29vb29vb29vb28jHyBvb29vb29vb28jHyAjHyAjHyAjHyAjHyAjHyAjHyAjHyBvb28jHyAjHyAjHyAjHyAjHyAjHyAjHyAjHyBvb29vb29vb28jHyAjHyAjHyAjHyAjHyAjHyAjHyAjHyAjHyAjHyAjHyAjHyAjHyBvb29vb28jHyAjHyAjHyAjHyAjHyAjHyAjHyAjHyAjHyAjHyAjHyAjHyAjHyAjHyAjHyAjHyAjHyAjHyAjHyAjHyAjHyAjHyAjHyAjHyAjHyAjHyAjHyAjHyAjHyAjHyAjHyAjHyAjHyAjHyAjHyAjHyAjHyAjHyAjHyAjHyAjHyAjHyAjHyAjHyAjHyAjHyAjHyAjHyAjHyAjHyAjHyAjHyAjHyAjHyAjHyAjHyAjHyAjHyAjHyAjHyBvb28AAABtGnhfAAAAcHRSTlMAxsuRllBUIuHlEXfM3SeIqlUjGRZ+/nwVJLv2zTOh+fifMTOZZmhSBFbD7oofIIzvwlPuRMCZE3nk1GYKC9bidxKcLp33sUMBRrOaLa3e8Y8i8t2qqBdNq43X8CHj5okvQjQ2RHrT29V4iIRV+n99BhWJUQAAAAFiS0dEAIgFHUgAAAAJcEhZcwAACxIAAAsSAdLdfvwAAAJkSURBVDjLvVT5VxMxEB5EjCYbNgu44haoukkph0fNAp6gKIqCghcK3rfilf3/f3GSbCvt+l6fvlfmNd1kJjPfTOZLAPZK3wHYJ+k/uF9IA4d6FJiQDsXhI91cqB2k265OYQFn7ZrBwS4uIcf8uPhXJAqRgKHh1nrkaBwfG20th4+XXRLxP0AogkFlrJiPT1QNyomTpwrFWKXswJkHSqVUToGT5rkoPyWhZLTQOBPDfxkC1Cbtqj41bczM7OkzZ88Z0zhft7rJWgmI5MoBMS2kRmfItAySImsdMtSlOpG8UHGNkCqXkNi9c/MACxcuGnPp8hVrvVpbNGbp2nWA+bkSksodENXMomGYIAQeepNGnkgJAW5I88x1R/MUt3AmuBAUGss3bq6Y+Nbt1Wa41Tt3Y1NZW19ulJBkYDE8iMoJlkSojohSCpJAKaYzB4Klgys4UcCETkmaIuS9+8ZsbD5ojzjU/9CYR49LSIH0qQcWDmkYJhDlJOKcUxwc01A2iSxPXYMCGRLtNFaMqT7ZakZ6OtAi3jPkRycQ1Zg79kAKT0O7toCeK/bUiOJFErZzoSUJCwrv7R1jnm+/8IuXr4qaNjeMWXzdiZRirgQTZ1gYDhJiukJ4W5LgpdE0yyP8eF5yFWlORdjyf+P69NZO3723ffqAfVpZWy9z3PXHwgjNdUZynflaHC0DzT1R3McRldiGcbUnwuhH5N4n5N7OZ+TeF+Te1wX4i1B34vYtIgSndsCflynNnJkS2rwT7kfbY9Sn+vA+7X7bnZ0xZvp7HXop4xM/7BtRbb0RPZStn3H8a6RX0X8DyfRXtIYDcTIAAAAASUVORK5CYII=") no-repeat 6px 9px;
  }
  .bl1 form .metr:focus {
    background: #ffffff url("data:img/png;base64,iVBORw0KGgoAAAANSUhEUgAAABwAAAASCAMAAAB/2U7WAAAABGdBTUEAALGPC/xhBQAAACBjSFJNAAB6JgAAgIQAAPoAAACA6AAAdTAAAOpgAAA6mAAAF3CculE8AAABLFBMVEUAAAAjHyAjHyAjHyAjHyAjHyAjHyAjHyAjHyAjHyAjHyAjHyAjHyAjHyAjHyAjHyAjHyAjHyAjHyAjHyAjHyAjHyAjHyAjHyAjHyAjHyAjHyAjHyAjHyAjHyAjHyAjHyAjHyAjHyAjHyAjHyAjHyAjHyAjHyAjHyAjHyAjHyAjHyAjHyAjHyAjHyAjHyAjHyAjHyAjHyAjHyAjHyAjHyAjHyAjHyAjHyAjHyAjHyAjHyAjHyAjHyAjHyAjHyAjHyAjHyAjHyAjHyAjHyAjHyAjHyAjHyAjHyAjHyAjHyAjHyAjHyAjHyAjHyAjHyAjHyAjHyAjHyAjHyAjHyAjHyAjHyAjHyAjHyAjHyAjHyAjHyAjHyAjHyAjHyAjHyAjHyAjHyAjHyAjHyAAAABPdDs+AAAAYnRSTlMAxsuRllBU4eUnIxkWfv58FST2zTOh+fifMWhSBFbD7oofIIzvwlPAmRN55NRmCgvW4ncSnC6d97FDAUazmi2t3vGPIvLdqqgXTauN1/Ah4+aJL0I0NkR609vVeIiEVfp/fVb0MNIAAAABYktHRACIBR1IAAAACXBIWXMAAAsSAAALEgHS3X78AAABBklEQVQoz32QezcCURTFTxJSbplqME0PMSpCiIieSJT3oySv/f0/hHOZZk2tZfZf++6z7t2/e4jsck3Q/3JPOgw9Uw7D6RmHodc7ep71WdY/J0QgaB1986SETB+OqGAtLC6ZQUghLSqNHosDieRyamUVMNZ0mUU1SmeIsusbQG5zS0bbWh7Y2d0jyqTJKOwfFCEOj0rDrtLxiYBSrhQMqtaAeuN0jNJ9Bpw3CVAvWsPw0mOhXjEetTvAdfvmL7q9M2826kD+nt3Db+ejDJ+eZecLdxbLFfONYJdpe0zbeWXaPtO+ZW0EeszF/xy8D5IJIP6hj+81HPmUG1KtDY2q9SXEt98W/ACiSyqW5O5vBwAAAABJRU5ErkJggg==") no-repeat 6px 9px;
  }
  .bl1 form input[type="submit"] {
    background: #fff;
    border-radius: 9px;
    height: 41px;
    width: 150px;
    float: left;
    border: 0;
    cursor: pointer;
    font-weight: bold;
    font-size: 18px;
    padding-left: 0;
    transition: all 0.3s ease;
    font-style: normal;
  }
  .bl1 form input[type="submit"]:hover {
    background: black;
    color: white;
  }
  .bl1 p {
    color: black;
    text-align: center;
  }
  .bl1 p:first-of-type {
    font-style: italic;
    font-size: 14px;
    text-decoration: underline;
    margin-top: 22px;
  }
  .bl1 p:last-of-type {
    font-size: 12px;
    margin-top: 31px;
    letter-spacing: 0.25px;
    padding-left: 44px;
  }
  .bl1 p:last-of-type:before {
    content: '';
    background: url("data:img/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAdCAMAAACHZFx5AAAABGdBTUEAALGPC/xhBQAAACBjSFJNAAB6JgAAgIQAAPoAAACA6AAAdTAAAOpgAAA6mAAAF3CculE8AAABklBMVEUAAAD///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////8jHyAjHyAjHyAjHyAjHyAjHyAjHyAjHyAjHyAjHyAjHyAjHyAjHyAjHyAjHyAjHyD///8jHyAjHyAjHyD///////8jHyAjHyAjHyD///////////8jHyAjHyAjHyD///////////////8jHyAjHyD///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////8jHyAAAABcJlzzAAAAg3RSTlMADqLIKFLj+HgEOcD+mXf10kQGVcPGOCG1UAU/RW2n7wFC7qhOQOyWM5jqcDACNm7r0Alb+XBf/fpCNooPafZOhcnTCr5u1O3d8UHpySUGsjcjx32Cci52gBz38BUJ5fwkh7CMpIVUhvpBLawMetXaIrzxRqWSD3X2y19JEZX9HzHWQ9TbknIAAAABYktHRACIBR1IAAAACXBIWXMAAAsSAAALEgHS3X78AAABPklEQVQoz2NggANGJmYWBiyAla25mZ2DE12Yi7uZh5ePv1lAEFlUSFikuVlUjIFBXKK5WURSCiwoLSMrJ9/czCypAOYqApUoKcuqqDI0A4GauhhCu4amFkiMoVlNW0cX1T5dPX0DoIQhNicaUkPCCIeEsYkpVgkzcwtLFAkraxsQZdtiZ49qlEOLow1Y3BJhuROI4ewClHGFizO4NTPwuzNAZTxaPL1gdil5M/g0+4KZfv4tngEw8cDmIIbg5hAIxy80DO648OYIhsio6Bh0f8fGxScwMCQ2JymgSSQ3p4CknZpTUcXTmr3BhgRqNacj60nLyMiEsLK8m7Nz4LGU25whDHddXnM+NHILCpuLihG6c0qa40vLGBg4yyuaKwNRLBTWavauqnZrjspFT3I1tXXAtJFdjyU6G4IamxA8AHAlUp1+ei95AAAAAElFTkSuQmCC") no-repeat;
    height: 30px;
    width: 25px;
    position: absolute;
    margin-left: -46px;
    margin-top: -10px;
  }
  .bl2 {
    height: 826px;
    background: white;
  }
  .bl2 h1:first-of-type {
    margin-top: 123px;
    font-size: 47px;
    overflow: hidden;
  }
  .bl2 h1:first-of-type:before,
  .bl2 h1:first-of-type:after {
    content: '';
    display: inline-block;
    vertical-align: middle;
    position: absolute;
    width: 100%;
    height: 4px;
    position: relative;
    top: -5px;
  }
  .bl2 h1:first-of-type:before {
    margin-left: -100%;
    left: -53px;
    background-color: #ed1c24;
  }
  .bl2 h1:first-of-type:after {
    background-color: #fec01d;
    margin-right: -100%;
    right: -57px;
  }
  .bl2 .preim {
    float: left;
    margin-top: 68px;
    font-weight: 700;
    font-size: 16px;
    border: 2px solid #fec01d;
    border-radius: 9px;
    text-transform: uppercase;
    margin-left: 22px;
    letter-spacing: 0.5px;
    padding: 7px 12px 7px 12px;
  }
  .bl2 ul li {
    float: left;
    cursor: pointer;
    text-transform: uppercase;
    margin: 69px 7px 0 10px;
    padding: 9px 15px 10px 15px;
  }
  .bl2 ul li div {
    padding: 0 0 8px 0;
    border-bottom: 2px solid white;
  }
  .bl2 ul li:hover div {
    border-bottom: 2px solid #ed1c24;
  }
  .bl2 ul .active {
    padding: 9px 15px 0px 15px;
    background-color: #fec01d;
    border-radius: 9px;
  }
  .bl2 ul .active div {
    border-bottom: 2px solid #fec01d;
  }
  .bl2 ul .active:hover div {
    border-bottom: 2px solid #fec01d;
  }
  .bl2 ul li[link="1"] {
    margin-left: 25px;
  }
  .bl2 ul li[link="3"] {
    margin-right: 0;
  }
  .bl2 .tablink {
    float: left;
    margin-top: 37px;
  }
  .bl2 .tablink img {
    float: left;
    width: 460px;
    height: 340px;
    margin: 83px 0 0 9px;
  }
  .bl2 .tablink article {
    height: 360px;
    width: 469px;
    float: right;
    margin-top: 90px;
  }
  .bl2 .tablink article h2 {
    text-transform: uppercase;
    margin: 0 0 0 79px;
    font-weight: 700;
    font-size: 24px;
  }
  .bl2 .tablink article p {
    margin-top: 33px;
    margin-left: 80px;
    line-height: 26px;
    letter-spacing: 0.4px;
  }
  .bl3 {
    height: 580px;
    background-color: #323232;
    color: white;
    overflow: hidden;
  }
  .bl3 h1 {
    font-weight: 700;
    font-size: 39px;
    padding-top: 65px;
    color: #fff;
  }
  .bl3 h2 {
    text-align: center;
    margin-top: 66px;
    font-size: 28px;
    text-transform: uppercase;
  }
  .bl3 h3 {
    font-weight: 300;
    text-align: center;
    margin-top: 17px;
    letter-spacing: 0.5px;
  }
  .bl3 form {
    margin-top: 63px;
    margin-left: 68px;
    height: 50px;
  }
  .bl3 form .eq-3bl {
    float: left;
    margin-right: 30px;
    height: 41px;
    width: 220px;
    border: 2px solid #fec01d;
    border-radius: 9px;
    font-style: italic;
    padding-left: 43px;
  }
  .bl3 form .eq-3bl::-webkit-input-placeholder {
    color: #fff;
  }
  .bl3 form .eq-3bl::-moz-placeholder {
    color: #fff;
  }
  .bl3 form .eq-3bl:-moz-placeholder {
    color: #fff;
  }
  .bl3 form .eq-3bl:-ms-input-placeholder {
    color: #fff;
  }
  .bl3 form .name-3bl {
    background: url("data:img/png;base64,iVBORw0KGgoAAAANSUhEUgAAABEAAAASCAMAAACKJ8VmAAAABGdBTUEAALGPC/xhBQAAACBjSFJNAAB6JgAAgIQAAPoAAACA6AAAdTAAAOpgAAA6mAAAF3CculE8AAAAwFBMVEUAAAD///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////8AAAADHcBeAAAAPnRSTlMADo3n5YwP0/HMEJelDKeUBPLmBxurrBr+xsjES0zDPPv6Y5Y7T+vqTgtQbD6fwMfQnTqEglMJTTEzz/zg31VNLOMAAAABYktHRACIBR1IAAAACXBIWXMAAAsSAAALEgHS3X78AAAAoklEQVQY022OaROBUBSGT4oil8hSiJKtkH3n/f8/SzV13WY8H84755k5C1GCVJKhlCXiVFRoVQ1qjRsddUasgWYujFbbjMOUNSMzHXTT7KGfGWbZaQ4slo8NMYqrgzHfPHE9fep77ux3fr4AsFyRQBCuw4B3m22026fYh2MiThA5E12gOFc+q+BGtnUX9j3iD7yneIHUFyEqmAh/zNsvGP/zBdVTEzbEuGgdAAAAAElFTkSuQmCC") no-repeat 11px 9px;
    color: white;
  }
  .bl3 form .tel-3bl {
    background: url("data:img/png;base64,iVBORw0KGgoAAAANSUhEUgAAABIAAAASCAMAAABhEH5lAAAABGdBTUEAALGPC/xhBQAAACBjSFJNAAB6JgAAgIQAAPoAAACA6AAAdTAAAOpgAAA6mAAAF3CculE8AAABDlBMVEUAAAD///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////8AAAAvYQfQAAAAWHRSTlMAEI/dg6SzvGn+mpa32qz7FOi4XzXVFsjyC+3XA8r3cZgkF/nNzGbA9TgrbkMs4cvw+pda+K8R0cdcsX/9AcWmhLYVxNm62+TzJ5uRru+7nt+fR5zQ5+BoMSKXiQAAAAFiS0dEAIgFHUgAAAAJcEhZcwAACxIAAAsSAdLdfvwAAAC/SURBVBjTVc9lE8IwDAbg4O5uw2XAcHd31/z/X0LLxq30w3vpc9cmAVAoVSo1/B0NanWoZEVvIFcjmhgyW2habXaZHE6aLnTLhB4SXp8/IFMwBBCOcFHmrxjGIZFMeRlyYxogw2VZ8+T4n+ULxS8JpbJdtGgFq7Wv1RvNFrU21+n2pD36A+uQWGNE3o5R3HcymM7IcGFazxdiiz7X5qVuy5VUdNeb2JYWO9z/RhEO1ePp3LzglZnvdn88X+Y3wAcuSBfFvpvMuQAAAABJRU5ErkJggg==") no-repeat 11px 11px;
    color: white;
  }
  .bl3 form .metr {
    background: url("data:img/png;base64,iVBORw0KGgoAAAANSUhEUgAAAGcAAAATCAMAAAC6LOMcAAAABGdBTUEAALGPC/xhBQAAACBjSFJNAAB6JgAAgIQAAPoAAACA6AAAdTAAAOpgAAA6mAAAF3CculE8AAABg1BMVEUAAAD///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////8AAAAa8ZiOAAAAf3RSTlMAw8ilqDQ1IuTnEXfM3VVYiKovLbvFnka4RDOZZtMFXNHskZbPWwTuTDkOdOPbagkKa+JzDfoci/HGUwJU8BpQX6P+rzs9s59e+feYJiib+MpRncsYedpoyc14FwZd0up9FDY3FYEBSLz2lSO6RTH8rHqtLh6N8/KMHRB2/XUPKaxPBwAAAAFiS0dEAIgFHUgAAAAJcEhZcwAACxIAAAsSAdLdfvwAAAJoSURBVDjLvVT3VxQxEI6gBpLNmuiC7iZHsQUUONtZOBVv7YBi7wV7773Ov+4k2dO7/eHew/eOeZs2yeT7Mvk2hLTaih6yLNa7cnlwVq3uyraUlhx9/Z0DmCuULNF4JHi7J17TMUAK5CbUUnEYidtj1vb3r+sUkKj/gUFTnAwMNgfrE0DbkDbHgwPl5YIHmExr4x3YaWbEhC6VmrPC46c41loSUgn8hoZHAEY3bhoF2LxlayBRKcFQMB6GW6UthpLU6igpGFvJ0ZfZRIvCJSwCGtAkcWvHxrHatn0CYHKqSsiOnQJg1+496BwfK+EY8DDMcoeFm0SSCBmmLKpDaxLhggx8SpgVGS4RXAmlGKlVyN59+wEOTNfDbvWDPQCH5GFSqZVwdOQQAoQBisehzMbUGEOSyBhuUw+Bxyb+sIkhXNmMZhkCzhxp1CA/eqx1w+MncqidPDVTwol0oB05MJSeTEgMNBZCMCwCSRhHIYXMX0ykJbXe4+w0wOzcfPnGz5xdAFho9zGLvDH3WgXpubGDCwpx+aJGFBTcjUknDR4V0TnAufNFysiFRtGpT18EyNtxMuRJkTTHQ2GhEqmqQuVJgj+JZSnE2AQtChNbwZQsoi9d7gO4crXqB9eu+6Y6NQkwceNmO46/FweirLApBZuGc3gpRlYEefjGi5O6ixLmb/yt23cA7i7ew+79B1g9fITiHhkeKueS+Uy7V4dS7LpC/r1BWeqnGWXNv8B/rHWHx0+eAjx7/sK9b/NzswAvX5Eu2eveN5C/fbf4HpXW+NAtFGcfP312786Xr9+6ieLs+4/856/f3dn7DxkLW7ND5NCpAAAAAElFTkSuQmCC") no-repeat 9px 9px;
    width: 140px;
    color: white;
  }
  .bl3 form .metr:focus {
    background: url("data:img/png;base64,iVBORw0KGgoAAAANSUhEUgAAABwAAAATCAMAAAC0hZ1zAAAABGdBTUEAALGPC/xhBQAAACBjSFJNAAB6JgAAgIQAAPoAAACA6AAAdTAAAOpgAAA6mAAAF3CculE8AAABa1BMVEUAAAD///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////8AAACJyLOpAAAAd3RSTlMAw8ilqDQ15OdVWC8txZ5Gu7hE06oFXNHskZbPWwRMOQ5049tqCQpr3eJzDfoci/HGUwJU8IgaUF+j/q87PbOfXvn3mCYom/jKUZ3LGHnaaMnNeBcGXdLqfRQ2NxWBAUi89pUjukUx/Kx6rS4ejfPyjB0Qdv11Dwf2DXIAAAABYktHRACIBR1IAAAACXBIWXMAAAsSAAALEgHS3X78AAABFUlEQVQoz31QZ1PCQBR8gkosYAlNUVAg1liIBXN2QLH3ggXsBeyi7t/3iGEmqOR9uLe3O7Ozb4mMU2Gh8mOtNBGrqk1Em2Ai1tSW1+oEob6EsDuKqKERfJqai3+HnUSnhlxuD+BtafUCvrZ2jXKK5A/w3dEZBEJhiairuwfo7evnZMBPskgDg0PAcET5cVNGLMDo2DiJMkUnVBlscsqYY3qGQZ6di9I8EIsnfudeWEwCSWLA0rLuSCuqDpTIKsBobd0GbGxKGre1rS0pHAKCO7sc7u0fAIepIw6PT/hzmub3eNwu3SRzdg5cXF4Vuk3EY8D1TUmCW+sd2H02leM51Yc/xT4+PRfqe3l9+7f39zz7+PwyEN+2PDa78eKDvAAAAABJRU5ErkJggg==") no-repeat 9px 9px;
  }
  .bl3 form input[type="submit"] {
    background: #fec01d;
    border-radius: 9px;
    height: 41px;
    width: 150px;
    float: left;
    border: 0;
    cursor: pointer;
    font-size: 18px;
    padding-left: 0;
    font-style: normal;
    font-family: 'Open Sans', sans-serif;
    font-weight: bold;
    transition: all 0.3s ease;
  }
  .bl3 form input[type="submit"]:hover {
    background: black;
    color: white;
  }
  .bl3 p {
    color: black;
    text-align: center;
  }
  .bl3 p:first-of-type {
    font-style: italic;
    font-size: 16px;
    color: white;
    letter-spacing: -0.1px;
    text-decoration: underline;
    margin-top: 36px;
  }
  .bl3 p:last-of-type {
    font-size: 12px;
    margin-top: 49px;
    letter-spacing: 0.25px;
    padding-left: 44px;
    color: white;
  }
  .bl3 p:last-of-type:before {
    content: '';
    background: url("data:img/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAeCAMAAAAB8C7XAAAABGdBTUEAALGPC/xhBQAAACBjSFJNAAB6JgAAgIQAAPoAAACA6AAAdTAAAOpgAAA6mAAAF3CculE8AAABklBMVEUAAAD9vx39vx39vx39vx39vx39vx39vx39vx39vx39vx39vx39vx39vx39vx39vx39vx39vx39vx39vx39vx39vx39vx39vx39vx39vx39vx39vx39vx39vx39vx39vx39vx39vx39vx39vx39vx39vx39vx39vx39vx39vx39vx39vx39vx39vx39vx39vx39vx39vx39vx39vx3////////////////////////////////////////////////////////////////////9vx3////////9vx39vx39vx39vx3////////9vx39vx39vx39vx39vx3////////9vx39vx39vx39vx39vx39vx39vx39vx39vx39vx39vx39vx39vx39vx39vx39vx39vx39vx39vx39vx39vx39vx39vx39vx39vx39vx39vx39vx39vx39vx39vx39vx39vx39vx39vx39vx39vx39vx39vx39vx39vx39vx39vx39vx39vx39vx39vx3///8AAAB+575XAAAAg3RSTlMAC53EIkPc9GsBJayujPzBLjSn4FLS/aUQIUR/0el1CANm586ARRLIZQkGYMr3qB5upvXQG6E53HkCFxXTsgaC/FtG+/3bZLz45PkFvgr7sD9drxkEB+L+OPruDFvZD7x6q7mNzAQNzboVqusz8Fg93iqHspSz33Nc5e9iComgFivD1F68sOAAAAABYktHRACIBR1IAAAACXBIWXMAAAsSAAALEgHS3X78AAABQUlEQVQoz2NggANGJmYWBiyAla25mZ2DE12Yi7u5mYeXr5lfAEVYUIivWVgESIs2i4lDpSQkpaRlmptl5eRBPAVFpeZmZRVVLjWGZhBQ19CEadbS1gELMTTr6mlIotqnb2BoBJQwxuZEY1pKmJiaYZUwMW+xQJGwtAKLW9u02NqhSNg7OCKLIyScnF0ckcTBEq5glqOLi5tNiztUnIHNg8HTyxsq09Li4wsLMD9hBv9mKQjH0cEdJs4Q0MzDENgcBOUFh8BdzdSswRAaFh6B7m+WyKhoBoaY5tg4VHGF+OYEEJ3YnKSFIpHcnJIKotPSmzOiEcKcvM2y0GSUadwsCo/EtKzm7BwYJy6jOTcPwsx3bS4oRLKuKLy5WB+ovCS8uVQNxcKAsmb28orK5qpqdLfX1PoBE01dPZbobGhsykfwAEfAVBL3OURQAAAAAElFTkSuQmCC") no-repeat;
    height: 30px;
    width: 25px;
    position: absolute;
    margin-left: -45px;
    margin-top: -8px;
  }
  .bl3 .wrap {
    position: relative;
  }
  .bl3 .wrap .mach {
    position: absolute;
    top: 538px;
    left: -67px;
    background: url('../images/machine.png') no-repeat 0 0;
    height: 600px;
    width: 1150px;
  }
  .bl4 {
    height: 670px;
    background-color: white;
  }
  .bl4 .wrap {
    position: relative;
  }
  .bl4 .wrap .mach {
    position: absolute;
    top: -42px;
    left: -67px;
    background: url('../images/machine.png') no-repeat 0 0;
    height: 600px;
    width: 1150px;
  }
  .bl5 {
    height: 850px;
    background: #fec01d url('../images/bg4.jpg') repeat-x center;
  }
  .bl5 .wrap .slider-box {
    width: 960px;
    height: 690px;
    position: relative;
    overflow: hidden;
  }
  .bl5 .wrap .slider-box .arro {
    position: absolute;
    top: 93px;
    width: 20px;
    height: 25px;
    padding: 25px;
    z-index: 20;
    cursor: pointer;
  }
  .bl5 .wrap .slider-box .lf {
    left: 307px;
    background: url(../images/arrows.png) no-repeat 14px;
  }
  .bl5 .wrap .slider-box .rgh {
    left: 598px;
    background: url(../images/arrows.png) no-repeat -29px;
  }
  .bl5 .wrap .slider-box .circle {
    position: absolute;
    width: 44px;
    height: 44px;
    border: 3px solid #fec01d;
    border-radius: 50%;
    background: white;
    cursor: pointer;
    z-index: 10;
    font-size: 36px;
    font-weight: 700;
    color: #fec01d;
    padding: 0px 0px 0px 9px;
    line-height: 38px;
  }
  .bl5 .wrap .slider-box .circle:hover:not(.activ) {
    color: white;
    background: #fec01d;
    transition: all 0.3s ease;
  }
  .bl5 .wrap .slider-box .activ {
    background: #fec01d url('../images/check.png') no-repeat center;
    z-index: 20;
    font-size: 0;
  }
  .bl5 .wrap .slider-box .crcl1 {
    left: 478px;
    top: 434px;
  }
  .bl5 .wrap .slider-box .crcl2 {
    left: 260px;
    top: 422px;
  }
  .bl5 .wrap .slider-box .crcl3 {
    left: 410px;
    top: 628px;
  }
  .bl5 .wrap .slider-box .crcl4 {
    left: 780px;
    top: 616px;
  }
  .bl5 .wrap .slider-box .slider {
    width: 3840px;
    height: 690px;
    position: relative;
  }
  .bl5 .wrap .slider-box .slider .slider-bl {
    float: left;
    width: 960px;
    height: 690px;
    top: 0;
  }
  .bl5 .wrap .slider-box .slider .slider-bl h1 {
    margin-top: 85px;
    font-size: 46px;
    font-weight: 700;
    letter-spacing: 2px;
    color: white;
  }
  .bl5 .wrap .slider-box .slider .slider-bl p {
    margin-top: 29px;
    text-align: center;
    font-family: Open Sans semibold, sans-serif;
    font-size: 18px;
    letter-spacing: 0.3px;
    font-weight: 700;
    color: white;
  }
  .bl5 .wrap .slider-box .slbl1 {
    left: 0;
  }
  .bl5 .wrap .slider-box .slbl2 {
    left: 960px;
  }
  .bl5 .wrap .slider-box .slbl3 {
    left: 1920px;
  }
  .bl5 .wrap .slider-box .slbl4 {
    left: 2880px;
  }
  .bl6 {
    height: 1073px;
    background-color: white;
    z-index: -20;
  }
  .bl6 h1:first-of-type {
    margin-top: 128px;
    font-size: 47px;
    overflow: hidden;
  }
  .bl6 h1:first-of-type:before,
  .bl6 h1:first-of-type:after {
    content: '';
    display: inline-block;
    vertical-align: middle;
    position: absolute;
    width: 100%;
    height: 4px;
    position: relative;
    top: -5px;
  }
  .bl6 h1:first-of-type:before {
    margin-left: -100%;
    left: -53px;
    background-color: #ed1c24;
    top: 30px;
  }
  .bl6 h1:first-of-type:after {
    background-color: #fec01d;
    margin-right: -100%;
    right: -193px;
    top: -34px;
  }
  .bl6 .tr1 {
    float: left;
    z-index: 10;
    position: relative;
  }
  .bl6 .mmax {
    font-family: 'Open Sans Extrabold', sans-serif;
    font-weight: 700;
    margin-left: 67px;
    font-size: 17px;
    letter-spacing: 1px;
    margin-bottom: 46px;
  }
  .bl6 .mmax1 {
    margin-top: 120px;
  }
  .bl6 .mmax2 {
    margin-top: 131px;
  }
  .bl6 .choose {
    background: #fec01d;
    border-radius: 10px;
    width: 142px;
    height: 40px;
    padding: 7px 0 0 23px;
    margin-left: 106px;
    font-weight: 600;
    font-size: 18px;
  }
  .bl6 .choose1 {
    margin-top: 110px;
  }
  .bl6 .choose2 {
    margin-top: 125px;
    margin-left: 215px;
  }
  .bl6 .dontchoose {
    margin-left: 140px;
    font-weight: 600;
    font-size: 18px;
    letter-spacing: 1.3px;
  }
  .bl6 .dontchoose1 {
    margin-top: 118px;
  }
  .bl6 .dontchoose2 {
    margin-top: 132px;
  }
  .bl6 .row {
    position: relative;
    z-index: 10;
    width: 900px;
    border-bottom: 1px solid #fec01d;
    float: left;
    margin-left: 28px;
    padding: 6px 0 10px 0;
  }
  .bl6 .row .td {
    float: left;
  }
  .bl6 .row .td1 {
    padding-left: 40px;
    width: 379px;
    font-weight: 600;
    font-size: 17px;
    letter-spacing: -0.2px;
  }
  .bl6 .row .td2 {
    width: 260px;
  }
  .bl6 .bigrow .td {
    padding-top: 15px;
    padding-bottom: 4px;
  }
  .bl6 .bigrow .td:last-of-type {
    padding-top: 4px;
  }
  .bl6 .nonbrdr {
    border: 0;
  }
  .bl6 .backgrey {
    position: absolute;
    width: 240px;
    height: 800px;
    background: #f7f7f7;
    top: 67px;
    left: 368px;
    z-index: 0;
  }
  .bl6 .profi {
    position: absolute;
    height: 60px;
    width: 126px;
    text-align: right;
    font-size: 14px;
    line-height: 15px;
    font-weight: 700;
    top: 807px;
    left: 407px;
    z-index: 11;
  }
  .bl6 .profi:after {
    content: '';
    position: absolute;
    background: url("data:img/png;base64,iVBORw0KGgoAAAANSUhEUgAAABsAAAAlCAMAAACEemK6AAAABGdBTUEAALGPC/xhBQAAACBjSFJNAAB6JgAAgIQAAPoAAACA6AAAdTAAAOpgAAA6mAAAF3CculE8AAABYlBMVEUAAAD9wB39wB39wB39wB39wB39wB39wB39wB39wB39wB39wB39wB39wB39wB39wB39wB39wB39wB39wB39wB39wB39wB39wB39wB39wB39wB39wB39wB39wB39wB39wB39wB39wB39wB39wB39wB39wB39wB39wB39wB39wB39wB39wB39wB39wB39wB39wB39wB39wB39wB39wB39wB39wB39wB39wB39wB39wB39wB39wB39wB39wB39wB39wB39wB39wB39wB39wB39wB39wB39wB39wB39wB39wB39wB39wB39wB39wB39wB39wB39wB39wB39wB39wB39wB39wB39wB39wB39wB39wB39wB39wB39wB39wB39wB39wB39wB39wB39wB39wB39wB39wB39wB39wB39wB39wB39wB39wB39wB39wB39wB39wB39wB39wB39wB39wB39wB0AAAAuZXXzAAAAdHRSTlMARdPiZDX7Zq7wCwb28lQ99ASohYC4gprNdnSgKSiDmJzOhDL9X8tdD9rWB/ouEslAPANC4XxxwLewpTC2lwFhf/zPVuTGMQVEYGV6VTYg+FrMH+XvOALHSHUQRgo3JznogewvkcTdOhMYPr47DJAcfum8WPsvIGwAAAABYktHRACIBR1IAAAACXBIWXMAAAsSAAALEgHS3X78AAABY0lEQVQ4y3XS+TsCQRgH8BeJEslduSVHEZLIWbkKubpEbrnv7///eGfRsTv7/WVm57PP7My7L1ExVdWoMZA0tUZw6qRWLwgms4QaLIChEWiSmBVotrUArRJrA9qpA+iUWBdgJwfg1FI3n6OHeoE+rfUDA0ROYFBDQy5gmMgNjKhpdAwYt5HHC0yoaNIHuKaIpvkeM5Xkn+WDBHgyxxYMBucXQoOLvyVeErVaXuHpKkpZ4+eAmIQdymvmSBlGidZ52Nj8231rOyYS3+Gz+YhMwK72unuAUbGE1haBfT07AA71zA94j8glta1jUXVIjeJAUs9SXEUdS2eAjI5leTmrYyeie3QsprSB3Lh9XDZhOa2d8vKZ3KLcHG5lT6uaPEnAklIMkXylnXM1L3i8VP77lafc8sC1GG9u74QW7PeJROIhx3l8ehZF+c1LAZrE//d4fQurKJQufeH9I/hZdNPXt+hI+gEvaI4PV5DBLAAAAABJRU5ErkJggg==") center no-repeat;
    height: 50px;
    width: 50px;
    top: -13px;
    left: 128px;
  }
  .bl7 {
    height: 850px;
    background: url(../images/bg7.jpg) repeat-x center;
  }
  .bl7 h1 {
    font-weight: 700;
    font-size: 46px;
    padding-top: 103px;
    color: #fff;
  }
  .bl7 h2 {
    text-align: center;
    margin-top: 19px;
    font-size: 19px;
    color: white;
    font-weight: 300;
  }
  .bl7 form {
    margin-top: 72px;
    margin-left: 53px;
    height: 50px;
    color: white;
    position: relative;
    /* Состояние при наведении курсора */
    /* активное состояние */
  }
  .bl7 form .eq-7bl {
    float: left;
    margin-right: 50px;
    border: 2px solid #fec01d;
    border-radius: 9px;
    font-style: italic;
    padding: 5px 0 0 25px;
    font-size: 17px;
    height: 50px;
    background: none;
    color: white;
  }
  .bl7 form .eq-7bl::-webkit-input-placeholder {
    color: #fff;
  }
  .bl7 form .eq-7bl::-moz-placeholder {
    color: #fff;
  }
  .bl7 form .eq-7bl:-moz-placeholder {
    color: #fff;
  }
  .bl7 form .eq-7bl:-ms-input-placeholder {
    color: #fff;
  }
  .bl7 form .long {
    width: 400px;
  }
  .bl7 form .supalong {
    width: 850px;
  }
  .bl7 form .metr {
    width: 160px;
    color: white;
    margin-top: 50px;
    margin-right: 40px;
    background: url('../images/bghackcalc.png') no-repeat 25px 16px;
  }
  .bl7 form .metr:focus {
    background: none;
  }
  .bl7 form .sloi {
    width: 200px;
    color: #fff;
    margin-top: 50px;
    margin-right: 50px;
  }
  .bl7 form .adres-7bl {
    margin-top: 50px;
  }
  .bl7 form input[type="submit"] {
    background: #fec01d;
    border-radius: 15px;
    height: 70px;
    width: 700px;
    float: left;
    border: 0;
    cursor: pointer;
    margin-top: 40px;
    margin-left: 75px;
    font-size: 24px;
    padding-left: 0;
    font-style: normal;
    font-family: 'Open Sans', sans-serif;
    font-weight: 700;
    text-transform: uppercase;
    transition: all 0.3s ease;
  }
  .bl7 form input[type="submit"]:hover {
    background: white;
    color: black;
  }
  .bl7 form p:first-of-type {
    font-style: italic;
    font-size: 19px;
    letter-spacing: -0.6px;
    text-decoration: underline;
    margin-top: 43px;
    margin-left: 258px;
  }
  .bl7 form .wrapper-dropdown-1 {
    /* размер и позиционирование */
    position: relative;
    margin-top: 50px;
    /*Общие стили */
    background: none;
    color: #fff;
    outline: none;
    cursor: pointer;
    padding: 13px 0 0 20px;
    /* Настройки шрифтов */
  }
  .bl7 form .wrapper-dropdown-1:after {
    content: "";
    width: 0;
    height: 0;
    position: absolute;
    right: 16px;
    top: 50%;
    border-color: #fff transparent;
    border-width: 6px 6px 0 6px ;
    margin-top: -3px;
    border-style: solid;
  }
  .bl7 form .wrapper-dropdown-1 .dropdown {
    /* размер и позиция */
    position: absolute;
    top: 100%;
    width: 400px;
    left: -2px;
    right: 0;
    /* Стили */
    background: #000;
    color: white;
    font-weight: normal;
    border-left: 2px solid #fec01d;
    border-right: 2px solid #fec01d;
    border-bottom: 2px solid #fec01d;
    border-bottom-right-radius: 9px;
    border-bottom-left-radius: 9px;
    opacity: 0;
    pointer-events: none;
  }
  .bl7 form .wrapper-dropdown-1 .dropdown li a {
    display: block;
    text-decoration: none;
    font-weight: normal;
    font-style: normal;
    color: #fff;
    padding: 5px 20px;
  }
  .bl7 form .wrapper-dropdown-1 .dropdown li:hover a {
    background: #342707;
  }
  .bl7 form .wrapper-dropdown-1 .dropdown li:last-of-type:hover a {
    background: #342707;
    border-bottom-left-radius: 9px;
    border-bottom-right-radius: 9px;
  }
  .bl7 form .wrapper-dropdown-1.active .dropdown {
    opacity: 0.9;
    pointer-events: auto;
  }
  .bl7 form .wrapper-dropdown-1.active:after {
    border-color: #fff transparent;
    border-width: 0 6px 6px 6px ;
    margin-top: -3px;
  }
  .bl7 form .wrapper-dropdown-1.active {
    background: #000;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
  }
  .bl7 p {
    float: left;
    color: black;
    text-align: center;
    color: white;
  }
  .bl7 p.last {
    font-size: 17px;
    margin-top: 49px;
    margin-left: 198px;
    letter-spacing: -0.05px;
    padding-left: 44px;
  }
  .bl7 p.last:before {
    content: '';
    background: url("data:img/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAeCAMAAAAB8C7XAAAABGdBTUEAALGPC/xhBQAAACBjSFJNAAB6JgAAgIQAAPoAAACA6AAAdTAAAOpgAAA6mAAAF3CculE8AAABklBMVEUAAAD9vx39vx39vx39vx39vx39vx39vx39vx39vx39vx39vx39vx39vx39vx39vx39vx39vx39vx39vx39vx39vx39vx39vx39vx39vx39vx39vx39vx39vx39vx39vx39vx39vx39vx39vx39vx39vx39vx39vx39vx39vx39vx39vx39vx39vx39vx39vx39vx39vx39vx39vx3////////////////////////////////////////////////////////////////////9vx3////////9vx39vx39vx39vx3////////9vx39vx39vx39vx39vx3////////9vx39vx39vx39vx39vx39vx39vx39vx39vx39vx39vx39vx39vx39vx39vx39vx39vx39vx39vx39vx39vx39vx39vx39vx39vx39vx39vx39vx39vx39vx39vx39vx39vx39vx39vx39vx39vx39vx39vx39vx39vx39vx39vx39vx39vx39vx39vx3///8AAAB+575XAAAAg3RSTlMAC53EIkPc9GsBJayujPzBLjSn4FLS/aUQIUR/0el1CANm586ARRLIZQkGYMr3qB5upvXQG6E53HkCFxXTsgaC/FtG+/3bZLz45PkFvgr7sD9drxkEB+L+OPruDFvZD7x6q7mNzAQNzboVqusz8Fg93iqHspSz33Nc5e9iComgFivD1F68sOAAAAABYktHRACIBR1IAAAACXBIWXMAAAsSAAALEgHS3X78AAABQUlEQVQoz2NggANGJmYWBiyAla25mZ2DE12Yi7u5mYeXr5lfAEVYUIivWVgESIs2i4lDpSQkpaRlmptl5eRBPAVFpeZmZRVVLjWGZhBQ19CEadbS1gELMTTr6mlIotqnb2BoBJQwxuZEY1pKmJiaYZUwMW+xQJGwtAKLW9u02NqhSNg7OCKLIyScnF0ckcTBEq5glqOLi5tNiztUnIHNg8HTyxsq09Li4wsLMD9hBv9mKQjH0cEdJs4Q0MzDENgcBOUFh8BdzdSswRAaFh6B7m+WyKhoBoaY5tg4VHGF+OYEEJ3YnKSFIpHcnJIKotPSmzOiEcKcvM2y0GSUadwsCo/EtKzm7BwYJy6jOTcPwsx3bS4oRLKuKLy5WB+ovCS8uVQNxcKAsmb28orK5qpqdLfX1PoBE01dPZbobGhsykfwAEfAVBL3OURQAAAAAElFTkSuQmCC") no-repeat;
    height: 30px;
    width: 25px;
    position: absolute;
    margin-left: -47px;
    margin-top: -3px;
  }
  .bl8 {
    background-color: white;
  }
  .bl8 h1:first-of-type {
    margin-top: 103px;
    font-size: 47px;
    letter-spacing: 0.7px;
    font-weight: 700;
    overflow: hidden;
  }
  .bl8 h1:first-of-type:before,
  .bl8 h1:first-of-type:after {
    content: '';
    display: inline-block;
    vertical-align: middle;
    position: absolute;
    width: 100%;
    height: 4px;
    position: relative;
    top: -6px;
  }
  .bl8 h1:first-of-type:before {
    margin-left: -100%;
    left: -45px;
    background-color: #ed1c24;
  }
  .bl8 h1:first-of-type:after {
    background-color: #fec01d;
    margin-right: -100%;
    right: -45px;
  }
  .bl8 h2 {
    text-align: center;
    margin-top: 75px;
    font-weight: 700;
    font-size: 18px;
    letter-spacing: 0.5px;
  }
  .bl8 .contacts {
    margin-top: 15px;
    margin-left: 315px;
  }
  .bl8 .contacts a {
    font-weight: 700;
    font-size: 18px;
  }
  .bl8 .contacts .dot {
    color: #fec01d;
    font-size: 20px;
    padding: 0 5px;
  }
  .bl8 .map {
    height: 450px;
    margin-top: 72px;
  }
  footer {
    background-color: #fec01d;
    height: 30px;
    color: black;
  }
  footer .foot1 {
    color: black;
    float: left;
    font-size: 14px;
    letter-spacing: -0.075px;
  }
  footer .foot2 {
    float: right;
    font-size: 14px;
    letter-spacing: -0.075px;
  }
  footer .company-info {
    font-size: 11px;
    font-weight: 700;
    padding: 6px 0 0px 7px;
    letter-spacing: 0.5px;
  }
  footer .dviga-info {
    font-size: 11px;
    font-weight: 700;
    padding: 6px 11px 0px 0px;
    letter-spacing: 0.5px;
    text-transform: none;
  }
  footer .dviga-info a {
    text-transform: none;
  }
  footer .dviga-info .linktodviga {
    text-transform: uppercase;
    text-decoration: underline;
    color: black;
    font-weight: 800;
  }
  .overlay {
    outline: none;
    overflow: hidden;
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    background: rgba(0, 0, 0, 0.5);
    z-index: 300;
    display: none;
  }
  .popup_body {
    overflow: hidden;
    outline: none;
    position: fixed;
    padding-left: 121px;
    top: 50%;
    left: 50%;
    width: 800px;
    height: 550px;
    margin: -275px 0 0 -400px;
    background: white;
    z-index: 400;
    display: none;
  }
  .popup_body a.close {
    position: relative;
    z-index: 1100;
    float: right;
    margin: 26px 37px 0 0;
    display: block;
    width: 30px;
    height: 30px;
    padding: 25px;
    background: url('../images/close.png') no-repeat center;
  }
  .popup_body h1.pp-h1 {
    font-size: 22px;
    font-weight: 800;
    margin-top: 38px;
    letter-spacing: -0.4px;
    text-transform: uppercase;
    text-align: left;
  }
  .popup_body p.pp-p {
    font-size: 15px;
    padding-top: 10px;
  }
  .popup_body form {
    margin-top: 25px;
    transition: all 0.3s ease;
  }
  .popup_body form .title {
    font-size: 18px;
    padding-left: 1px;
  }
  .popup_body form .titels {
    height: 50px;
    width: 500px;
  }
  .popup_body form .t2 {
    float: left;
    padding-top: 23px;
    padding-right: 277px;
  }
  .popup_body form .t3 {
    float: left;
    padding-top: 23px;
  }
  .popup_body form .same-box {
    width: 590px;
    height: 40px;
    border-radius: 9px;
    margin-top: 12px;
    margin-left: -17px;
    border: 1px solid #fec01d;
    font-size: 15px;
    font-style: italic;
    padding-left: 18px;
  }
  .popup_body form .pp-txt {
    margin-top: 13px;
  }
  .popup_body form .pp_phone {
    width: 320px;
    margin-right: 43px;
  }
  .popup_body form .pp_metr {
    width: 240px;
    background: url('../images/bghac.png') no-repeat 18px 11px;
  }
  .popup_body form .pp_metr:focus {
    background: none;
  }
  .popup_body form .pp_subm {
    margin-top: 34px;
    height: 50px;
    font-size: 22px;
    font-weight: 800;
    padding-left: 0;
    font-style: normal;
    text-align: center;
    background: #fec01d;
    cursor: pointer;
    transition: background 0.3s ease;
    transition: color 0.3s ease;
  }
  .popup_body form .pp_subm:hover {
    background: black;
    transition: background 0.3s ease;
    color: white;
    transition: color 0.3s ease;
  }
  .popup_body p {
    color: black;
  }
  .popup_body p:nth-of-type(2) {
    font-style: italic;
    font-size: 13px;
    letter-spacing: -0.5px;
    text-decoration: underline;
    margin-top: 28px;
    margin-left: 168px;
  }
  .popup_body p:nth-of-type(3) {
    font-size: 13px;
    margin-top: 39px;
    margin-left: 60px;
    letter-spacing: -0.05px;
    padding-left: 44px;
  }
  .popup_body p:nth-of-type(3):before {
    content: '';
    background: url("data:img/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAZCAMAAAAGyf7hAAAABGdBTUEAALGPC/xhBQAAACBjSFJNAAB6JgAAgIQAAPoAAACA6AAAdTAAAOpgAAA6mAAAF3CculE8AAABcVBMVEUAAAD9vx39vx39vx39vx39vx39vx39vx39vx39vx39vx39vx39vx39vx39vx39vx39vx39vx39vx39vx39vx39vx39vx39vx39vx39vx39vx39vx39vx39vx39vx39vx39vx39vx39vx39vx39vx39vx39vx39vx39vx39vx39vx0jHyAjHyAjHyAjHyAjHyAjHyAjHyAjHyAjHyAjHyAjHyD9vx0jHyAjHyAjHyAjHyAjHyD9vx39vx39vx39vx0jHyAjHyD9vx39vx39vx39vx0jHyAjHyAjHyD9vx39vx39vx39vx39vx39vx39vx39vx39vx39vx39vx39vx39vx39vx39vx39vx39vx39vx39vx39vx39vx39vx39vx39vx39vx39vx39vx39vx39vx39vx39vx39vx39vx39vx39vx39vx39vx39vx39vx39vx39vx39vx39vx39vx39vx39vx39vx39vx0jHyAAAADKBNVgAAAAeHRSTlMADKLCIU7i82sBQcFMMNDKQCZxfwsF781uKALn99p3EA503P3o0nIzds+AaUKIgjJJsgSbZgaID8n9W4aL/vCozPyquOkEFcut5rdgXUpp2+URBtTrCrqWW7IJjJ2Byx0NrGP5WkTfjwM02LkjFVCH+/GbFCrD0Tkzf4J3AAAAAWJLR0QAiAUdSAAAAAlwSFlzAAALEgAACxIB0t1+/AAAAQBJREFUKM9jYIACRiZmFgY0wMpWUcHOwYksxMVdwcbDy1fBLwAV4BQU4qtgFxZhYBAVEqsQl5CUYmCQlqmokJWThyhQUFSqqFBWYahQVeNFNkldQ7OCoUIL3U4tsgW1dTAFdSv1kAT1DSBihkZIgsaVJmAxU5iZZkDa3MLSyhoqxmBjy2BnD2KYOzjCxBicVBicXVxBLDd3D6iYlKcXg3eFD6ozfSv8GET9AwKRxYKCQ0KBYWkbFoQkGF4RAaI0KiKjYEKc0RUxEFZsRVw8hJXgVJEoCpVPShZLAdGpaRXpGXCDMrMqsnNyYypC8pDtjMovkCmsKCpGC8WS0rJyGBsA5ok3Yxojg1IAAAAASUVORK5CYII=") no-repeat;
    height: 30px;
    width: 25px;
    position: absolute;
    margin-left: -47px;
    margin-left: -40px;
    margin-top: -4px;
  }
}
@media screen and (max-width: 720px) {
  .body_desktop {
    display: none;
  }
  .body_mobile {
    display: block;
  }
  html,
  body {
    margin: 0;
    padding: 0;
    min-width: 320px;
    font-family: 'Open Sans', sans-serif;
  }
  html .wrap,
  body .wrap {
    min-width: 320px;
    max-width: 320px;
    margin: auto;
  }
  html .rel,
  body .rel {
    position: relative;
  }
  .scroll-call {
    display: none;
    position: fixed;
    top: 0;
    background: #fff;
    min-height: 50px;
    z-index: 300;
  }
  .scroll-call ul {
    padding-left: 19px;
    height: 50px;
  }
  .scroll-call ul li.cl {
    float: left;
    font-size: 12px;
    font-weight: 800;
    padding-top: 18px;
    letter-spacing: -0.9px;
    text-transform: uppercase;
  }
  .scroll-call ul li:first-child {
    padding-right: 5px;
  }
  .scroll-call ul li:nth-child(2) {
    color: #fec01d;
    padding-right: 8px;
  }
  .scroll-call ul li:nth-child(3) {
    letter-spacing: 0;
  }
  .scroll-call ul:after {
    content: '';
    display: inline-block;
    vertical-align: middle;
    position: absolute;
    width: 100%;
    height: 50px;
    top: -35px;
    left: 280px;
    position: relative;
    background: #fec01d;
  }
  .scroll-call ul:before {
    content: '';
    display: inline-block;
    vertical-align: middle;
    position: absolute;
    width: 100%;
    height: 50px;
    right: 320px;
    top: 0;
    background: white;
  }
  .scroll-call .truba {
    position: absolute;
    top: 0;
    height: 50px;
    width: 45px;
    right: 0px;
    border-radius: 25px;
    background-color: #fec01d;
    padding: 15px 0 0 14px;
  }
  .menu-holder {
    min-height: 159px;
    width: 100%;
    z-index: 100;
  }
  .menu-holder .wrap {
    background: white;
  }
  .menu-holder .logo {
    margin: 32px 0 0 111px;
    display: inline-block;
    width: 99px;
    height: 102px;
  }
  .menu-holder .flag {
    margin-top: 15px;
    margin-right: 12px;
    float: right;
    padding: 15px;
    cursor: pointer;
  }
  .menu-holder .flag .f {
    width: 25px;
    height: 3px;
    margin-bottom: 3px;
  }
  .menu-holder .flag .f1 {
    background-color: #000;
  }
  .menu-holder .flag .f2 {
    background-color: #ff3d3d;
  }
  .menu-holder .flag .f3 {
    background-color: #fec01d;
  }
  .menu-holder .burger-menu {
    float: right;
    position: relative;
    width: 25px;
    height: 16px;
    cursor: pointer;
    margin-top: 30px;
    margin-right: 27px;
  }
  .menu-holder .burger-menu .burger {
    position: absolute;
    background: #ec1c23;
    width: 100%;
    height: 3px;
    right: 0px;
    margin-top: 6px;
    opacity: 1;
  }
  .menu-holder .burger-menu .burger::before,
  .menu-holder .burger-menu .burger::after {
    content: "";
    position: absolute;
    display: block;
    width: 25px;
    height: 3px;
  }
  .menu-holder .burger-menu .burger::before {
    background: #fdc01d;
    top: 6px;
  }
  .menu-holder .burger-menu .burger::after {
    background: #000000;
    bottom: 6px;
  }
  .menu-holder .burger::after,
  .menu-holder .burger::before,
  .menu-holder .burger {
    transition: all 0.3s ease-in-out;
    -webkit-transition: all 0.3s ease-in-out;
  }
  .menu-holder .burger-menu.menu-on .burger::after {
    background: #000000;
    transform: rotate(-45deg);
    -webkit-transform: rotate(-45deg);
    bottom: 0px;
  }
  .menu-holder .burger-menu.menu-on .burger::before {
    background: #000000;
    transform: rotate(45deg);
    -webkit-transform: rotate(45deg);
    top: 0px;
  }
  .menu-holder .burger-menu.menu-on .burger {
    background: rgba(111, 111, 111, 0);
  }
  .menu-holder .off {
    display: none;
  }
  .menu-holder .on {
    display: block;
  }
  .menu-holder #menu {
    top: 159px;
    color: black;
    width: 100%;
    padding-top: 27px;
    background: white;
    z-index: 990;
  }
  .menu-holder #menu li {
    font-family: 'Open Sans Condensed', sans-serif;
    text-align: center;
  }
  .menu-holder #menu .item {
    font-size: 17px;
    padding: 10px 0px 10px 0;
    font-weight: 700;
    text-transform: uppercase;
    letter-spacing: -1.4px;
  }
  .menu-holder #menu .item:hover {
    color: #ed1c24;
  }
  .menu-holder #menu .btn:first-letter {
    text-transform: capitalize;
  }
  .menu-holder #menu .btn-hold {
    background: white;
    height: 99px;
    width: 100%;
  }
  .menu-holder #menu .btn-hold .btn {
    float: left;
    text-align: none;
    font-size: 17px;
    font-weight: 700;
    letter-spacing: -1.2px;
    margin-top: 32px;
    margin-left: 70px;
    padding: 6px 20px 8px 20px;
    background: #fec01d;
    border-radius: 9px;
    cursor: pointer;
  }
  .menu-holder #menu .btn-hold .btn:hover {
    color: white;
    background: black;
  }
  .bl1 {
    width: 100%;
  }
  .bl1 header.tnxx {
    height: 272px;
  }
  .bl1 header {
    height: 322px;
    background: #fec01d url('../images/mob/mbg1.jpg') repeat-x center top;
    color: white;
  }
  .bl1 header #danke {
    text-align: center;
    text-transform: uppercase;
    padding-top: 41px;
    letter-spacing: 1.2px;
    line-height: 60px;
    font-size: 47px;
  }
  .bl1 header .tnx {
    text-align: center;
    font-size: 15px;
    padding-top: 28px;
  }
  .bl1 header h1:first-of-type {
    text-align: center;
    text-transform: uppercase;
    padding-top: 22px;
    letter-spacing: 1.2px;
    line-height: 50px;
    font-size: 33px;
  }
  .bl1 header .flat {
    float: left;
    margin: 25px 0 0 35px;
    padding: 7px 17px 7px 20px;
    text-transform: uppercase;
    font-weight: 700;
    font-size: 18px;
    text-align: center;
    letter-spacing: 0.8px;
    border: 2px solid #fec01d;
    border-radius: 15px;
    font-style: normal;
  }
  .bl1 header ul {
    margin: 35px 0 0 45px;
    letter-spacing: -1px;
    float: left;
    height: 35px;
  }
  .bl1 header ul li {
    padding-right: 12px;
    float: left;
    text-transform: uppercase;
    font-weight: bold;
    font-size: 14px;
  }
  .bl1 header ul li:nth-child(even) {
    color: #fec01d;
    margin-top: -1px;
  }
  .bl1 header ul li:first-of-type {
    padding-right: 12px;
  }
  .bl1 header ul li:nth-of-type(3) {
    padding-right: 11px;
    letter-spacing: 0px;
  }
  .bl1 header ul li:last-of-type {
    letter-spacing: 0.2px;
    padding-right: 0;
  }
  .bl1 .forma {
    height: 600px;
    background: url('../images/mob/mbg2.jpg') repeat-x center top;
    color: black;
  }
  .bl1 .forma h2,
  .bl1 .forma h3 {
    text-align: center;
  }
  .bl1 .forma h2 {
    text-transform: uppercase;
    padding-top: 31px;
    font-size: 24px;
    font-weight: 700;
  }
  .bl1 .forma h3 {
    margin-top: 15px;
    font-size: 13px;
    letter-spacing: -0.6px;
    line-height: 14px;
  }
  .bl1 .forma form {
    height: 300px;
    margin-left: 50px;
    padding-top: 34px;
  }
  .bl1 .forma form .eq-inp {
    float: left;
    background: #fff;
    height: 41px;
    width: 220px;
    border: 2px solid white;
    border-radius: 9px;
    font-style: italic;
    padding-left: 43px;
  }
  .bl1 .forma form .eq-inp::-webkit-input-placeholder {
    color: #6f6f6f;
  }
  .bl1 .forma form .eq-inp::-moz-placeholder {
    color: #6f6f6f;
  }
  .bl1 .forma form .eq-inp:-moz-placeholder {
    color: #6f6f6f;
  }
  .bl1 .forma form .eq-inp:-ms-input-placeholder {
    color: #6f6f6f;
  }
  .bl1 .forma form .name-1bl {
    background: #ffffff url(../images/mob/man1.svg) no-repeat 11px 9px;
    background-size: 17px 18px;
  }
  .bl1 .forma form .tel-1bl {
    background: #ffffff url(../images/mob/phone1.svg) no-repeat 11px 11px;
    margin-top: 30px;
    background-size: 17px 18px;
  }
  .bl1 .forma form .metr {
    background: #ffffff url(../images/mob/metr1.svg) no-repeat 6px 9px;
    margin-top: 30px;
    background-size: 26px 18px;
    position: relative;
  }
  .bl1 .forma form .metr input {
    border: none;
    height: 38px;
  }
  .bl1 .forma form .metr .s {
    position: absolute;
    top: 6px;
    font-style: italic;
    font-size: 13px;
    color: #6f6f6f;
  }
  .bl1 .forma form input[type="submit"] {
    border: 0;
    cursor: pointer;
    font-weight: bold;
    font-size: 18px;
    padding-left: 0;
    margin-top: 35px;
    width: 150px;
    margin-left: 35px;
    font-style: normal;
  }
  .bl1 .forma form input[type="submit"]:hover {
    background: black;
    color: white;
  }
  .bl1 .forma form p {
    color: black;
    text-align: center;
    float: left;
  }
  .bl1 .forma form p:first-of-type {
    font-style: italic;
    font-size: 11px;
    font-weight: 700;
    text-decoration: underline;
    margin-top: 33px;
    margin-left: -2px;
    letter-spacing: -0.1px;
  }
  .bl1 p.warrant {
    font-size: 10px;
    font-weight: 700;
    margin-top: 26px;
    letter-spacing: -0.1px;
    padding-left: 92px;
    float: left;
  }
  .bl1 p.warrant:before {
    content: '';
    background: url('../images/mob/war1.svg') no-repeat;
    background-size: 24px 29px;
    height: 30px;
    width: 25px;
    position: absolute;
    margin-left: -42px;
    margin-top: 0px;
  }
  .bl1 .machine {
    position: absolute;
    width: 295px;
    height: 160px;
    background: url('../images/mob/machine.png') no-repeat 0 0;
    z-index: 200;
    overflow: hidden;
    top: 540px;
    left: 18px;
  }
  .bl2 {
    min-height: 650px;
  }
  .bl2 h1.lines {
    padding-top: 160px;
    text-align: center;
    text-transform: uppercase;
    font-weight: 700;
    letter-spacing: 1.1px;
    font-size: 22px;
    line-height: 34px;
    overflow: hidden;
  }
  .bl2 h1.lines:before,
  .bl2 h1.lines:after {
    content: '';
    display: inline-block;
    vertical-align: middle;
    position: absolute;
    width: 100%;
    height: 4px;
    position: relative;
  }
  .bl2 h1.lines:before {
    margin-left: -100%;
    left: -40px;
    background-color: #ed1c24;
    top: 32px;
  }
  .bl2 h1.lines:after {
    background-color: #fec01d;
    margin-right: -100%;
    right: -17px;
    top: -36px;
  }
  .bl2 .-mob-slider-box {
    width: 320px;
    height: 410px;
    position: relative;
    overflow: hidden;
  }
  .bl2 .-mob-slider-box .ar {
    position: absolute;
    top: 50px;
    width: 20px;
    height: 25px;
    padding: 25px;
    z-index: 20;
    cursor: pointer;
  }
  .bl2 .-mob-slider-box .L {
    left: 30px;
    background: url('../images/mob/yelar.png') no-repeat 20px;
  }
  .bl2 .-mob-slider-box .R {
    left: 240px;
    background: url('../images/mob/yelar.png') no-repeat -50px;
  }
  .bl2 .-mob-slider-box .L:hover {
    left: 30px;
    background: url('../images/mob/blar.png') no-repeat 20px;
  }
  .bl2 .-mob-slider-box .R:hover {
    left: 240px;
    background: url('../images/mob/blar.png') no-repeat -50px;
  }
  .bl2 .-mob-slider-box .-mob-slider {
    width: 1280px;
    height: 330px;
    position: relative;
  }
  .bl2 .-mob-slider-box .-mob-slider .-mob-slider-bl {
    float: left;
    width: 320px;
    height: 330px;
    top: 0;
  }
  .bl2 .-mob-slider-box .-mob-slider .-mob-slider-bl .ico {
    height: 100px;
    width: 100px;
    margin: 15px 0 0 108px;
  }
  .bl2 .-mob-slider-box .-mob-slider .-mob-slider-bl .ico .icoico {
    float: left;
  }
  .bl2 .-mob-slider-box .-mob-slider .-mob-slider-bl .ic1 {
    background: url(../images/mob/ok.svg) no-repeat 18px 14px;
    background-size: 67px 77px;
  }
  .bl2 .-mob-slider-box .-mob-slider .-mob-slider-bl .ic2 {
    background: url(../images/mob/shield.svg) no-repeat 22px 25px;
    background-size: 61px 69px;
  }
  .bl2 .-mob-slider-box .-mob-slider .-mob-slider-bl .ic3 {
    background: url(../images/mob/time.svg) no-repeat 0px 24px;
    background-size: 99px 70px;
  }
  .bl2 .-mob-slider-box .-mob-slider .-mob-slider-bl h1 {
    margin-top: 26px;
    font-size: 18px;
    font-weight: 700;
    text-align: center;
    letter-spacing: -0.1px;
    text-transform: uppercase;
  }
  .bl2 .-mob-slider-box .-mob-slider .-mob-slider-bl p {
    margin-top: 29px;
    text-align: left;
    padding-left: 18px;
    font-family: 'Open Sans semibold', sans-serif;
    font-size: 11.5px;
    letter-spacing: 0.2px;
    font-weight: 700;
    line-height: 18px;
  }
  .bl2 .-mob-slider-box .-mob-slider .-mob-slbl3 .ico {
    margin: 15px 0 0 90px;
  }
  .bl2 .-mob-slider-box .-mob-slbl1 {
    left: 0;
  }
  .bl2 .-mob-slider-box .-mob-slbl2 {
    left: 320px;
  }
  .bl2 .-mob-slider-box .-mob-slbl3 {
    left: 640px;
  }
  .bl2 .-mob-slider-box .-mob-slbl4 {
    left: 980px;
  }
  .bl3 {
    background: #fec01d url('../images/mob/mbg3.jpg') repeat-x center;
  }
  .bl3 .-mob-slider-box2 {
    width: 320px;
    height: 550px;
    position: relative;
    overflow: hidden;
  }
  .bl3 .-mob-slider-box2 .ar2 {
    position: absolute;
    top: 42px;
    width: 20px;
    height: 25px;
    padding: 25px;
    z-index: 20;
    cursor: pointer;
  }
  .bl3 .-mob-slider-box2 .L2 {
    left: 30px;
    background: url('../images/mob/whar.png') no-repeat 20px;
  }
  .bl3 .-mob-slider-box2 .R2 {
    left: 240px;
    background: url('../images/mob/whar.png') no-repeat -190px;
  }
  .bl3 .-mob-slider-box2 .L2:hover {
    left: 30px;
    background: url('../images/mob/blar.png') no-repeat 20px;
  }
  .bl3 .-mob-slider-box2 .R2:hover {
    left: 240px;
    background: url('../images/mob/blar.png') no-repeat -50px;
  }
  .bl3 .-mob-slider-box2 .warkers {
    height: 400px;
    width: 650px;
    top: 143px;
    left: 60px;
    position: absolute;
    background: url('../images/mob/warkers.png') no-repeat 0;
  }
  .bl3 .-mob-slider-box2 .-mob-slider2 {
    width: 1280px;
    height: 550px;
    position: relative;
  }
  .bl3 .-mob-slider-box2 .-mob-slider2 .-mob-slider-bl2 {
    float: left;
    width: 320px;
    height: 550px;
    top: 0;
    color: white;
  }
  .bl3 .-mob-slider-box2 .-mob-slider2 .-mob-slider-bl2 h1 {
    margin-top: 43px;
    font-size: 32px;
    font-weight: 700;
    text-align: center;
    letter-spacing: -0.1px;
    text-transform: uppercase;
  }
  .bl3 .-mob-slider-box2 .-mob-slider2 .-mob-slider-bl2 p {
    margin-top: 29px;
    text-align: left;
    padding-left: 18px;
    font-family: 'Open Sans semibold', sans-serif;
    font-size: 11.5px;
    letter-spacing: 0.2px;
    font-weight: 700;
    line-height: 18px;
    text-align: center;
  }
  .bl3 .-mob-slider-box2 .-mob-slbl1 {
    background: red;
    left: 0;
  }
  .bl3 .-mob-slider-box2 .-mob-slbl2 {
    background: blue;
    left: 320px;
  }
  .bl3 .-mob-slider-box2 .-mob-slbl3 {
    background: yellow;
    left: 640px;
  }
  .bl3 .-mob-slider-box2 .-mob-slbl4 {
    background: pink;
    left: 980px;
  }
  .bl4 {
    background: white;
  }
  .bl4 .-mob-slider-box3 {
    width: 320px;
    height: 480px;
    position: relative;
    overflow: hidden;
  }
  .bl4 .-mob-slider-box3 .ar3 {
    position: absolute;
    top: 50px;
    width: 20px;
    height: 25px;
    padding: 25px;
    z-index: 20;
    cursor: pointer;
  }
  .bl4 .-mob-slider-box3 .L3 {
    left: 9px;
    background: url('../images/mob/yelar.png') no-repeat 20px;
  }
  .bl4 .-mob-slider-box3 .R3 {
    left: 258px;
    background: url('../images/mob/yelar.png') no-repeat -50px;
  }
  .bl4 .-mob-slider-box3 .L3:hover {
    left: 9px;
    background: url('../images/mob/blar.png') no-repeat 20px;
  }
  .bl4 .-mob-slider-box3 .R3:hover {
    left: 258px;
    background: url('../images/mob/blar.png') no-repeat -50px;
  }
  .bl4 .-mob-slider-box3 .compare {
    margin-top: 24px;
    line-height: 34px;
    font-size: 23px;
    font-weight: 700;
    text-align: center;
    text-transform: uppercase;
  }
  .bl4 .-mob-slider-box3 .stajka {
    position: absolute;
    top: 164px;
    border: 2px solid #fec01d;
    border-radius: 9px;
    float: left;
    font-weight: 700;
    font-size: 18px;
    padding: 5px 19px 7px 20px;
  }
  .bl4 .-mob-slider-box3 .wet {
    border-bottom-right-radius: 0;
    border-top-right-radius: 0;
    border-right: 0;
    right: 0;
    padding-right: 36px;
    padding-left: 36px;
  }
  .bl4 .-mob-slider-box3 .dry {
    background: #fec01d;
    border-bottom-left-radius: 0;
    border-top-left-radius: 0;
    border-left: 0;
    padding-left: 23px;
  }
  .bl4 .-mob-slider-box3 .do {
    position: absolute;
    color: #fec01d;
    font-size: 36px;
    font-weight: 800;
    left: 152px;
  }
  .bl4 .-mob-slider-box3 .dot1 {
    top: 275px;
  }
  .bl4 .-mob-slider-box3 .dot2 {
    top: 397px;
  }
  .bl4 .-mob-slider-box3 .-mob-slider3 {
    width: 1600px;
    height: 480px;
    position: relative;
  }
  .bl4 .-mob-slider-box3 .-mob-slider3 .-mob-slider-bl3 {
    float: left;
    width: 320px;
    height: 480px;
    top: 0;
  }
  .bl4 .-mob-slider-box3 .-mob-slider3 .-mob-slider-bl3 h1 {
    font-size: 16px;
    text-align: center;
    letter-spacing: 0.4px;
  }
  .bl4 .-mob-slider-box3 .-mob-slider3 .-mob-slider-bl3 h1:nth-of-type(2) {
    margin-top: 88px;
    letter-spacing: 0.8px;
  }
  .bl4 .-mob-slider-box3 .-mob-slbl13 {
    left: 0;
  }
  .bl4 .-mob-slider-box3 .-mob-slbl13 h1:first-of-type {
    margin-top: 110px;
  }
  .bl4 .-mob-slider-box3 .-mob-slbl13 div {
    float: left;
    text-align: center;
  }
  .bl4 .-mob-slider-box3 .-mob-slbl13 div:first-of-type {
    margin: 29px 0 0 56px;
  }
  .bl4 .-mob-slider-box3 .-mob-slbl13 div:nth-of-type(2) {
    margin: 29px 0 0 157px;
  }
  .bl4 .-mob-slider-box3 .-mob-slbl13 div:nth-of-type(3) {
    margin: 27px 0 0 38px;
  }
  .bl4 .-mob-slider-box3 .-mob-slbl13 div:nth-of-type(4) {
    margin: 27px 0 0 125px;
  }
  .bl4 .-mob-slider-box3 .-mob-slbl23 {
    left: 320px;
  }
  .bl4 .-mob-slider-box3 .-mob-slbl23 h1:first-of-type {
    margin-top: 113px;
  }
  .bl4 .-mob-slider-box3 .-mob-slbl23 div {
    float: left;
    text-align: center;
  }
  .bl4 .-mob-slider-box3 .-mob-slbl23 div:first-of-type {
    margin: 29px 0 0 56px;
  }
  .bl4 .-mob-slider-box3 .-mob-slbl23 div:nth-of-type(2) {
    margin: 29px 0 0 117px;
  }
  .bl4 .-mob-slider-box3 .-mob-slbl23 div:nth-of-type(3) {
    margin: 37px 0 0 68px;
  }
  .bl4 .-mob-slider-box3 .-mob-slbl23 div:nth-of-type(4) {
    margin: 37px 0 0 125px;
  }
  .bl4 .-mob-slider-box3 .-mob-slbl33 {
    left: 640px;
  }
  .bl4 .-mob-slider-box3 .-mob-slbl33 h1:first-of-type {
    margin-top: 114px;
  }
  .bl4 .-mob-slider-box3 .-mob-slbl33 div {
    float: left;
    text-align: center;
  }
  .bl4 .-mob-slider-box3 .-mob-slbl33 div:first-of-type {
    margin: 29px 0 0 26px;
  }
  .bl4 .-mob-slider-box3 .-mob-slbl33 div:nth-of-type(2) {
    margin: 29px 0 0 97px;
  }
  .bl4 .-mob-slider-box3 .-mob-slbl33 div:nth-of-type(3) {
    margin: 40px 0 0 68px;
  }
  .bl4 .-mob-slider-box3 .-mob-slbl33 div:nth-of-type(4) {
    margin: 40px 0 0 145px;
  }
  .bl4 .-mob-slider-box3 .-mob-slbl43 {
    left: 980px;
  }
  .bl4 .-mob-slider-box3 .-mob-slbl43 h1:first-of-type {
    margin-top: 114px;
  }
  .bl4 .-mob-slider-box3 .-mob-slbl43 div {
    float: left;
    text-align: center;
  }
  .bl4 .-mob-slider-box3 .-mob-slbl43 div:first-of-type {
    margin: 29px 0 0 36px;
  }
  .bl4 .-mob-slider-box3 .-mob-slbl43 div:nth-of-type(2) {
    margin: 29px 0 0 117px;
  }
  .bl4 .-mob-slider-box3 .-mob-slbl43 div:nth-of-type(3) {
    margin: 43px 0 0 44px;
  }
  .bl4 .-mob-slider-box3 .-mob-slbl43 div:nth-of-type(4) {
    margin: 43px 0 0 145px;
  }
  .bl4 .-mob-slider-box3 .-mob-slbl53 h1:first-of-type {
    margin-top: 114px;
  }
  .bl4 .-mob-slider-box3 .-mob-slbl53 div {
    float: left;
    text-align: center;
  }
  .bl4 .-mob-slider-box3 .-mob-slbl53 div:first-of-type {
    margin: 24px 0 0 20px;
  }
  .bl4 .-mob-slider-box3 .-mob-slbl53 div:nth-of-type(2) {
    margin: 24px 0 0 100px;
  }
  .bl4 .-mob-slider-box3 .-mob-slbl53 .best {
    font-size: 14px;
    text-align: right;
    letter-spacing: .4px;
    font-weight: 700;
    background: #fec01d url(../images/mob/thumb.svg) no-repeat 219px 11px;
    background-size: 29px ;
    margin-left: 22px;
    margin-top: 70px;
    padding: 16px 64px 16px 25px;
    line-height: 17px;
    border-radius: 15px;
  }
  .bl5 {
    background: #3b3b3b url('../images/mob/mbg5.jpg') repeat-x center;
    height: 800px;
  }
  .bl5 .wrap {
    color: #fff;
  }
  .bl5 .wrap h1 {
    padding-top: 49px;
    text-align: center;
    font-size: 23px;
    letter-spacing: 0.5px;
    line-height: 34px;
    text-transform: uppercase;
  }
  .bl5 .wrap p {
    text-align: center;
    font-size: 12px;
    padding-top: 38px;
    letter-spacing: 0.3px;
    line-height: 15px;
  }
  .bl5 .wrap form {
    margin-left: 50px;
    padding-top: 51px;
    /* Состояние при наведении курсора */
    /* активное состояние */
  }
  .bl5 .wrap form .eq-5bl {
    background: #fff;
    height: 41px;
    width: 220px;
    border: 2px solid #fec01d;
    border-radius: 9px;
    font-style: italic;
    padding-left: 43px;
    color: white;
  }
  .bl5 .wrap form .name-5bl {
    background: url(../images/mob/man2.svg) no-repeat 9px 9px;
    background-size: 19px 18px;
  }
  .bl5 .wrap form .tel-5bl {
    background: url(../images/mob/phone2.svg) no-repeat 9px 9px;
    margin-top: 19px;
    background-size: 19px 18px;
  }
  .bl5 .wrap form .metr {
    background: url(../images/mob/metr2.svg) no-repeat 9px 9px;
    margin-top: 20px;
    padding-left: 51px;
    background-size: 26px 18px;
    position: relative;
    color: #fff;
  }
  .bl5 .wrap form .metr input {
    border: none;
    background: none;
    height: 36px;
    width: 160px;
    color: #fff;
  }
  .bl5 .wrap form .metr .s2 {
    position: absolute;
    top: 7px;
    font-style: italic;
    font-size: 13px;
    color: #fff;
  }
  .bl5 .wrap form .sloi {
    background: url(../images/mob/sloi2.svg) no-repeat 18px 7px;
    margin-top: 20px;
    padding-left: 51px;
    background-size: 10px 25px;
  }
  .bl5 .wrap form .eq-5bl::-webkit-input-placeholder {
    color: #fff;
  }
  .bl5 .wrap form .eq-5bl::-moz-placeholder {
    color: #fff;
  }
  .bl5 .wrap form .eq-5bl:-moz-placeholder {
    color: #fff;
  }
  .bl5 .wrap form .eq-5bl:-ms-input-placeholder {
    color: #fff;
  }
  .bl5 .wrap form .wrapper-dropdown-mob {
    /* размер и позиционирование */
    position: relative;
    margin-top: 20px;
    /*Общие стили */
    background: none;
    color: #fff;
    outline: none;
    cursor: pointer;
    padding: 10px 0 0 53px;
    font-size: 13px;
    background: url(../images/mob/city.svg) no-repeat 14px 9px;
    background-size: 19px;
    /* Настройки шрифтов */
  }
  .bl5 .wrap form .wrapper-dropdown-mob:after {
    content: "";
    width: 0;
    height: 0;
    position: absolute;
    right: 12px;
    top: 50%;
    margin-top: -2px;
    border-style: solid;
    border-width: 6px 6px 0 6px ;
    border-color: #fff transparent;
  }
  .bl5 .wrap form .wrapper-dropdown-mob .dropdown-mob {
    /* размер и позиция */
    position: absolute;
    top: 100%;
    width: 220px;
    left: -2px;
    right: 0;
    /* Стили */
    background: #000;
    color: white;
    font-weight: normal;
    border-left: 2px solid #fec01d;
    border-right: 2px solid #fec01d;
    border-bottom: 2px solid #fec01d;
    border-bottom-right-radius: 9px;
    border-bottom-left-radius: 9px;
    opacity: 0;
    pointer-events: none;
  }
  .bl5 .wrap form .wrapper-dropdown-mob .dropdown-mob li a {
    display: block;
    text-decoration: none;
    font-weight: normal;
    font-style: normal;
    color: #fff;
    padding: 5px 20px;
  }
  .bl5 .wrap form .wrapper-dropdown-mob .dropdown-mob li:hover a {
    background: #342707;
  }
  .bl5 .wrap form .wrapper-dropdown-mob .dropdown-mob li:last-of-type:hover a {
    background: #342707;
    border-bottom-left-radius: 9px;
    border-bottom-right-radius: 9px;
  }
  .bl5 .wrap form .wrapper-dropdown-mob.active .dropdown-mob {
    opacity: 0.9;
    pointer-events: auto;
  }
  .bl5 .wrap form .wrapper-dropdown-mob.active:after {
    border-color: #fff transparent;
    border-width: 0 6px  6px 6px;
    margin-top: -2px;
  }
  .bl5 .wrap form .wrapper-dropdown-mob.active {
    background: #000000 url(../images/mob/city.svg) no-repeat 14px 9px;
    background-size: 19px;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
  }
  .bl5 .wrap form p {
    color: white;
    text-align: center;
  }
  .bl5 .wrap form p:first-of-type {
    font-style: italic;
    font-size: 12px;
    margin-left: -51px;
    text-decoration: underline;
    letter-spacing: -0.2px;
    margin-top: 7px;
  }
  .bl5 .wrap form input[type="submit"] {
    border: 0;
    border-radius: 10px;
    cursor: pointer;
    font-weight: 700;
    font-size: 18px;
    padding-left: 0;
    padding: 11px 0px 9px 5px;
    margin-top: 40px;
    width: 150px;
    margin-left: 32px;
    font-style: normal;
    letter-spacing: 0.8px;
    background: #fec01d;
  }
  .bl5 .wrap form input[type="submit"]:hover {
    background: white;
    color: black;
  }
  .bl5 .wrap p.warrant {
    font-size: 10px;
    letter-spacing: 0.1px;
    text-align: left;
    padding-top: 34px;
    padding-left: 93px;
  }
  .bl5 .wrap p.warrant:before {
    content: '';
    background: url('../images/mob/war2.svg') no-repeat;
    height: 30px;
    width: 25px;
    position: absolute;
    margin-left: -43px;
    margin-top: 0px;
  }
  .bl5 .wrap .war2 {
    position: absolute;
    left: 50px;
    top: 714px;
  }
  .bl6 h1:first-of-type {
    text-align: center;
    margin-top: 41px;
    font-size: 33px;
    line-height: 48px;
    font-weight: 700;
    overflow: hidden;
    text-transform: uppercase;
  }
  .bl6 h1:first-of-type:before,
  .bl6 h1:first-of-type:after {
    content: '';
    display: inline-block;
    vertical-align: middle;
    position: absolute;
    width: 100%;
    height: 4px;
    position: relative;
  }
  .bl6 h1:first-of-type:before {
    margin-left: -100%;
    left: -25px;
    background-color: #ed1c24;
    top: 19px;
  }
  .bl6 h1:first-of-type:after {
    background-color: #fec01d;
    margin-right: -100%;
    right: -9px;
    top: -29px;
  }
  .bl6 h2 {
    font-weight: 700;
    font-size: 18px;
    text-align: center;
    margin-top: 55px;
    letter-spacing: 0.4px;
    line-height: 28px;
  }
  .bl6 .contacts {
    margin-top: 15px;
    text-align: center;
  }
  .bl6 .contacts a {
    font-weight: 700;
    font-size: 18px;
  }
  .bl6 .contacts .dot {
    color: #fec01d;
    font-size: 39px;
    padding: 0 5px;
    font-weight: 900;
  }
  .bl6 .contacts div:nth-of-type(2) {
    margin-top: 20px;
  }
  .bl6 .contacts div:nth-of-type(3) {
    margin-top: 10px;
  }
  .bl6 .map-mob {
    height: 450px;
    margin-top: 56px;
  }
  footer {
    height: 110px;
    background: #fec01d;
    color: black;
  }
  footer .wrap div {
    text-align: center;
    font-size: 12px;
    letter-spacing: -0.1px;
    font-weight: 600;
  }
  footer .wrap div a {
    text-transform: none;
  }
  footer .wrap div .linktodviga {
    text-transform: uppercase;
    text-decoration: underline;
    color: black;
    font-weight: 800;
  }
  footer .wrap div:first-of-type {
    padding-top: 32px;
  }
  footer .wrap div:last-of-type {
    padding-top: 18px;
  }
}
