/*
	Это файл, содержащий пути подключения всех стилей.
	Стили в данном файле не пишется.

*/



/*
 * Third party
 */
 *{box-sizing: border-box;}
 html{font-weight: normal;}
 :link,:visited { text-decoration:none; color: inherit; }
 
 ul,ol { list-style:none }
 
 h1,h2,h3,h4,h5,h6,pre,code { font-size:1em; }
 
 
 ul,ol,li,h1,h2,h3,h4,h5,h6,pre,form,body,html,p,blockquote,fieldset,input
 { margin:0; padding:0 }
 
 a img,:link img,:visited img, button { border:none;}
 
 
 address { font-style:normal }
 
 input:focus, input:active,button{
 	outline: none;
 }
 button{cursor: pointer;}




/*
 * Custom
 */

/* 
	Файл переменных
 */
@yellow: #fec01d;
@red: #ed1c24;
@err: #ff2232;
@font-face {
  font-family: 'Open Sans';
  font-weight: 300;
  font-style: normal;
  src: url('../fonts/Open-Sans-300/Open-Sans-300.eot');
  src: url('../fonts/Open-Sans-300/Open-Sans-300.eot?#iefix') format('embedded-opentype'),
       local('Open Sans Light'),
       local('Open-Sans-300'),
       url('../fonts/Open-Sans-300/Open-Sans-300.woff2') format('woff2'),
       url('../fonts/Open-Sans-300/Open-Sans-300.woff') format('woff'),
       url('../fonts/Open-Sans-300/Open-Sans-300.ttf') format('truetype'),
       url('../fonts/Open-Sans-300/Open-Sans-300.svg#OpenSans') format('svg');
}

@font-face {
  font-family: 'Open Sans';
  font-weight: 400;
  font-style: normal;
  src: url('../fonts/Open-Sans-regular/Open-Sans-regular.eot');
  src: url('../fonts/Open-Sans-regular/Open-Sans-regular.eot?#iefix') format('embedded-opentype'),
       local('Open Sans'),
       local('Open-Sans-regular'),
       url('../fonts/Open-Sans-regular/Open-Sans-regular.woff2') format('woff2'),
       url('../fonts/Open-Sans-regular/Open-Sans-regular.woff') format('woff'),
       url('../fonts/Open-Sans-regular/Open-Sans-regular.ttf') format('truetype'),
       url('../fonts/Open-Sans-regular/Open-Sans-regular.svg#OpenSans') format('svg');
}

@font-face {
  font-family: 'Open Sans';
  font-weight: 600;
  font-style: normal;
  src: url('../fonts/Open-Sans-600/Open-Sans-600.eot');
  src: url('../fonts/Open-Sans-600/Open-Sans-600.eot?#iefix') format('embedded-opentype'),
       local('Open Sans Semibold'),
       local('Open-Sans-600'),
       url('../fonts/Open-Sans-600/Open-Sans-600.woff2') format('woff2'),
       url('../fonts/Open-Sans-600/Open-Sans-600.woff') format('woff'),
       url('../fonts/Open-Sans-600/Open-Sans-600.ttf') format('truetype'),
       url('../fonts/Open-Sans-600/Open-Sans-600.svg#OpenSans') format('svg');
}

@font-face {
  font-family: 'Open Sans';
  font-weight: 700;
  font-style: normal;
  src: url('../fonts/Open-Sans-700/Open-Sans-700.eot');
  src: url('../fonts/Open-Sans-700/Open-Sans-700.eot?#iefix') format('embedded-opentype'),
       local('Open Sans Bold'),
       local('Open-Sans-700'),
       url('../fonts/Open-Sans-700/Open-Sans-700.woff2') format('woff2'),
       url('../fonts/Open-Sans-700/Open-Sans-700.woff') format('woff'),
       url('../fonts/Open-Sans-700/Open-Sans-700.ttf') format('truetype'),
       url('../fonts/Open-Sans-700/Open-Sans-700.svg#OpenSans') format('svg');
}

@font-face {
  font-family: 'Open Sans';
  font-weight: 800;
  font-style: normal;
  src: url('../fonts/Open-Sans-800/Open-Sans-800.eot');
  src: url('../fonts/Open-Sans-800/Open-Sans-800.eot?#iefix') format('embedded-opentype'),
       local('Open Sans Extrabold'),
       local('Open-Sans-800'),
       url('../fonts/Open-Sans-800/Open-Sans-800.woff2') format('woff2'),
       url('../fonts/Open-Sans-800/Open-Sans-800.woff') format('woff'),
       url('../fonts/Open-Sans-800/Open-Sans-800.ttf') format('truetype'),
       url('../fonts/Open-Sans-800/Open-Sans-800.svg#OpenSans') format('svg');
}

@font-face {
  font-family: 'Open Sans';
  font-weight: 300;
  font-style: italic;
  src: url('../fonts/Open-Sans-300italic/Open-Sans-300italic.eot');
  src: url('../fonts/Open-Sans-300italic/Open-Sans-300italic.eot?#iefix') format('embedded-opentype'),
       local('Open Sans Light Italic'),
       local('Open-Sans-300italic'),
       url('../fonts/Open-Sans-300italic/Open-Sans-300italic.woff2') format('woff2'),
       url('../fonts/Open-Sans-300italic/Open-Sans-300italic.woff') format('woff'),
       url('../fonts/Open-Sans-300italic/Open-Sans-300italic.ttf') format('truetype'),
       url('../fonts/Open-Sans-300italic/Open-Sans-300italic.svg#OpenSans') format('svg');
}

@font-face {
  font-family: 'Open Sans';
  font-weight: 400;
  font-style: italic;
  src: url('../fonts/Open-Sans-italic/Open-Sans-italic.eot');
  src: url('../fonts/Open-Sans-italic/Open-Sans-italic.eot?#iefix') format('embedded-opentype'),
       local('Open Sans Italic'),
       local('Open-Sans-italic'),
       url('../fonts/Open-Sans-italic/Open-Sans-italic.woff2') format('woff2'),
       url('../fonts/Open-Sans-italic/Open-Sans-italic.woff') format('woff'),
       url('../fonts/Open-Sans-italic/Open-Sans-italic.ttf') format('truetype'),
       url('../fonts/Open-Sans-italic/Open-Sans-italic.svg#OpenSans') format('svg');
}

@font-face {
  font-family: 'Open Sans';
  font-weight: 600;
  font-style: italic;
  src: url('../fonts/Open-Sans-600italic/Open-Sans-600italic.eot');
  src: url('../fonts/Open-Sans-600italic/Open-Sans-600italic.eot?#iefix') format('embedded-opentype'),
       local('Open Sans Semibold Italic'),
       local('Open-Sans-600italic'),
       url('../fonts/Open-Sans-600italic/Open-Sans-600italic.woff2') format('woff2'),
       url('../fonts/Open-Sans-600italic/Open-Sans-600italic.woff') format('woff'),
       url('../fonts/Open-Sans-600italic/Open-Sans-600italic.ttf') format('truetype'),
       url('../fonts/Open-Sans-600italic/Open-Sans-600italic.svg#OpenSans') format('svg');
}

@font-face {
  font-family: 'Open Sans';
  font-weight: 700;
  font-style: italic;
  src: url('../fonts/Open-Sans-700italic/Open-Sans-700italic.eot');
  src: url('../fonts/Open-Sans-700italic/Open-Sans-700italic.eot?#iefix') format('embedded-opentype'),
       local('Open Sans Bold Italic'),
       local('Open-Sans-700italic'),
       url('../fonts/Open-Sans-700italic/Open-Sans-700italic.woff2') format('woff2'),
       url('../fonts/Open-Sans-700italic/Open-Sans-700italic.woff') format('woff'),
       url('../fonts/Open-Sans-700italic/Open-Sans-700italic.ttf') format('truetype'),
       url('../fonts/Open-Sans-700italic/Open-Sans-700italic.svg#OpenSans') format('svg');
}

@font-face {
  font-family: 'Open Sans';
  font-weight: 800;
  font-style: italic;
  src: url('../fonts/Open-Sans-800italic/Open-Sans-800italic.eot');
  src: url('../fonts/Open-Sans-800italic/Open-Sans-800italic.eot?#iefix') format('embedded-opentype'),
       local('Open Sans Extrabold Italic'),
       local('Open-Sans-800italic'),
       url('../fonts/Open-Sans-800italic/Open-Sans-800italic.woff2') format('woff2'),
       url('../fonts/Open-Sans-800italic/Open-Sans-800italic.woff') format('woff'),
       url('../fonts/Open-Sans-800italic/Open-Sans-800italic.ttf') format('truetype'),
       url('../fonts/Open-Sans-800italic/Open-Sans-800italic.svg#OpenSans') format('svg');
}

//----------------------OPEN SANS CONDENSED-----------------------------

@font-face {
  font-family: 'Open Sans Condensed';
  font-weight: 300;
  font-style: normal;
  src: url('../fonts/Open-Sans-Condensed-300/Open-Sans-Condensed-300.eot');
  src: url('../fonts/Open-Sans-Condensed-300/Open-Sans-Condensed-300.eot?#iefix') format('embedded-opentype'),
       local('Open Sans Cond Light'),
       local('Open-Sans-Condensed-300'),
       url('../fonts/Open-Sans-Condensed-300/Open-Sans-Condensed-300.woff2') format('woff2'),
       url('../fonts/Open-Sans-Condensed-300/Open-Sans-Condensed-300.woff') format('woff'),
       url('../fonts/Open-Sans-Condensed-300/Open-Sans-Condensed-300.ttf') format('truetype'),
       url('../fonts/Open-Sans-Condensed-300/Open-Sans-Condensed-300.svg#OpenSansCondensed') format('svg');
}

@font-face {
  font-family: 'Open Sans Condensed';
  font-weight: 700;
  font-style: normal;
  src: url('../fonts/Open-Sans-Condensed-700/Open-Sans-Condensed-700.eot');
  src: url('../fonts/Open-Sans-Condensed-700/Open-Sans-Condensed-700.eot?#iefix') format('embedded-opentype'),
       local('Open Sans Condensed Bold'),
       local('Open-Sans-Condensed-700'),
       url('../fonts/Open-Sans-Condensed-700/Open-Sans-Condensed-700.woff2') format('woff2'),
       url('../fonts/Open-Sans-Condensed-700/Open-Sans-Condensed-700.woff') format('woff'),
       url('../fonts/Open-Sans-Condensed-700/Open-Sans-Condensed-700.ttf') format('truetype'),
       url('../fonts/Open-Sans-Condensed-700/Open-Sans-Condensed-700.svg#OpenSansCondensed') format('svg');
}

@font-face {
  font-family: 'Open Sans Condensed';
  font-weight: 300;
  font-style: italic;
  src: url('../fonts/Open-Sans-Condensed-300italic/Open-Sans-Condensed-300italic.eot');
  src: url('../fonts/Open-Sans-Condensed-300italic/Open-Sans-Condensed-300italic.eot?#iefix') format('embedded-opentype'),
       local('Open Sans Cond Light Italic'),
       local('Open-Sans-Condensed-300italic'),
       url('../fonts/Open-Sans-Condensed-300italic/Open-Sans-Condensed-300italic.woff2') format('woff2'),
       url('../fonts/Open-Sans-Condensed-300italic/Open-Sans-Condensed-300italic.woff') format('woff'),
       url('../fonts/Open-Sans-Condensed-300italic/Open-Sans-Condensed-300italic.ttf') format('truetype'),
       url('../fonts/Open-Sans-Condensed-300italic/Open-Sans-Condensed-300italic.svg#OpenSansCondensed') format('svg');
}

.bgBlackNone{background-image: url('../images/metr.png')!important}
.bgWhiteNone{background-image: url('../images/metrbl3.png')!important}
.bgNone{background: none!important}
@media screen and (min-width: 721px){
	.body_desktop{display:block}
	.body_mobile{display:none}

	html,body{
		margin: 0;
		padding: 0;
		min-width: 960px;
		min-height: 100%;
		font-family: 'Open Sans',sans-serif;
		transition: all 0.3s ease;
		.wrap{
			min-width: 960px;
			max-width: 960px;
			margin: auto;
		}
	}
	html {
		h1{
			text-transform: uppercase;
		    font-weight: 700;
		    font-size: 59px;
		    text-align: center;
		    letter-spacing: 1px;
		}
		.rel{position: relative;}
	}

	#full{position: relative;top: 0;}
	#mini{position: fixed;top: 0;}
	#fakemenu{height: 159px;}
	.menu-holder{
		height: 159px;
		width: 100%;
		background-color: white;
		z-index: 100;
		nav{
			color: black;
			position: relative;
			width: 100%;
			ul.menu{
				.underl{letter-spacing: -0.5px;}
				li:first-letter{text-transform: uppercase;}
				li{
					font-family: 'Open Sans Condensed',sans-serif;
					float: left;
					font-size: 13.6px;
					font-weight: 700;
				}
				li a{padding-bottom: 5px;}
				.item{
					position: absolute;
					top: 63px;
				}
				.it1{left: 9px;}
				.it2{left: 165px;}
				.it3{left: 310px;}
				.it4{left: 568px;}
				.it5{left: 690px;}
				
				li.logo{
					position: absolute;
					left: 431px;
					top: 28px;
				}
				
				li.tel{
					position: absolute;
					top: 49px;
					left:784px;
					font-size: 20px;
				}
				li.tel:before{
				    
				}
				.freezam{
					position: absolute;
				    top: 28px;
	    			padding-bottom: 2px;
				    left: 12px;
				    font-family: Open-Sans-Condensed-300,sans-serif;
				    font-weight: 300;
				    font-size: 15px;
				    border-bottom: 1px solid @red;
				    letter-spacing: -0.3px;
				    cursor: pointer;
				}
				
				.flag{
					margin-left: 15px;
				    margin-top: 6px;
					float:right;
					width: 8px;
					height: 42px;
					.f{
						width: 8px;
						height: 14px;
					}
					.f1{background-color: #000;}
					.f2{background-color: #ff3d3d;}
					.f3{background-color: #fec01d;}
				}
				.underl:hover{
					border-bottom: 2px solid @red;
					padding-bottom: 5px;
				}
			}
		}
		.bttn{display:none}
		.infinity-active{
			border-bottom: 2px solid @red;
		}
	}
	.menumini{
		height: 50px!important;
		width: 100%;
		background-color: white;
		z-index: 100;
		nav{
			position: relative;
			color: black;
			ul.menu{
				li{
					font-family: 'Open Sans Condensed',sans-serif;
					float: left;
					font-size: 13px;
					font-weight: 700;
					letter-spacing: -0.7px;
				}
				li:first-letter{text-transform: uppercase;}
				li a{padding-bottom: 2px;}
				.item{
					position: absolute;
					top: 15px;
				}
				.it1{left: 162px;}
				.it2{left: 294px;}
				.it3{left: 416px;}
				.it4{left: 508px;}
				.it5{left: 610px;}
				
				.logo{
					position: absolute;
					top: 0px;
					left: 42px;
					height: 82px;
					width: 82px;
					background-color: white;
					border-radius: 50%;
					margin:0;
					object{
						//margin-top: -3px;
						margin-top: 13px;
    					margin-left: 10px;
    				}
					svg{margin: 12px 0 0 11px;}
					img{
						//transform: scale(0.6);
						position: absolute;
					    top: -8px;
					    left: -9px;
					}
				}
				li.tel{
					position: absolute;
					top: 13px;
					left: 707px;
				    font-size: 18px;
				    letter-spacing: 0px;
				}
				
				.underl:hover{
					border-bottom: 2px solid @red;
				}
				.act-menu{
					border-bottom: 2px solid @red;
				}
			}
			.bttn{
				position: absolute;
			    top: 10px;
			    right: -21px;
			    height: 30px;
			    font-size: 13px;
			    font-weight: 700;
			    padding: 3px 8px 5px 8px;
			    letter-spacing: -.2px;
			    border: 2px solid #fec01d;
			    border-radius: 16px;
			    cursor: pointer;
			    transition: background 0.3s ease;
			}
			.bttn:hover{background-color:@yellow;transition: background 0.3s ease;}
		}
	}
	#bgtnx{
		height: 880px;
		background: url('../images/bgtnx.jpg') repeat-x center 0px;
		h1{
			margin:0;
			padding-top: 248px;
		    padding-left: 20px;
		    font-size: 80px;
		}
		.tnx{
			padding: 70px 0 0 9px;
		    text-align: center;
		    font-size: 47px;
		    letter-spacing: 1.6px;
		    line-height: 55px;
		}
	}
	.bl1{
		width: 100%;
		height: 920px;
		background: url('../images/bg1.jpg') repeat-x center center;
		color: white;
		header{
			height: 438px;
			h1:first-of-type{margin-top: 34px;}
			.flat{
				margin: 42px 215px 0;
			    padding: 14px 0 15px;
				text-transform: uppercase;
			    font-weight: 700;
			    font-size: 40px;
			    text-align: center;
			    letter-spacing: 1px;
				border: 3px solid @yellow;
				border-radius: 24px;
				font-style:normal;
			}
			ul{
				margin: 48px 0 0 281px;
			    letter-spacing: -1px;
			    display: block;
			    height: 35px;
				li{
					padding-right: 17px;
					float: left;
					text-transform: uppercase;
					font-weight: bold;
					font-size: 24px;
				}
				li:nth-child(even){color: @yellow;margin-top: -1px;}
				li:first-of-type{padding-right: 17px;}
				li:nth-of-type(3){padding-right: 28px;}
			}

		}
		
		
		button{
			margin: 58px 0 0 349px;
			text-transform: uppercase;
			color: black;
			background: @yellow;
			font-weight: bold;
			font-size: 16px;
			padding: 15px 23px 13px 23px;
		    border-radius: 15px;
		    transition: background 0.3s ease;
		}

		button:hover{background:white;transition: background 0.3s ease;}

		h2, h3{text-align: center;color:black}
		h2{
			text-transform: uppercase;
			font-size: 28px;
		    font-weight: 700;
		    margin-top: 34px;
		}
		h3{
			font-size: 16px;
			margin-top: 7px;
	    	font-size: 17px;
			letter-spacing: -0.6px;
		}
		form{
			margin-top: 22px;
		    margin-left: 84px;
		    height: 50px;
			.eq-inp{
				float: left;
				margin-right: 20px;
				background: #fff;
				height: 41px;
				width: 220px;
				border: 2px solid white;
				border-radius: 9px;
				font-style:italic;
				padding-left: 43px;
			}

			.eq-inp::-webkit-input-placeholder {color:#6f6f6f;}
			.eq-inp::-moz-placeholder          {color:#6f6f6f;}
			.eq-inp:-moz-placeholder           {color:#6f6f6f;}
			.eq-inp:-ms-input-placeholder      {color:#6f6f6f;}
			
			.name-1bl{background:#fff url("data:img/png;base64,iVBORw0KGgoAAAANSUhEUgAAABEAAAASCAMAAACKJ8VmAAAABGdBTUEAALGPC/xhBQAAACBjSFJNAAB6JgAAgIQAAPoAAACA6AAAdTAAAOpgAAA6mAAAF3CculE8AAAAwFBMVEUAAAAjHyAjHyAjHyAjHyAjHyAjHyAjHyAjHyAjHyAjHyAjHyAjHyAjHyAjHyAjHyAjHyAjHyAjHyAjHyAjHyAjHyAjHyAjHyAjHyAjHyAjHyAjHyAjHyAjHyAjHyAjHyAjHyAjHyAjHyAjHyAjHyAjHyAjHyAjHyAjHyAjHyAjHyAjHyAjHyAjHyAjHyAjHyAjHyAjHyAjHyAjHyAjHyAjHyAjHyAjHyAjHyAjHyAjHyAjHyAjHyAjHyAjHyAAAAALMCp0AAAAPnRSTlMADo3n5YwP0/HMEJelDKeUBPLmBxurrBr+xsjES0zDPPv6Y5Y7T+vqTgtQbD6fwMfQnTqEglMJTTEzz/zg31VNLOMAAAABYktHRACIBR1IAAAACXBIWXMAAAsSAAALEgHS3X78AAAAoklEQVQY022OaROBUBSGT4oil8hSiJKtkH3n/f8/SzV13WY8H84755k5C1GCVJKhlCXiVFRoVQ1qjRsddUasgWYujFbbjMOUNSMzHXTT7KGfGWbZaQ4slo8NMYqrgzHfPHE9fep77ux3fr4AsFyRQBCuw4B3m22026fYh2MiThA5E12gOFc+q+BGtnUX9j3iD7yneIHUFyEqmAh/zNsvGP/zBdVTEzbEuGgdAAAAAElFTkSuQmCC") no-repeat 11px 9px}
			.tel-1bl{background:#fff url("data:img/png;base64,iVBORw0KGgoAAAANSUhEUgAAABIAAAARCAMAAADnhAzLAAAABGdBTUEAALGPC/xhBQAAACBjSFJNAAB6JgAAgIQAAPoAAACA6AAAdTAAAOpgAAA6mAAAF3CculE8AAABGlBMVEUAAAAjHyAjHyAjHyAjHyAjHyAjHyAjHyAjHyAjHyAjHyAjHyAjHyAjHyAjHyAjHyAjHyAjHyAjHyAjHyAjHyAjHyAjHyAjHyAjHyAjHyAjHyAjHyAjHyAjHyAjHyAjHyAjHyAjHyAjHyAjHyAjHyAjHyAjHyAjHyAjHyAjHyAjHyAjHyAjHyAjHyAjHyAjHyAjHyAjHyAjHyAjHyAjHyAjHyAjHyAjHyAjHyAjHyAjHyAjHyAjHyAjHyAjHyAjHyAjHyAjHyAjHyAjHyAjHyAjHyAjHyAjHyAjHyAjHyAjHyAjHyAjHyAjHyAjHyAjHyAjHyAjHyAjHyAjHyAjHyAjHyAjHyAjHyAjHyAjHyAjHyAjHyAjHyAAAADH6WXQAAAAXHRSTlMAEpXg3Iaz+qufd3r9rMHDIB7zv2wu/thQ6dMC0OgDFPAtcgbS710M/BfAZnSTFbb3bxmmtUeZaxOl3edPEa5pqD/R7FOxh38418uwGK+A8uF9AaG846qPl6PicL/JvJQAAAABYktHRACIBR1IAAAACXBIWXMAAAsSAAALEgHS3X78AAAAx0lEQVQY01XO1RLCMBAF0EWLOwUKhQLFrXhxd3fL/38HKQPTkIebnTOTuwFQKFVqDfwdLaXTIj0pBmQE0JjMBFmsNpxKZJfJ4ZTShdwy0R4cXh/jlynABiHEMWGiK4Lro3wsTlAiyQKk0o4MYVlTDiBfEIpQKvOV6sdqyTqAqKP0PNdottoSdbpCD2cftQYQHo7GkokTaoqv2RxHabH8PO006NX6W71RfYftbn84SoNROP32Vs/05Xq7B9BKlD/TfjDs8/wCeANOKhepdIQ2gwAAAABJRU5ErkJggg==") no-repeat 11px 11px;}
			.metr{width: 140px;background:#fff url("data:img/png;base64,iVBORw0KGgoAAAANSUhEUgAAAGkAAAASCAMAAABvuQAKAAAABGdBTUEAALGPC/xhBQAAACBjSFJNAAB6JgAAgIQAAPoAAACA6AAAdTAAAOpgAAA6mAAAF3CculE8AAABWVBMVEUAAAAjHyAjHyAjHyAjHyAjHyAjHyBvb28jHyAjHyBvb29vb29vb29vb28jHyBvb29vb29vb28jHyAjHyAjHyAjHyAjHyAjHyAjHyAjHyBvb28jHyAjHyAjHyAjHyAjHyAjHyAjHyAjHyBvb29vb29vb28jHyAjHyAjHyAjHyAjHyAjHyAjHyAjHyAjHyAjHyAjHyAjHyAjHyBvb29vb28jHyAjHyAjHyAjHyAjHyAjHyAjHyAjHyAjHyAjHyAjHyAjHyAjHyAjHyAjHyAjHyAjHyAjHyAjHyAjHyAjHyAjHyAjHyAjHyAjHyAjHyAjHyAjHyAjHyAjHyAjHyAjHyAjHyAjHyAjHyAjHyAjHyAjHyAjHyAjHyAjHyAjHyAjHyAjHyAjHyAjHyAjHyAjHyAjHyAjHyAjHyAjHyAjHyAjHyAjHyAjHyAjHyAjHyAjHyAjHyBvb28AAABtGnhfAAAAcHRSTlMAxsuRllBUIuHlEXfM3SeIqlUjGRZ+/nwVJLv2zTOh+fifMTOZZmhSBFbD7oofIIzvwlPuRMCZE3nk1GYKC9bidxKcLp33sUMBRrOaLa3e8Y8i8t2qqBdNq43X8CHj5okvQjQ2RHrT29V4iIRV+n99BhWJUQAAAAFiS0dEAIgFHUgAAAAJcEhZcwAACxIAAAsSAdLdfvwAAAJkSURBVDjLvVT5VxMxEB5EjCYbNgu44haoukkph0fNAp6gKIqCghcK3rfilf3/f3GSbCvt+l6fvlfmNd1kJjPfTOZLAPZK3wHYJ+k/uF9IA4d6FJiQDsXhI91cqB2k265OYQFn7ZrBwS4uIcf8uPhXJAqRgKHh1nrkaBwfG20th4+XXRLxP0AogkFlrJiPT1QNyomTpwrFWKXswJkHSqVUToGT5rkoPyWhZLTQOBPDfxkC1Cbtqj41bczM7OkzZ88Z0zhft7rJWgmI5MoBMS2kRmfItAySImsdMtSlOpG8UHGNkCqXkNi9c/MACxcuGnPp8hVrvVpbNGbp2nWA+bkSksodENXMomGYIAQeepNGnkgJAW5I88x1R/MUt3AmuBAUGss3bq6Y+Nbt1Wa41Tt3Y1NZW19ulJBkYDE8iMoJlkSojohSCpJAKaYzB4Klgys4UcCETkmaIuS9+8ZsbD5ojzjU/9CYR49LSIH0qQcWDmkYJhDlJOKcUxwc01A2iSxPXYMCGRLtNFaMqT7ZakZ6OtAi3jPkRycQ1Zg79kAKT0O7toCeK/bUiOJFErZzoSUJCwrv7R1jnm+/8IuXr4qaNjeMWXzdiZRirgQTZ1gYDhJiukJ4W5LgpdE0yyP8eF5yFWlORdjyf+P69NZO3723ffqAfVpZWy9z3PXHwgjNdUZynflaHC0DzT1R3McRldiGcbUnwuhH5N4n5N7OZ+TeF+Te1wX4i1B34vYtIgSndsCflynNnJkS2rwT7kfbY9Sn+vA+7X7bnZ0xZvp7HXop4xM/7BtRbb0RPZStn3H8a6RX0X8DyfRXtIYDcTIAAAAASUVORK5CYII=") no-repeat 6px 9px;}
			.metr:focus{background:#fff url("data:img/png;base64,iVBORw0KGgoAAAANSUhEUgAAABwAAAASCAMAAAB/2U7WAAAABGdBTUEAALGPC/xhBQAAACBjSFJNAAB6JgAAgIQAAPoAAACA6AAAdTAAAOpgAAA6mAAAF3CculE8AAABLFBMVEUAAAAjHyAjHyAjHyAjHyAjHyAjHyAjHyAjHyAjHyAjHyAjHyAjHyAjHyAjHyAjHyAjHyAjHyAjHyAjHyAjHyAjHyAjHyAjHyAjHyAjHyAjHyAjHyAjHyAjHyAjHyAjHyAjHyAjHyAjHyAjHyAjHyAjHyAjHyAjHyAjHyAjHyAjHyAjHyAjHyAjHyAjHyAjHyAjHyAjHyAjHyAjHyAjHyAjHyAjHyAjHyAjHyAjHyAjHyAjHyAjHyAjHyAjHyAjHyAjHyAjHyAjHyAjHyAjHyAjHyAjHyAjHyAjHyAjHyAjHyAjHyAjHyAjHyAjHyAjHyAjHyAjHyAjHyAjHyAjHyAjHyAjHyAjHyAjHyAjHyAjHyAjHyAjHyAjHyAjHyAjHyAjHyAjHyAjHyAAAABPdDs+AAAAYnRSTlMAxsuRllBU4eUnIxkWfv58FST2zTOh+fifMWhSBFbD7oofIIzvwlPAmRN55NRmCgvW4ncSnC6d97FDAUazmi2t3vGPIvLdqqgXTauN1/Ah4+aJL0I0NkR609vVeIiEVfp/fVb0MNIAAAABYktHRACIBR1IAAAACXBIWXMAAAsSAAALEgHS3X78AAABBklEQVQoz32QezcCURTFTxJSbplqME0PMSpCiIieSJT3oySv/f0/hHOZZk2tZfZf++6z7t2/e4jsck3Q/3JPOgw9Uw7D6RmHodc7ep71WdY/J0QgaB1986SETB+OqGAtLC6ZQUghLSqNHosDieRyamUVMNZ0mUU1SmeIsusbQG5zS0bbWh7Y2d0jyqTJKOwfFCEOj0rDrtLxiYBSrhQMqtaAeuN0jNJ9Bpw3CVAvWsPw0mOhXjEetTvAdfvmL7q9M2826kD+nt3Db+ejDJ+eZecLdxbLFfONYJdpe0zbeWXaPtO+ZW0EeszF/xy8D5IJIP6hj+81HPmUG1KtDY2q9SXEt98W/ACiSyqW5O5vBwAAAABJRU5ErkJggg==") no-repeat 6px 9px;}
				
			input[type="submit"]{
				background: #fff;
				border-radius: 9px;
				height: 41px;
				width: 150px;
				float: left;
				border:0;
				cursor: pointer;
				font-weight: bold;
				font-size:18px;
				padding-left: 0;
				transition: all 0.3s ease;
				font-style:normal;
			}
			input[type="submit"]:hover{
				background: black;
				color: white;
			}
		}
		p{
			color:black;
			text-align: center;
		}
		p:first-of-type{
			font-style: italic;
			font-size:14px;
			text-decoration: underline;
			margin-top: 22px;
		}
		p:last-of-type{
			font-size: 12px;
		    margin-top: 31px;
		    letter-spacing: 0.25px;
	    	padding-left: 44px;
		}
		p:last-of-type:before{
			content: '';
			background: url("data:img/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAdCAMAAACHZFx5AAAABGdBTUEAALGPC/xhBQAAACBjSFJNAAB6JgAAgIQAAPoAAACA6AAAdTAAAOpgAAA6mAAAF3CculE8AAABklBMVEUAAAD///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////8jHyAjHyAjHyAjHyAjHyAjHyAjHyAjHyAjHyAjHyAjHyAjHyAjHyAjHyAjHyAjHyD///8jHyAjHyAjHyD///////8jHyAjHyAjHyD///////////8jHyAjHyAjHyD///////////////8jHyAjHyD///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////8jHyAAAABcJlzzAAAAg3RSTlMADqLIKFLj+HgEOcD+mXf10kQGVcPGOCG1UAU/RW2n7wFC7qhOQOyWM5jqcDACNm7r0Alb+XBf/fpCNooPafZOhcnTCr5u1O3d8UHpySUGsjcjx32Cci52gBz38BUJ5fwkh7CMpIVUhvpBLawMetXaIrzxRqWSD3X2y19JEZX9HzHWQ9TbknIAAAABYktHRACIBR1IAAAACXBIWXMAAAsSAAALEgHS3X78AAABPklEQVQoz2NggANGJmYWBiyAla25mZ2DE12Yi7uZh5ePv1lAEFlUSFikuVlUjIFBXKK5WURSCiwoLSMrJ9/czCypAOYqApUoKcuqqDI0A4GauhhCu4amFkiMoVlNW0cX1T5dPX0DoIQhNicaUkPCCIeEsYkpVgkzcwtLFAkraxsQZdtiZ49qlEOLow1Y3BJhuROI4ewClHGFizO4NTPwuzNAZTxaPL1gdil5M/g0+4KZfv4tngEw8cDmIIbg5hAIxy80DO648OYIhsio6Bh0f8fGxScwMCQ2JymgSSQ3p4CknZpTUcXTmr3BhgRqNacj60nLyMiEsLK8m7Nz4LGU25whDHddXnM+NHILCpuLihG6c0qa40vLGBg4yyuaKwNRLBTWavauqnZrjspFT3I1tXXAtJFdjyU6G4IamxA8AHAlUp1+ei95AAAAAElFTkSuQmCC") no-repeat;
			height: 30px;
		    width: 25px;
		    position: absolute;
			margin-left: -46px;
		    margin-top: -10px;
		}
	}
	.bl2{
		height: 826px;
		background: white;
		
		h1:first-of-type{
			margin-top:123px;
			font-size:47px;
			overflow: hidden;
		}
		h1:first-of-type:before,h1:first-of-type:after{
			content: '';
			display: inline-block;
			vertical-align: middle;
			position: absolute;
			width: 100%;
			height: 4px;
			position: relative;
			top: -5px;
		}
		h1:first-of-type:before{
			margin-left: -100%;
			left: -53px;
			background-color: @red;
		}
		h1:first-of-type:after{
			background-color: @yellow;
			margin-right: -100%;
		    right: -57px;
		}

		.preim{
			float: left;
			margin-top: 68px;
			font-weight: 700;
			font-size: 16px;
			border: 2px solid @yellow;
			border-radius: 9px;
			text-transform: uppercase;
			margin-left: 22px;
			letter-spacing: 0.5px;
			padding: 7px 12px 7px 12px;
		}
		ul{
			li{
				float: left;
				cursor: pointer;
				text-transform: uppercase;
				margin:69px 7px 0 10px;
		    	padding: 9px 15px 10px 15px;
		    	div{
		    		padding: 0 0 8px 0;
		    		border-bottom: 2px solid white;
		    	}
			}
			li:hover div{
				border-bottom: 2px solid @red;
			}
			.active{
				padding: 9px 15px 0px 15px;
				background-color: @yellow;
				border-radius: 9px;
				div{border-bottom: 2px solid @yellow;}
			}
			.active:hover div{border-bottom: 2px solid @yellow;}
			li[link="1"]{margin-left: 25px;}
			li[link="3"]{margin-right: 0;}
		}

		.tablink{
			float: left;
			margin-top: 37px;
			img{
				float: left;
				width: 460px;
				height: 340px;
				margin: 83px 0 0 9px;
			}

			
			article{
				h2{
					text-transform: uppercase;
					margin: 0 0 0 79px;
					font-weight: 700;
					font-size: 24px;
					//letter-spacing: -2.2px;
				}
				p{
				    margin-top: 33px;
				    margin-left: 80px;
				    line-height: 26px;
				    letter-spacing: 0.4px;
				}
				height: 360px;
				width: 469px;
				float: right;
				margin-top: 90px;
			}
		}
		
		.tablink[link="1"]{
			
		}
		.tablink[link="2"]{
			
		}
		.tablink[link="3"]{
			
		}
	}
	.bl3{
		height: 580px;
		background-color: #323232;
		color: white;
		overflow: hidden;
		h1{
			font-weight: 700;
		    font-size: 39px; 
		    //letter-spacing: 1.9px;
		    padding-top: 65px;
		    color: #fff;
		}
		h2{
			text-align: center;
			margin-top: 66px;
		    font-size: 28px;
		    text-transform: uppercase;
		}
		h3{
			font-weight: 300;
			text-align: center;
			margin-top: 17px;
		    letter-spacing: 0.5px;
		}
		form{
			margin-top: 63px;
		    margin-left: 68px;
		    height: 50px;
			.eq-3bl{
				float: left;
				margin-right: 30px;
				height: 41px;
				width: 220px;
				border: 2px solid @yellow;
				border-radius: 9px;
				font-style:italic;
				padding-left: 43px;
			}

			.eq-3bl::-webkit-input-placeholder {color:#fff;}
			.eq-3bl::-moz-placeholder          {color:#fff;}
			.eq-3bl:-moz-placeholder           {color:#fff;}
			.eq-3bl:-ms-input-placeholder      {color:#fff;}
			
				
			.name-3bl{background: url("data:img/png;base64,iVBORw0KGgoAAAANSUhEUgAAABEAAAASCAMAAACKJ8VmAAAABGdBTUEAALGPC/xhBQAAACBjSFJNAAB6JgAAgIQAAPoAAACA6AAAdTAAAOpgAAA6mAAAF3CculE8AAAAwFBMVEUAAAD///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////8AAAADHcBeAAAAPnRSTlMADo3n5YwP0/HMEJelDKeUBPLmBxurrBr+xsjES0zDPPv6Y5Y7T+vqTgtQbD6fwMfQnTqEglMJTTEzz/zg31VNLOMAAAABYktHRACIBR1IAAAACXBIWXMAAAsSAAALEgHS3X78AAAAoklEQVQY022OaROBUBSGT4oil8hSiJKtkH3n/f8/SzV13WY8H84755k5C1GCVJKhlCXiVFRoVQ1qjRsddUasgWYujFbbjMOUNSMzHXTT7KGfGWbZaQ4slo8NMYqrgzHfPHE9fep77ux3fr4AsFyRQBCuw4B3m22026fYh2MiThA5E12gOFc+q+BGtnUX9j3iD7yneIHUFyEqmAh/zNsvGP/zBdVTEzbEuGgdAAAAAElFTkSuQmCC") no-repeat 11px 9px; color:white;}
			.tel-3bl{background: url("data:img/png;base64,iVBORw0KGgoAAAANSUhEUgAAABIAAAASCAMAAABhEH5lAAAABGdBTUEAALGPC/xhBQAAACBjSFJNAAB6JgAAgIQAAPoAAACA6AAAdTAAAOpgAAA6mAAAF3CculE8AAABDlBMVEUAAAD///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////8AAAAvYQfQAAAAWHRSTlMAEI/dg6SzvGn+mpa32qz7FOi4XzXVFsjyC+3XA8r3cZgkF/nNzGbA9TgrbkMs4cvw+pda+K8R0cdcsX/9AcWmhLYVxNm62+TzJ5uRru+7nt+fR5zQ5+BoMSKXiQAAAAFiS0dEAIgFHUgAAAAJcEhZcwAACxIAAAsSAdLdfvwAAAC/SURBVBjTVc9lE8IwDAbg4O5uw2XAcHd31/z/X0LLxq30w3vpc9cmAVAoVSo1/B0NanWoZEVvIFcjmhgyW2habXaZHE6aLnTLhB4SXp8/IFMwBBCOcFHmrxjGIZFMeRlyYxogw2VZ8+T4n+ULxS8JpbJdtGgFq7Wv1RvNFrU21+n2pD36A+uQWGNE3o5R3HcymM7IcGFazxdiiz7X5qVuy5VUdNeb2JYWO9z/RhEO1ePp3LzglZnvdn88X+Y3wAcuSBfFvpvMuQAAAABJRU5ErkJggg==") no-repeat 11px 11px; color:white;}
			.metr{
				background: url("data:img/png;base64,iVBORw0KGgoAAAANSUhEUgAAAGcAAAATCAMAAAC6LOMcAAAABGdBTUEAALGPC/xhBQAAACBjSFJNAAB6JgAAgIQAAPoAAACA6AAAdTAAAOpgAAA6mAAAF3CculE8AAABg1BMVEUAAAD///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////8AAAAa8ZiOAAAAf3RSTlMAw8ilqDQ1IuTnEXfM3VVYiKovLbvFnka4RDOZZtMFXNHskZbPWwTuTDkOdOPbagkKa+JzDfoci/HGUwJU8BpQX6P+rzs9s59e+feYJiib+MpRncsYedpoyc14FwZd0up9FDY3FYEBSLz2lSO6RTH8rHqtLh6N8/KMHRB2/XUPKaxPBwAAAAFiS0dEAIgFHUgAAAAJcEhZcwAACxIAAAsSAdLdfvwAAAJoSURBVDjLvVT3VxQxEI6gBpLNmuiC7iZHsQUUONtZOBVv7YBi7wV7773Ov+4k2dO7/eHew/eOeZs2yeT7Mvk2hLTaih6yLNa7cnlwVq3uyraUlhx9/Z0DmCuULNF4JHi7J17TMUAK5CbUUnEYidtj1vb3r+sUkKj/gUFTnAwMNgfrE0DbkDbHgwPl5YIHmExr4x3YaWbEhC6VmrPC46c41loSUgn8hoZHAEY3bhoF2LxlayBRKcFQMB6GW6UthpLU6igpGFvJ0ZfZRIvCJSwCGtAkcWvHxrHatn0CYHKqSsiOnQJg1+496BwfK+EY8DDMcoeFm0SSCBmmLKpDaxLhggx8SpgVGS4RXAmlGKlVyN59+wEOTNfDbvWDPQCH5GFSqZVwdOQQAoQBisehzMbUGEOSyBhuUw+Bxyb+sIkhXNmMZhkCzhxp1CA/eqx1w+MncqidPDVTwol0oB05MJSeTEgMNBZCMCwCSRhHIYXMX0ykJbXe4+w0wOzcfPnGz5xdAFho9zGLvDH3WgXpubGDCwpx+aJGFBTcjUknDR4V0TnAufNFysiFRtGpT18EyNtxMuRJkTTHQ2GhEqmqQuVJgj+JZSnE2AQtChNbwZQsoi9d7gO4crXqB9eu+6Y6NQkwceNmO46/FweirLApBZuGc3gpRlYEefjGi5O6ixLmb/yt23cA7i7ew+79B1g9fITiHhkeKueS+Uy7V4dS7LpC/r1BWeqnGWXNv8B/rHWHx0+eAjx7/sK9b/NzswAvX5Eu2eveN5C/fbf4HpXW+NAtFGcfP312786Xr9+6ieLs+4/856/f3dn7DxkLW7ND5NCpAAAAAElFTkSuQmCC") no-repeat 9px 9px;
				width: 140px;
				color: white;
			}
			.metr:focus{background: url("data:img/png;base64,iVBORw0KGgoAAAANSUhEUgAAABwAAAATCAMAAAC0hZ1zAAAABGdBTUEAALGPC/xhBQAAACBjSFJNAAB6JgAAgIQAAPoAAACA6AAAdTAAAOpgAAA6mAAAF3CculE8AAABa1BMVEUAAAD///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////8AAACJyLOpAAAAd3RSTlMAw8ilqDQ15OdVWC8txZ5Gu7hE06oFXNHskZbPWwRMOQ5049tqCQpr3eJzDfoci/HGUwJU8IgaUF+j/q87PbOfXvn3mCYom/jKUZ3LGHnaaMnNeBcGXdLqfRQ2NxWBAUi89pUjukUx/Kx6rS4ejfPyjB0Qdv11Dwf2DXIAAAABYktHRACIBR1IAAAACXBIWXMAAAsSAAALEgHS3X78AAABFUlEQVQoz31QZ1PCQBR8gkosYAlNUVAg1liIBXN2QLH3ggXsBeyi7t/3iGEmqOR9uLe3O7Ozb4mMU2Gh8mOtNBGrqk1Em2Ai1tSW1+oEob6EsDuKqKERfJqai3+HnUSnhlxuD+BtafUCvrZ2jXKK5A/w3dEZBEJhiairuwfo7evnZMBPskgDg0PAcET5cVNGLMDo2DiJMkUnVBlscsqYY3qGQZ6di9I8EIsnfudeWEwCSWLA0rLuSCuqDpTIKsBobd0GbGxKGre1rS0pHAKCO7sc7u0fAIepIw6PT/hzmub3eNwu3SRzdg5cXF4Vuk3EY8D1TUmCW+sd2H02leM51Yc/xT4+PRfqe3l9+7f39zz7+PwyEN+2PDa78eKDvAAAAABJRU5ErkJggg==") no-repeat 9px 9px;}
			input[type="submit"]{
				background: @yellow;
				border-radius: 9px;
				height: 41px;
				width: 150px;
				float: left;
				border:0;
				cursor: pointer;
				font-weight: bold;
				font-size:18px;
				padding-left: 0;
				font-style: normal;
				font-family: 'Open Sans',sans-serif;
				font-weight: bold;
				transition: all 0.3s ease;
			}
			input[type="submit"]:hover{
				background: black;
				color: white;
			}
			

		}
		p{
			color:black;
			text-align: center;
		}
		p:first-of-type{
			font-style: italic;
		    font-size: 16px;
		    color: white;
		    letter-spacing: -0.1px;
		    text-decoration: underline;
		    margin-top: 36px;
		}
		p:last-of-type{
			font-size: 12px;
		    margin-top: 49px;
		    letter-spacing: 0.25px;
	    	padding-left: 44px;
	    	color: white;
		}
		p:last-of-type:before{
			content: '';
			background: url("data:img/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAeCAMAAAAB8C7XAAAABGdBTUEAALGPC/xhBQAAACBjSFJNAAB6JgAAgIQAAPoAAACA6AAAdTAAAOpgAAA6mAAAF3CculE8AAABklBMVEUAAAD9vx39vx39vx39vx39vx39vx39vx39vx39vx39vx39vx39vx39vx39vx39vx39vx39vx39vx39vx39vx39vx39vx39vx39vx39vx39vx39vx39vx39vx39vx39vx39vx39vx39vx39vx39vx39vx39vx39vx39vx39vx39vx39vx39vx39vx39vx39vx39vx39vx39vx39vx3////////////////////////////////////////////////////////////////////9vx3////////9vx39vx39vx39vx3////////9vx39vx39vx39vx39vx3////////9vx39vx39vx39vx39vx39vx39vx39vx39vx39vx39vx39vx39vx39vx39vx39vx39vx39vx39vx39vx39vx39vx39vx39vx39vx39vx39vx39vx39vx39vx39vx39vx39vx39vx39vx39vx39vx39vx39vx39vx39vx39vx39vx39vx39vx39vx39vx3///8AAAB+575XAAAAg3RSTlMAC53EIkPc9GsBJayujPzBLjSn4FLS/aUQIUR/0el1CANm586ARRLIZQkGYMr3qB5upvXQG6E53HkCFxXTsgaC/FtG+/3bZLz45PkFvgr7sD9drxkEB+L+OPruDFvZD7x6q7mNzAQNzboVqusz8Fg93iqHspSz33Nc5e9iComgFivD1F68sOAAAAABYktHRACIBR1IAAAACXBIWXMAAAsSAAALEgHS3X78AAABQUlEQVQoz2NggANGJmYWBiyAla25mZ2DE12Yi7u5mYeXr5lfAEVYUIivWVgESIs2i4lDpSQkpaRlmptl5eRBPAVFpeZmZRVVLjWGZhBQ19CEadbS1gELMTTr6mlIotqnb2BoBJQwxuZEY1pKmJiaYZUwMW+xQJGwtAKLW9u02NqhSNg7OCKLIyScnF0ckcTBEq5glqOLi5tNiztUnIHNg8HTyxsq09Li4wsLMD9hBv9mKQjH0cEdJs4Q0MzDENgcBOUFh8BdzdSswRAaFh6B7m+WyKhoBoaY5tg4VHGF+OYEEJ3YnKSFIpHcnJIKotPSmzOiEcKcvM2y0GSUadwsCo/EtKzm7BwYJy6jOTcPwsx3bS4oRLKuKLy5WB+ovCS8uVQNxcKAsmb28orK5qpqdLfX1PoBE01dPZbobGhsykfwAEfAVBL3OURQAAAAAElFTkSuQmCC") no-repeat;
			height: 30px;
		    width: 25px;
		    position: absolute;
			margin-left: -45px;
	    	margin-top: -8px;
		}
		.wrap{
			position: relative;
			.mach{
				position: absolute;
				top: 538px;
			    left: -67px;
				background: url('../images/machine.png') no-repeat 0 0;
				height: 600px;
				width: 1150px;
			}
		}
	}
	.bl4{
		height: 670px;
		background-color: white;
		.wrap{
			position: relative;
			.mach{
				position: absolute;
				top: -42px;
			    left: -67px;
				background: url('../images/machine.png') no-repeat 0 0;
				height: 600px;
				width: 1150px;
			}
		}
	}
	.bl5{
		height: 850px;
		background: @yellow url('../images/bg4.jpg') repeat-x center;
		.wrap{
			.slider-box{
				width: 960px;
				height: 690px;
				position: relative;
				overflow: hidden;
				.arro{
					position: absolute;
					top: 93px;
					width: 20px;
				    height: 25px;
				    padding: 25px;
				    z-index: 20;
				    cursor: pointer;
				}
				.lf{
				    left: 307px;
				    background: url(../images/arrows.png) no-repeat 14px;
				}
				.rgh{
				    left: 598px;
				    background: url(../images/arrows.png) no-repeat -29px;
				}
				.circle{
					position: absolute;
					width: 44px;
					height: 44px;
					border: 3px solid @yellow;
					border-radius: 50%;
					background:white;
					cursor: pointer;
					z-index: 10;
					font-size: 36px;
				    font-weight: 700;
				    color: @yellow;
				    padding: 0px 0px 0px 9px;
	    			line-height: 38px;
				}
				.circle:hover:not(.activ){
					color: white;
					background:@yellow;
					transition: all 0.3s ease;
				}
				
				.activ{
					background: @yellow url('../images/check.png') no-repeat center;
					z-index: 20;
					font-size: 0;
				}
				.crcl1{
					left: 478px;
				    top: 434px;
				}
				.crcl2{
					left: 260px;
					top: 422px;
				}
				.crcl3{
				    left: 410px;
	    			top: 628px;
				}
				.crcl4{
					left: 780px;
				    top: 616px;
				}
				.slider{
					width: 960px*4;
					height: 690px;
					position:relative;
					.slider-bl{
						float: left;
						width: 960px;
						height: 690px;
						top: 0;
						h1{
							margin-top: 85px;
						    font-size: 46px;
						    font-weight: 700;
						    letter-spacing: 2px;
						    color: white;
						}
						p{
							margin-top: 29px;
						    text-align: center;
						    font-family: Open Sans semibold,sans-serif;
						    font-size: 18px;
						    letter-spacing: 0.3px;
						    font-weight: 700;
						    color: white;
						}
					}
				}
				.slbl1{left: 0;}
				.slbl2{left: 960px;}
				.slbl3{left: 1920px;}
				.slbl4{left: 2880px;}
			}
		}
	}
	.bl6{
		height: 1073px;
		background-color: white;
		z-index:-20;
		h1:first-of-type{
			margin-top:128px;
			font-size:47px;
			overflow: hidden;
		}
		h1:first-of-type:before,h1:first-of-type:after{
			content: '';
			display: inline-block;
			vertical-align: middle;
			position: absolute;
			width: 100%;
			height: 4px;
			position: relative;
			top: -5px;
		}
		h1:first-of-type:before{
			margin-left: -100%;
			left: -53px;
			background-color: @red;
			top: 30px;
		}
		h1:first-of-type:after{
			background-color: @yellow;
			margin-right: -100%;
			right: -193px;
		    top: -34px;
		}

		.tr1{float:left; z-index:10;position: relative;}
		.mmax{
			font-family: 'Open Sans Extrabold', sans-serif;
		    font-weight: 700;
		    margin-left: 67px;
		    font-size: 17px;
		    letter-spacing: 1px;
			margin-bottom: 46px;
		}
		.mmax1{margin-top: 120px;}
		.mmax2{margin-top: 131px;}
		.choose{
			background: #fec01d;
		    border-radius: 10px;
		    width: 142px;
		    height: 40px;
		    padding: 7px 0 0 23px;
		    margin-left: 106px;
		    font-weight: 600;
		    font-size: 18px;
		}
		.choose1{margin-top: 110px;}
		.choose2{margin-top: 125px; margin-left: 215px;}
		.dontchoose{
		    margin-left: 140px;
			font-weight: 600;
		    font-size: 18px;
		    letter-spacing: 1.3px;
		}
		.dontchoose1{margin-top: 118px;}
		.dontchoose2{margin-top: 132px;}
		.row{
			position: relative;
			z-index:10;
			width: 900px;
			border-bottom: 1px solid @yellow;
			float: left;
			margin-left: 28px;
			padding: 6px 0 10px 0;
			.td{float: left;}
			.td1{
				padding-left: 40px;
			    width: 379px;
			    font-weight: 600;
			    font-size: 17px;
			    letter-spacing: -0.2px;
			}
			.td2{
				width: 260px;
			}
		}
		.bigrow{
			.td{
				padding-top: 15px;
				padding-bottom: 4px;
			}
			.td:last-of-type{padding-top:4px;}
		}
		.nonbrdr{border:0;}
		.backgrey{
			position: absolute;
		    width: 240px;
		    height: 800px;
		    background: #f7f7f7;
		    top: 67px;
		    left: 368px;
		    z-index: 0;
		}
		.profi{
			position: absolute;
		    height: 60px;
		    width: 126px;
		    text-align: right;
		    font-size: 14px;
		    line-height: 15px;
		    font-weight: 700;
		    top: 807px;
		    left: 407px;
		    z-index: 11;
		}
		.profi:after{
			content:'';
			position: absolute;
		    background: url("data:img/png;base64,iVBORw0KGgoAAAANSUhEUgAAABsAAAAlCAMAAACEemK6AAAABGdBTUEAALGPC/xhBQAAACBjSFJNAAB6JgAAgIQAAPoAAACA6AAAdTAAAOpgAAA6mAAAF3CculE8AAABYlBMVEUAAAD9wB39wB39wB39wB39wB39wB39wB39wB39wB39wB39wB39wB39wB39wB39wB39wB39wB39wB39wB39wB39wB39wB39wB39wB39wB39wB39wB39wB39wB39wB39wB39wB39wB39wB39wB39wB39wB39wB39wB39wB39wB39wB39wB39wB39wB39wB39wB39wB39wB39wB39wB39wB39wB39wB39wB39wB39wB39wB39wB39wB39wB39wB39wB39wB39wB39wB39wB39wB39wB39wB39wB39wB39wB39wB39wB39wB39wB39wB39wB39wB39wB39wB39wB39wB39wB39wB39wB39wB39wB39wB39wB39wB39wB39wB39wB39wB39wB39wB39wB39wB39wB39wB39wB39wB39wB39wB39wB39wB39wB39wB39wB39wB39wB39wB39wB39wB0AAAAuZXXzAAAAdHRSTlMARdPiZDX7Zq7wCwb28lQ99ASohYC4gprNdnSgKSiDmJzOhDL9X8tdD9rWB/ouEslAPANC4XxxwLewpTC2lwFhf/zPVuTGMQVEYGV6VTYg+FrMH+XvOALHSHUQRgo3JznogewvkcTdOhMYPr47DJAcfum8WPsvIGwAAAABYktHRACIBR1IAAAACXBIWXMAAAsSAAALEgHS3X78AAABY0lEQVQ4y3XS+TsCQRgH8BeJEslduSVHEZLIWbkKubpEbrnv7///eGfRsTv7/WVm57PP7My7L1ExVdWoMZA0tUZw6qRWLwgms4QaLIChEWiSmBVotrUArRJrA9qpA+iUWBdgJwfg1FI3n6OHeoE+rfUDA0ROYFBDQy5gmMgNjKhpdAwYt5HHC0yoaNIHuKaIpvkeM5Xkn+WDBHgyxxYMBucXQoOLvyVeErVaXuHpKkpZ4+eAmIQdymvmSBlGidZ52Nj8231rOyYS3+Gz+YhMwK72unuAUbGE1haBfT07AA71zA94j8glta1jUXVIjeJAUs9SXEUdS2eAjI5leTmrYyeie3QsprSB3Lh9XDZhOa2d8vKZ3KLcHG5lT6uaPEnAklIMkXylnXM1L3i8VP77lafc8sC1GG9u74QW7PeJROIhx3l8ehZF+c1LAZrE//d4fQurKJQufeH9I/hZdNPXt+hI+gEvaI4PV5DBLAAAAABJRU5ErkJggg==") center no-repeat;
		    height: 50px;
		    width: 50px;
		    top: -13px;
		    left: 128px;
		}
	}
	.bl7{
		height: 850px;
		background: url(../images/bg7.jpg) repeat-x center;
		h1{
			font-weight: 700;
		    font-size: 46px;
		    padding-top: 103px;
		    color: #fff;
		}
		h2{
			text-align: center;
			margin-top: 19px;
		    font-size: 19px;
		    color:white;
		    font-weight: 300;
		}
		form{
			margin-top: 72px;
		    margin-left: 53px;
		    height: 50px;
		    color: white;
		    position: relative;
			.eq-7bl{
				float: left;
				margin-right: 50px;
				border: 2px solid @yellow;
				border-radius: 9px;
				font-style:italic;
				padding: 5px 0 0 25px;
				font-size:17px;
				height: 50px;
				background:none;
				color: white;
			}
			.eq-7bl::-webkit-input-placeholder {color:#fff;}
			.eq-7bl::-moz-placeholder          {color:#fff;}
			.eq-7bl:-moz-placeholder           {color:#fff;}
			.eq-7bl:-ms-input-placeholder      {color:#fff;}

			.long{width: 400px;}
			.supalong{width: 850px;}
			.metr{
				width: 160px;
				color: white;
				margin-top: 50px;
				margin-right: 40px;
				background: url('../images/bghackcalc.png') no-repeat 25px 16px;
			}
			.metr:focus{background: none} 
			.sloi{
			    width: 200px;
			    color: #fff;
			    margin-top: 50px;
			    margin-right: 50px;
			}
			
			.adres-7bl{margin-top: 50px;}
			input[type="submit"]{
				background: @yellow;
			    border-radius: 15px;
			    height: 70px;
			    width: 700px;
			    float: left;
			    border: 0;
			    cursor: pointer;
			    margin-top: 40px;
			    margin-left: 75px;
			    font-size: 24px;
			    padding-left: 0;
			    font-style: normal;
			    font-family: 'Open Sans',sans-serif;
			    font-weight: 700;
			    text-transform: uppercase;
			    transition: all 0.3s ease;
			}
			input[type="submit"]:hover{
				background: white;
				color: black;
			}
			p:first-of-type{
				font-style: italic;
			    font-size: 19px;
			    letter-spacing: -0.6px;
			    text-decoration: underline;
			    margin-top: 43px;
			    margin-left: 258px;
			}

			//Drop Down
			.wrapper-dropdown-1 {
				/* размер и позиционирование */
				position: relative;
				margin-top: 50px;

				/*Общие стили */
				background: none;
				color: #fff;
				outline: none;
				cursor: pointer;
				padding: 13px 0 0 20px;

				/* Настройки шрифтов */
				//font-weight: bold;
			}
			.wrapper-dropdown-1:after {
				content: "";
				width: 0;
				height: 0;
				position: absolute;
				right: 16px;
				top: 50%;
				border-color: #fff transparent;
				border-width: 6px 6px 0 6px ;
				margin-top: -3px;
				
				border-style: solid;
				//border-color: transparent #fff;
			}
			.wrapper-dropdown-1 .dropdown {
				/* размер и позиция */
				position: absolute;
				top: 100%;
				width: 400px;
		    	left: -2px;right: 0;

				/* Стили */
				background: #000;
				color: white;
				font-weight: normal;
				border-left: 2px solid @yellow;
				border-right: 2px solid @yellow;
				border-bottom: 2px solid @yellow;
				border-bottom-right-radius: 9px;
				border-bottom-left-radius: 9px;

				opacity: 0;
				pointer-events: none;
			}
			.wrapper-dropdown-1 .dropdown li a {
				display: block;
				text-decoration: none;
				font-weight: normal;
				font-style: normal;
				color: #fff;
				padding: 5px 20px;
			}

			/* Состояние при наведении курсора */
			.wrapper-dropdown-1 .dropdown li:hover a {
				background: #342707;
			}
			.wrapper-dropdown-1 .dropdown li:last-of-type:hover a {
				background: #342707;
				border-bottom-left-radius: 9px;
				border-bottom-right-radius:9px;
			}
			/* активное состояние */
			.wrapper-dropdown-1.active .dropdown {
				opacity: 0.9;
				pointer-events: auto;
			}

			.wrapper-dropdown-1.active:after {
				border-color: #fff transparent;
				border-width: 0 6px 6px 6px ;
				margin-top: -3px;
			}

			.wrapper-dropdown-1.active {
			 background: #000;
			 border-bottom-left-radius: 0;
			 border-bottom-right-radius: 0;
			}
		}
		p{
			float: left;
			color:black;
			text-align: center;
			color: white;
		}
		p.last{
			font-size: 17px;
		    margin-top: 49px;
		    margin-left: 198px;
		    letter-spacing: -0.05px;
		    padding-left: 44px;
		}
		p.last:before{
			content: '';
			background: url("data:img/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAeCAMAAAAB8C7XAAAABGdBTUEAALGPC/xhBQAAACBjSFJNAAB6JgAAgIQAAPoAAACA6AAAdTAAAOpgAAA6mAAAF3CculE8AAABklBMVEUAAAD9vx39vx39vx39vx39vx39vx39vx39vx39vx39vx39vx39vx39vx39vx39vx39vx39vx39vx39vx39vx39vx39vx39vx39vx39vx39vx39vx39vx39vx39vx39vx39vx39vx39vx39vx39vx39vx39vx39vx39vx39vx39vx39vx39vx39vx39vx39vx39vx39vx39vx39vx3////////////////////////////////////////////////////////////////////9vx3////////9vx39vx39vx39vx3////////9vx39vx39vx39vx39vx3////////9vx39vx39vx39vx39vx39vx39vx39vx39vx39vx39vx39vx39vx39vx39vx39vx39vx39vx39vx39vx39vx39vx39vx39vx39vx39vx39vx39vx39vx39vx39vx39vx39vx39vx39vx39vx39vx39vx39vx39vx39vx39vx39vx39vx39vx39vx39vx3///8AAAB+575XAAAAg3RSTlMAC53EIkPc9GsBJayujPzBLjSn4FLS/aUQIUR/0el1CANm586ARRLIZQkGYMr3qB5upvXQG6E53HkCFxXTsgaC/FtG+/3bZLz45PkFvgr7sD9drxkEB+L+OPruDFvZD7x6q7mNzAQNzboVqusz8Fg93iqHspSz33Nc5e9iComgFivD1F68sOAAAAABYktHRACIBR1IAAAACXBIWXMAAAsSAAALEgHS3X78AAABQUlEQVQoz2NggANGJmYWBiyAla25mZ2DE12Yi7u5mYeXr5lfAEVYUIivWVgESIs2i4lDpSQkpaRlmptl5eRBPAVFpeZmZRVVLjWGZhBQ19CEadbS1gELMTTr6mlIotqnb2BoBJQwxuZEY1pKmJiaYZUwMW+xQJGwtAKLW9u02NqhSNg7OCKLIyScnF0ckcTBEq5glqOLi5tNiztUnIHNg8HTyxsq09Li4wsLMD9hBv9mKQjH0cEdJs4Q0MzDENgcBOUFh8BdzdSswRAaFh6B7m+WyKhoBoaY5tg4VHGF+OYEEJ3YnKSFIpHcnJIKotPSmzOiEcKcvM2y0GSUadwsCo/EtKzm7BwYJy6jOTcPwsx3bS4oRLKuKLy5WB+ovCS8uVQNxcKAsmb28orK5qpqdLfX1PoBE01dPZbobGhsykfwAEfAVBL3OURQAAAAAElFTkSuQmCC") no-repeat;
			height: 30px;
		    width: 25px;
		    position: absolute;
			margin-left: -47px;
	    	margin-top: -3px;
		}
	}
	.bl8{
		background-color:white;
		h1:first-of-type{
			margin-top: 103px;
		    font-size: 47px;
		    letter-spacing: 0.7px;
		    font-weight: 700;
			overflow: hidden;
		}
		h1:first-of-type:before,h1:first-of-type:after{
			content: '';
			display: inline-block;
			vertical-align: middle;
			position: absolute;
			width: 100%;
			height: 4px;
			position: relative;
			top: -6px;
		}
		h1:first-of-type:before{
			margin-left: -100%;
			left: -45px;
			background-color: @red;
		}
		h1:first-of-type:after{
			background-color: @yellow;
			margin-right: -100%;
		    right: -45px;
		}

		h2{
			text-align: center;
			margin-top: 75px;
		    font-weight: 700;
		    font-size: 18px;
		    letter-spacing: 0.5px;
		}
		.contacts{
			margin-top: 15px;
		    margin-left: 315px;
		    a{
				font-weight: 700;
			    font-size: 18px;
		    }
		    .dot{
		    	color:@yellow;
		        font-size: 20px;
	    		padding: 0 5px;
		    }
		}
		.map{
			height: 450px;
			margin-top: 72px;
		}
	}
	footer{
		background-color: @yellow;
		height: 30px;
		color: black;
		.foot1{
	        color: black;
	        float: left;
	        font-size: 14px;
			letter-spacing: -0.075px;
	    }
	    .foot2{
			float: right;
			font-size: 14px;
			letter-spacing: -0.075px;
	    }
	    .company-info{
	    	font-size: 11px;
		    font-weight: 700;
		    padding: 6px 0 0px 7px;
		    letter-spacing: 0.5px;
	    }
	    .dviga-info{
	    	font-size: 11px;
		    font-weight: 700;
		    padding: 6px 11px 0px 0px;
		    letter-spacing: 0.5px;
	        text-transform: none;
	        a{
	            text-transform: none;
	        }
	        .linktodviga{
	            text-transform: uppercase;
	            text-decoration: underline;
	            color: black;
	            font-weight: 800;
	        }
	    }
	}


	//-----------POPUP STYLES-------------------
	    .overlay{
	        outline: none;
	        overflow: hidden;
	        position: fixed;
	        top: 0;
	        left: 0;
	        bottom: 0;
	        right: 0;
	        background: rgba(0, 0, 0, 0.5);
	        z-index: 300;
	        display: none;
	    }
	    .popup_body{
			overflow:hidden;
		    outline: none;
		    position: fixed;
		    padding-left:121px;
		    top: 50%;
		    left: 50%;
		    width: 800px;
		    height: 550px;
		    margin: -275px 0 0 -400px;
		    background: white;
		    z-index: 400;
		    display:none;
		    a.close{
	            position: relative;
	            z-index: 1100;
	            float: right;
	            margin: 26px 37px 0 0;
	            display: block;
	            width: 30px;
	            height: 30px;
	            padding: 25px;
	            background: url('../images/close.png') no-repeat center;
	        }
	        h1.pp-h1{
	        	font-size: 22px;
			    font-weight: 800;
			    margin-top: 38px;
			    letter-spacing: -0.4px;
			    text-transform: uppercase;
			    text-align: left;
	        }
	        p.pp-p{
			   	font-size: 15px;
			    padding-top: 10px;
	        }
	        form{
	            margin-top: 25px;
	            transition: all 0.3s ease;
	            .title{
	                font-size: 18px;
	    			padding-left: 1px;
	            }
	            .titels{
	            	height: 50px;
	            	width: 500px;
	            }
	            .t2{
					float: left;
					    padding-top: 23px;
					    padding-right: 277px;;
	            }
	            .t3{
	            	float: left;
	            	    padding-top: 23px;
	            }
	            .same-box{
	                width: 590px;
	                height: 40px;
	                border-radius: 9px;
				    margin-top: 12px;
				    margin-left: -17px;
				    border: 1px solid @yellow;
				    font-size: 15px;
	                font-style: italic;
	                padding-left: 18px;
	            }
	            .pp-txt{
	                margin-top: 13px;
	            }
	            .pp_phone{
	                width: 320px;
	                    margin-right: 43px;
	            }
	            .pp_metr{
	            	width: 240px;
	            	background: url('../images/bghac.png') no-repeat 18px 11px;
	            }
	            .pp_metr:focus{
	            	background: none;
	            }
	            .pp_subm{
	                margin-top:34px;
	                height: 50px;
					font-size: 22px;
					font-weight: 800;
					padding-left: 0;
					font-style: normal;
	                text-align: center;
	                background: @yellow;
	                cursor: pointer;
	                transition: background 0.3s ease;
	            	transition: color 0.3s ease;
	            }
	            .pp_subm:hover{
	                background: black;
	                transition: background 0.3s ease;
	                color: white;
	            	transition: color 0.3s ease;
	            } 
	        }
	        p{color:black;}
	        p:nth-of-type(2){
				font-style: italic;
			    font-size: 13px;
			    letter-spacing: -0.5px;
			    text-decoration: underline;
			    margin-top: 28px;
			    margin-left: 168px;
			}
			p:nth-of-type(3){
				font-size: 13px;
			    margin-top: 39px;
			    margin-left: 60px;
			    letter-spacing: -0.05px;
			    padding-left: 44px;
			}
			p:nth-of-type(3):before{
				content: '';
				background: url("data:img/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAZCAMAAAAGyf7hAAAABGdBTUEAALGPC/xhBQAAACBjSFJNAAB6JgAAgIQAAPoAAACA6AAAdTAAAOpgAAA6mAAAF3CculE8AAABcVBMVEUAAAD9vx39vx39vx39vx39vx39vx39vx39vx39vx39vx39vx39vx39vx39vx39vx39vx39vx39vx39vx39vx39vx39vx39vx39vx39vx39vx39vx39vx39vx39vx39vx39vx39vx39vx39vx39vx39vx39vx39vx39vx39vx39vx0jHyAjHyAjHyAjHyAjHyAjHyAjHyAjHyAjHyAjHyAjHyD9vx0jHyAjHyAjHyAjHyAjHyD9vx39vx39vx39vx0jHyAjHyD9vx39vx39vx39vx0jHyAjHyAjHyD9vx39vx39vx39vx39vx39vx39vx39vx39vx39vx39vx39vx39vx39vx39vx39vx39vx39vx39vx39vx39vx39vx39vx39vx39vx39vx39vx39vx39vx39vx39vx39vx39vx39vx39vx39vx39vx39vx39vx39vx39vx39vx39vx39vx39vx39vx39vx39vx0jHyAAAADKBNVgAAAAeHRSTlMADKLCIU7i82sBQcFMMNDKQCZxfwsF781uKALn99p3EA503P3o0nIzds+AaUKIgjJJsgSbZgaID8n9W4aL/vCozPyquOkEFcut5rdgXUpp2+URBtTrCrqWW7IJjJ2Byx0NrGP5WkTfjwM02LkjFVCH+/GbFCrD0Tkzf4J3AAAAAWJLR0QAiAUdSAAAAAlwSFlzAAALEgAACxIB0t1+/AAAAQBJREFUKM9jYIACRiZmFgY0wMpWUcHOwYksxMVdwcbDy1fBLwAV4BQU4qtgFxZhYBAVEqsQl5CUYmCQlqmokJWThyhQUFSqqFBWYahQVeNFNkldQ7OCoUIL3U4tsgW1dTAFdSv1kAT1DSBihkZIgsaVJmAxU5iZZkDa3MLSyhoqxmBjy2BnD2KYOzjCxBicVBicXVxBLDd3D6iYlKcXg3eFD6ozfSv8GET9AwKRxYKCQ0KBYWkbFoQkGF4RAaI0KiKjYEKc0RUxEFZsRVw8hJXgVJEoCpVPShZLAdGpaRXpGXCDMrMqsnNyYypC8pDtjMovkCmsKCpGC8WS0rJyGBsA5ok3Yxojg1IAAAAASUVORK5CYII=") no-repeat;
				height: 30px;
			    width: 25px;
			    position: absolute;
				margin-left: -47px;
			    margin-left: -40px;
			    margin-top: -4px;
			}
		}
}//desktop styles end
@media screen and (max-width: 720px){
	.body_desktop{display:none}
	.body_mobile{display:block}

	html,body{
		margin: 0;
		padding: 0;
		min-width: 320px;
		font-family: 'Open Sans',sans-serif;
		.wrap{
			min-width: 320px;
			max-width: 320px;
			margin: auto;
		}
		.rel{position: relative;}
	}
	
	.scroll-call{
		display:none;
		position: fixed;
		top:0;
		background:#fff;
		min-height: 50px;
		z-index:300;
		ul {
			padding-left: 19px;
			height: 50px;
			li.cl{
				float: left;
			    font-size: 12px;
			    font-weight: 800;
			    padding-top: 18px;
			    letter-spacing: -0.9px;
			    text-transform: uppercase;
			}
			li:first-child{padding-right: 5px;}
			li:nth-child(2){color: @yellow; padding-right: 8px;}
			li:nth-child(3){letter-spacing:0}
		}
		ul:after{
			content: '';
			display: inline-block;
			vertical-align: middle;
			position: absolute;
			width: 100%;
			height: 50px;
			top: -35px;
			left: 280px;
			position: relative;
			background:@yellow ;
		}
		ul:before{
			content: '';
			display: inline-block;
			vertical-align: middle;
			position: absolute;
			width: 100%;
			height: 50px;
		    right: 320px;
			top: 0;
			background:white;
		}
		.truba{
			position: absolute;
			top:0;
			height: 50px;
			width: 45px;
			right: 0px;
			border-radius:25px;
			background-color:@yellow;
			padding:15px 0 0 14px;
			//background:@yellow url("data:img/png;base64,iVBORw0KGgoAAAANSUhEUgAAABUAAAAVCAMAAACeyVWkAAAABGdBTUEAALGPC/xhBQAAACBjSFJNAAB6JgAAgIQAAPoAAACA6AAAdTAAAOpgAAA6mAAAF3CculE8AAABNVBMVEUAAAD///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////8AAABvv0tqAAAAZXRSTlMAPLn90CkHl/ijCsDybRj78a/cJ7xIHmid7REa/u4BzjAo7wyZamv5WbcJ+pAL/PQglpLN1bQGi8gFIeaxCLDrrGUWlS3Jg6ov9YUk2DElcL1NDqZ0HMVvv4cXI0RcHQKioBRmD0TCpbEAAAABYktHRACIBR1IAAAACXBIWXMAAAsSAAALEgHS3X78AAABBUlEQVQY012Q6VICMRCERxHUZTWy4HrhiRglEjxQLm9QWMGDRbxv7fd/BcNiURvyoyvz1WSmO0REA4OBoSDpJzQMjGBUh0YY5tg4mwhpNAIrShTDpEYBW+kUpjU6g1mlc/H5BT9dxJLS5RUk/HQVSaI1jpg2YR0blBLxTUOjaVPahsxs9aXYxo4as6v3ki1EMJ3F3r73suckh3yhWMJBig6PcHxyetbNbKFMlRLO7QtwAKxa7OCag0uqN8CZvLq+uWXIe91NgXLBbSFw16naknVHNx3cR8l1veIBj/8bKxb407N3fTHx2vvmnARvvb1/tBv49PmufwllgGf6A33/VLMOwr9EfzRSLjjFwnc+AAAAAElFTkSuQmCC") no-repeat  15px 15px;
		}

	}
	.menu-holder{
		min-height: 159px;
		width: 100%;
		z-index: 100;
		.wrap{
			background: white;
		}
		.logo{
			margin: 32px 0 0 111px;
			display:inline-block;
			width: 99px;
			height: 102px;
		}
		.flag{
			margin-top: 15px;
		    margin-right: 12px;
			float:right;
			padding:15px;
			cursor: pointer;
			.f{
				width: 25px;
				height: 3px;
				margin-bottom: 3px;
			}
			.f1{background-color: #000;}
			.f2{background-color: #ff3d3d;}
			.f3{background-color: #fec01d;}
		}
		.burger-menu {
			float:right;
			position: relative;
			width: 25px;
			height: 16px;
			cursor: pointer;
			margin-top: 30px;
	    	margin-right: 27px;
	    	.burger {
				position: absolute;
				background: rgba(236,28,35,1);
				width: 100%;
				height: 3px;
				//top: 50%;
				right: 0px;
				margin-top: 6px;
				opacity: 1;
			}
			.burger::before,.burger::after {
				content: "";
				position: absolute;
				display: block;
				width: 25px;
				height: 3px;
			}
			.burger::before {
				background: rgba(253,192,29,1);
				top: 6px;
			}
			.burger::after {
				background: rgba(0,0,0,1);
				bottom: 6px;
			}
		}
		.burger::after, .burger::before, .burger {
			transition: all .3s ease-in-out;
			-webkit-transition: all .3s ease-in-out;
		}
		.burger-menu.menu-on .burger::after{
			background: rgba(0,0,0,1);
			transform: rotate(-45deg);
			-webkit-transform: rotate(-45deg);
			bottom: 0px;
		}
		.burger-menu.menu-on .burger::before{
			background: rgba(0,0,0,1);
			transform: rotate(45deg);
			-webkit-transform: rotate(45deg);
			top: 0px;
		}
		.burger-menu.menu-on .burger{
			background: rgba(111,111,111,.0);
		}
		
		.off{display:none}
		.on{display:block}
		#menu{
			top:159px;
			color: black;
			width: 100%;
			padding-top: 27px;
			background:white;
			z-index:990;
			li{
			    font-family: 'Open Sans Condensed',sans-serif;
			    text-align: center;
			}
			.item{
				font-size: 17px;
			    padding: 10px 0px 10px 0;
			    font-weight: 700;
			    text-transform: uppercase;
			    letter-spacing: -1.4px;
			}
			.item:hover{color: @red}
			.btn:first-letter{
				text-transform: capitalize;
			}
			.btn-hold{
				background:white;
				height: 99px;
				width: 100%;
				.btn{
					float: left;
					text-align: none;
					font-size: 17px;
					font-weight: 700;
					letter-spacing: -1.2px;
					
					margin-top: 32px;
					margin-left: 70px;
					padding: 6px 20px 8px 20px;
					background: @yellow;
					border-radius: 9px;
					cursor:pointer;
				}
				.btn:hover{
					color:white;
					background:black;
				}
			}
		}
	}
	.bl1{
		width: 100%;
		header.tnxx{height: 272px;}
		header{
			height: 322px;
			background: @yellow url('../images/mob/mbg1.jpg') repeat-x center top;
			color: white;
			#danke{
				text-align: center;
			    text-transform: uppercase;
			    padding-top: 41px;
			    letter-spacing: 1.2px;
			    line-height: 60px;
			    font-size: 47px;
			}
			.tnx{
				text-align: center;
			    font-size: 15px;
			    padding-top: 28px;
			}
			h1:first-of-type{
				text-align: center;
				text-transform: uppercase;
				padding-top: 22px;
			    letter-spacing: 1.2px;
			    line-height: 50px;
			    font-size: 33px;
			}
			.flat{
				float: left;
				margin: 25px 0 0 35px;
    			padding: 7px 17px 7px 20px;
				text-transform: uppercase;
			    font-weight: 700;
			    font-size: 18px;
			    text-align: center;
			    letter-spacing: 0.8px;
				border: 2px solid @yellow;
				border-radius: 15px;
				font-style:normal;
			}
			ul{
				margin: 35px 0 0 45px;
			    letter-spacing: -1px;
			    float: left;
			    height: 35px;
				li{
					padding-right: 12px;
					float: left;
					text-transform: uppercase;
					font-weight: bold;
					font-size: 14px;
				}
				li:nth-child(even){color: @yellow;margin-top: -1px;}
				li:first-of-type{padding-right: 12px;}
				li:nth-of-type(3){padding-right: 11px;letter-spacing: 0px;}
				li:last-of-type{letter-spacing: 0.2px; padding-right:0;}
			}
		}
		.forma{
			height: 600px;
			background: url('../images/mob/mbg2.jpg') repeat-x center top;
			color: black;
			h2, h3{text-align: center}
			h2{
				text-transform: uppercase;
			    padding-top: 31px;
			    font-size: 24px;
			    font-weight: 700;
			    //letter-spacing: -1.9px;
			}
			h3{
			    margin-top: 15px;
			    font-size: 13px;
			    letter-spacing: -.6px;
			    line-height: 14px;
			}
			form{
				height: 300px;
			    margin-left: 50px;
				padding-top: 34px;
				.eq-inp{
					float: left;
					background: #fff;
					height: 41px;
					width: 220px;
					border: 2px solid white;
					border-radius: 9px;
					font-style:italic;
					padding-left: 43px;
				}
				.eq-inp::-webkit-input-placeholder {color:#6f6f6f;}
				.eq-inp::-moz-placeholder          {color:#6f6f6f;}
				.eq-inp:-moz-placeholder           {color:#6f6f6f;}
				.eq-inp:-ms-input-placeholder      {color:#6f6f6f;}
				
				.name-1bl{background:#fff url(../images/mob/man1.svg)no-repeat 11px 9px;background-size: 17px 18px;}
				.tel-1bl{background:#fff url(../images/mob/phone1.svg) no-repeat 11px 11px;margin-top: 30px;background-size: 17px 18px;}
				.metr{
					background:#fff url(../images/mob/metr1.svg) no-repeat 6px 9px;
					margin-top: 30px;
					background-size: 26px 18px;
					position: relative;
					input{
						border:none;
						height: 38px;
					}
					.s{
						position: absolute;
						top: 6px;
						font-style:italic;
						font-size: 13px;
						color:#6f6f6f;
					}
				}
				
				input[type="submit"]{
					border:0;
					cursor: pointer;
					font-weight: bold;
					font-size:18px;
					padding-left: 0;
				    margin-top: 35px;
				    width: 150px;
				    margin-left: 35px;
				    font-style:normal;
				}
				input[type="submit"]:hover{
					background: black;
					color: white;
				}
				p{
					color:black;
					text-align: center;
					float: left;
					
				}
				p:first-of-type{
					font-style: italic;
					font-size: 11px;
				    font-weight: 700;
				    text-decoration: underline;
				    margin-top: 33px;
					margin-left: -2px;
    				letter-spacing: -0.1px;
				}
			}
		}
		p.warrant{
		    font-size: 10px;
		    font-weight: 700;
		    margin-top: 26px;
		    letter-spacing: -0.1px;
		    padding-left: 92px;
		    float: left;
		}
		p.warrant:before{
			content: '';
			background: url('../images/mob/war1.svg') no-repeat;
			background-size: 24px 29px;
			height: 30px;
		    width: 25px;
		    position: absolute;
		    margin-left: -42px;
			margin-top: 0px;
		}
 		.machine{
			position: absolute;
			width: 295px;
			height: 160px;
			background: url('../images/mob/machine.png') no-repeat 0 0;
			z-index:200;
			overflow:hidden;
			top: 540px;
    		left: 18px;
		}
	}
	.bl2{
		min-height: 650px;
		h1.lines{
			padding-top: 160px;
		    text-align: center;
		    text-transform: uppercase;
		    font-weight: 700;
		        letter-spacing: 1.1px;
		    font-size: 22px;
		    line-height: 34px;
		    overflow: hidden;
		}
		h1.lines:before,h1.lines:after{
			content: '';
			display: inline-block;
			vertical-align: middle;
			position: absolute;
			width: 100%;
			height: 4px;
			position: relative;
		}
		h1.lines:before{
			margin-left: -100%;
			left: -40px;
			background-color: @red;
			top: 32px;
		}
		h1.lines:after{
			background-color: @yellow;
			margin-right: -100%;
		    right: -17px;
		    top: -36px;
		}

		.-mob-slider-box{
			width: 320px;
			height: 410px;
			position: relative;
			overflow: hidden;
			.ar{
				position: absolute;
				top: 50px;
				width: 20px;
			    height: 25px;
			    padding: 25px;
			    z-index: 20;
			    cursor: pointer;
			}
			.L{
			    left: 30px;
			    background: url('../images/mob/yelar.png') no-repeat 20px;
			}
			.R{
			    left: 240px;
			    background: url('../images/mob/yelar.png') no-repeat -50px;
			}
			.L:hover{
			    left: 30px;
			    background: url('../images/mob/blar.png') no-repeat 20px;
			}
			.R:hover{
			    left: 240px;
			    background: url('../images/mob/blar.png') no-repeat -50px;
			}
			
			.-mob-slider{
				width: 320px*4;
				height: 330px;
				position:relative;
				.-mob-slider-bl{
					float: left;
					width: 320px;
					height: 330px;
					top: 0;
					.ico{
						height: 100px;
						width: 100px;
						margin: 15px 0 0 108px;
						.icoico{float: left;}
					}
					.ic1{
						background: url(../images/mob/ok.svg) no-repeat 18px 14px;
						background-size: 67px 77px;
					}
					.ic2{
						background: url(../images/mob/shield.svg) no-repeat 22px 25px ;
						background-size: 61px 69px;
					}
					.ic3{
						background: url(../images/mob/time.svg) no-repeat 0px 24px;
						background-size: 99px 70px;
					}
					h1{
						margin-top: 26px;
					    font-size: 18px;
					    font-weight: 700;
					    text-align: center;
					    letter-spacing: -0.1px;
					    text-transform: uppercase;
					}
					p{
						margin-top: 29px;
					    text-align: left;
					    padding-left: 18px;
					    font-family: 'Open Sans semibold',sans-serif;
					    font-size: 11.5px;
					    letter-spacing: 0.2px;
					    font-weight: 700;
					    line-height: 18px;
					}
				}
				.-mob-slbl3{.ico{margin:15px 0 0 90px;}}
			} 
			.-mob-slbl1{left: 0;}
			.-mob-slbl2{left: 320px;}
			.-mob-slbl3{left: 640px;}
			.-mob-slbl4{left: 980px;}
		}
	}
	.bl3{
		background: @yellow url('../images/mob/mbg3.jpg') repeat-x center;
		.-mob-slider-box2{
			width: 320px;
			height: 550px;
			position: relative;
			overflow: hidden;
			.ar2{
				position: absolute;
				top: 42px;
				width: 20px;
			    height: 25px;
			    padding: 25px;
			    z-index: 20;
			    cursor: pointer;
			}
			.L2{
			    left: 30px;
			    background: url('../images/mob/whar.png') no-repeat 20px;
			}
			.R2{
			    left: 240px;
			    background: url('../images/mob/whar.png') no-repeat -190px;
			}
			.L2:hover{
			    left: 30px;
			    background: url('../images/mob/blar.png') no-repeat 20px;
			}
			.R2:hover{
			    left: 240px;
			    background: url('../images/mob/blar.png') no-repeat -50px;
			}

			.warkers{
				height: 400px;
				width: 650px;
				top: 143px;
				left:60px;
				position: absolute;
				background:url('../images/mob/warkers.png') no-repeat 0;
			}
			
			.-mob-slider2{
				width: 320px*4;
				height: 550px;
				position:relative;
				.-mob-slider-bl2{
					float: left;
					width: 320px;
					height: 550px;
					top: 0;
					color: white;
					h1{
						margin-top: 43px;
					    font-size: 32px;
    					font-weight: 700;
					    text-align: center;
					    letter-spacing: -0.1px;
					    text-transform: uppercase;
					}
					p{
						margin-top: 29px;
					    text-align: left;
					    padding-left: 18px;
					    font-family: 'Open Sans semibold',sans-serif;
					    font-size: 11.5px;
					    letter-spacing: 0.2px;
					    font-weight: 700;
					    line-height: 18px;
					    text-align: center;
					}
				}
			} 
			.-mob-slbl1{
				background:red;
				left: 0;
			}
			.-mob-slbl2{
				background:blue;
				left: 320px;
			}
			.-mob-slbl3{
				background:yellow;
				left: 640px;
			}
			.-mob-slbl4{
				background:pink;
				left: 980px;
			}
		}
	}
	.bl4{
		background: white;
		.-mob-slider-box3{
			width: 320px;
			height: 480px;
			position: relative;
			overflow: hidden;
			.ar3{
				position: absolute;
				top: 50px;
				width: 20px;
			    height: 25px;
			    padding: 25px;
			    z-index: 20;
			    cursor: pointer;
			}
			.L3{
			    left: 9px;
			    background: url('../images/mob/yelar.png') no-repeat 20px;
			}
			.R3{
			    left: 258px;
			    background: url('../images/mob/yelar.png') no-repeat -50px;
			}
			.L3:hover{
			    left: 9px;
			    background: url('../images/mob/blar.png') no-repeat 20px;
			}
			.R3:hover{
			    left: 258px;
			    background: url('../images/mob/blar.png') no-repeat -50px;
			}

			.compare{
			    margin-top: 24px;
			    line-height: 34px;
			    font-size: 23px;
			    font-weight: 700;
			    text-align: center;
			    //letter-spacing: -1.1px;
			    text-transform: uppercase;
			}
			
			.stajka{
				position: absolute;
				top: 164px;
			    border: 2px solid @yellow;
			    border-radius: 9px;
			    float: left;
			    font-weight: 700;
			    font-size: 18px;
			    padding: 5px 19px 7px 20px;
			}
			.wet{
				border-bottom-right-radius: 0;
				border-top-right-radius: 0;
				border-right:0;
				right:0;
				padding-right: 36px;
			    padding-left: 36px;
			}
			.dry{
				background: @yellow;
				border-bottom-left-radius: 0;
				border-top-left-radius: 0;
				border-left:0;
				padding-left:23px;
			}
			.do{
				position: absolute;
				color:@yellow;
				font-size:36px;
				font-weight: 800;
				left:152px;
			}
			.dot1{top: 275px;}
			.dot2{top: 397px;}

			.-mob-slider3{
				width: 320px*5;
				height: 480px;
				position:relative;
				.-mob-slider-bl3{
					float: left;
					width: 320px;
					height: 480px;
					top: 0;
					//color: white;
					h1{
					    font-size: 16px;
					    text-align: center;
					    letter-spacing: 0.4px;
					}
					
					h1:nth-of-type(2){margin-top: 88px;letter-spacing: 0.8px;}
				}
			} 
			.-mob-slbl13{
				h1:first-of-type{margin-top: 110px;}
				left: 0;
				div{float: left;text-align: center;}
				div:first-of-type{margin:29px 0 0 56px}
				div:nth-of-type(2){margin:29px 0 0 157px}
				div:nth-of-type(3){margin:27px 0 0 38px}
				div:nth-of-type(4){margin:27px 0 0 125px;}
			}
			.-mob-slbl23{
				h1:first-of-type{margin-top: 113px;}
				div{float: left;text-align: center;}
				div:first-of-type{margin:29px 0 0 56px}
				div:nth-of-type(2){margin:29px 0 0 117px}
				div:nth-of-type(3){margin:37px 0 0 68px}
				div:nth-of-type(4){margin:37px 0 0 125px;}
				left: 320px;
			}
			.-mob-slbl33{
				h1:first-of-type{margin-top: 114px;}
				div{float: left;text-align: center;}
				div:first-of-type{margin:29px 0 0 26px}
				div:nth-of-type(2){margin:29px 0 0 97px}
				div:nth-of-type(3){margin:40px 0 0 68px}
				div:nth-of-type(4){margin:40px 0 0 145px;}
				left: 640px;
			}
			.-mob-slbl43{
				h1:first-of-type{margin-top: 114px;}
				div{float: left;text-align: center;}
				div:first-of-type{margin:29px 0 0 36px}
				div:nth-of-type(2){margin:29px 0 0 117px}
				div:nth-of-type(3){margin:43px 0 0 44px}
				div:nth-of-type(4){margin:43px 0 0 145px;}
				left: 980px;
			}
			.-mob-slbl53{
				h1:first-of-type{margin-top: 114px;}
				div{float: left;text-align: center;}
				div:first-of-type{margin:24px 0 0 20px}
				div:nth-of-type(2){margin:24px 0 0 100px}
				//left: 980px;
				.best{
				    font-size: 14px;
				    text-align: right;
				    letter-spacing: .4px;
				    font-weight: 700;
				    background: @yellow url(../images/mob/thumb.svg) no-repeat 219px 11px;
				    	//"data:img/png;base64,iVBORw0KGgoAAAANSUhEUgAAABsAAAAlCAMAAACEemK6AAAABGdBTUEAALGPC/xhBQAAACBjSFJNAAB6JgAAgIQAAPoAAACA6AAAdTAAAOpgAAA6mAAAF3CculE8AAABYlBMVEUAAAD///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////8AAAAk9ovSAAAAdHRSTlMARdPiZDX7Zq7wCwb28lQ99ASohYC4gprNdnSgKSiDmJzOhDL9X8tdD9rWB/ouEslAPANC4XxxwLewpTC2lwFhf/zPVuTGMQVEYGV6VTYg+FrMH+XvOALHSHUQRgo3JznogewvkcTdOhMYPr47DJAcfum8WPsvIGwAAAABYktHRACIBR1IAAAACXBIWXMAAAsSAAALEgHS3X78AAABY0lEQVQ4y3XS+TsCQRgH8BeJEslduSVHEZLIWbkKubpEbrnv7///eGfRsTv7/WVm57PP7My7L1ExVdWoMZA0tUZw6qRWLwgms4QaLIChEWiSmBVotrUArRJrA9qpA+iUWBdgJwfg1FI3n6OHeoE+rfUDA0ROYFBDQy5gmMgNjKhpdAwYt5HHC0yoaNIHuKaIpvkeM5Xkn+WDBHgyxxYMBucXQoOLvyVeErVaXuHpKkpZ4+eAmIQdymvmSBlGidZ52Nj8231rOyYS3+Gz+YhMwK72unuAUbGE1haBfT07AA71zA94j8glta1jUXVIjeJAUs9SXEUdS2eAjI5leTmrYyeie3QsprSB3Lh9XDZhOa2d8vKZ3KLcHG5lT6uaPEnAklIMkXylnXM1L3i8VP77lafc8sC1GG9u74QW7PeJROIhx3l8ehZF+c1LAZrE//d4fQurKJQufeH9I/hZdNPXt+hI+gEvaI4PV5DBLAAAAABJRU5ErkJggg=="
				    background-size: 29px ;
				    margin-left: 22px;
				    margin-top: 70px;
				    padding: 16px 64px 16px 25px;
				    line-height: 17px;
				    border-radius: 15px;
				}
			}
		}
	}
	.bl5{
		background: #3b3b3b url('../images/mob/mbg5.jpg') repeat-x center;
		height: 800px;
		.wrap{
			color: #fff;
			h1{
				
			    padding-top: 49px;
			    text-align: center;
			    font-size: 23px;
			    letter-spacing: 0.5px;
			    line-height: 34px;
			    text-transform: uppercase;
			}
			p{
				text-align: center;
				font-size: 12px;
			    padding-top: 38px;
			    letter-spacing: 0.3px;
			    line-height: 15px;
			}

			form{
			    margin-left: 50px;
				padding-top: 51px;
				.eq-5bl{
					background: #fff;
					height: 41px;
					width: 220px;
					border: 2px solid @yellow;
					border-radius: 9px;
					font-style:italic;
					padding-left: 43px;
					color:white;
				}
				
				.name-5bl{background: url(../images/mob/man2.svg) no-repeat 9px 9px;background-size: 19px 18px;}
					
				.tel-5bl{background: url(../images/mob/phone2.svg) no-repeat 9px 9px;margin-top: 19px;background-size: 19px 18px;}
				
				.metr{
					background: url(../images/mob/metr2.svg) no-repeat 9px 9px;
					margin-top: 20px;
					padding-left: 51px;
					background-size: 26px 18px;
					position: relative;
					color:#fff;
					input{
						border:none;
						background: none;
						height: 36px;
						width: 160px;
						color:#fff;
					}
					.s2{
						position: absolute;
						top: 7px;
						font-style:italic;
						font-size: 13px;
						color:#fff;
					}
				}

				.sloi{background: url(../images/mob/sloi2.svg) no-repeat 18px 7px;margin-top: 20px;padding-left: 51px;background-size: 10px 25px;}
				
				.eq-5bl::-webkit-input-placeholder {color:#fff;}
				.eq-5bl::-moz-placeholder          {color:#fff;}
				.eq-5bl:-moz-placeholder           {color:#fff;}
				.eq-5bl:-ms-input-placeholder      {color:#fff;}
				//Drop Down
					.wrapper-dropdown-mob {
						/* размер и позиционирование */
						position: relative;
						margin-top: 20px;

						/*Общие стили */
						background: none;
						color: #fff;
						outline: none;
						cursor: pointer;
						padding: 10px 0 0 53px;
	    				font-size: 13px;
	    				background: url(../images/mob/city.svg) no-repeat 14px 9px; background-size: 19px;
	    					//"data:img/png;base64,iVBORw0KGgoAAAANSUhEUgAAABEAAAAVCAMAAACXIvXeAAAABGdBTUEAALGPC/xhBQAAACBjSFJNAAB6JgAAgIQAAPoAAACA6AAAdTAAAOpgAAA6mAAAF3CculE8AAAA8FBMVEUAAAD///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////8AAADla+TzAAAATnRSTlMAD3PF7l7r/trGf/WCGlrkLQrn9i5rhCWcwLsZLPHp1q5vDrrh4/jPG/fb6OWeAeqgMIb6UpQQl1AEIiPgA1u8uIe3Et94JI/YxECzEWU1vS0VAAAAAWJLR0QAiAUdSAAAAAlwSFlzAAALEgAACxIB0t1+/AAAAPdJREFUGNNNj+lWwlAMhAfKvpWt7KuUAhUrBUFRKpvgyrz/45jS2mN+5JszuclNAIlQWIkwooRD8CMaYzyRTMQZi3pGiulMVpjNpJlyjRzVvP84rzIHFIql8l87yqViARorIqu1eq0qrFBDg02g1SbZbgFNNtDpSqnH/k2fPVHdDjgQ6kNJQ13SgDAU4cgYY2yMRCUnMG+FGqd3UxkK697EjLaI+YKLuQXYnOGBS3eTlb1y8cgnrPXJc7Dhi7FxgFduHd9wtu4VsHbc+86ehyuPbzxdxYmbo1c6q1yu4bxTPQcDP/j5VacZGPL3t1x6+MH/uKgXy1O/t4Ahvf2eRvkAAAAASUVORK5CYII="


						/* Настройки шрифтов */
						//font-weight: bold;
					}
					.wrapper-dropdown-mob:after {
						content: "";
						width: 0;
						height: 0;
						position: absolute;
						right: 12px;
						top: 50%;
						margin-top: -2px;
						border-style: solid;
						border-width: 6px 6px 0 6px ;
						border-color:  #fff transparent;
					}
					.wrapper-dropdown-mob .dropdown-mob {
						/* размер и позиция */
						position: absolute;
						top: 100%;
						width: 220px;
				    	left: -2px;right: 0;

						/* Стили */
						background: #000;
						color: white;
						font-weight: normal;
						border-left: 2px solid @yellow;
						border-right: 2px solid @yellow;
						border-bottom: 2px solid @yellow;
						border-bottom-right-radius: 9px;
						border-bottom-left-radius: 9px;

						opacity: 0;
						pointer-events: none;
					}
					.wrapper-dropdown-mob .dropdown-mob li a {
						display: block;
						text-decoration: none;
						font-weight: normal;
						font-style: normal;
						color: #fff;
						padding: 5px 20px;
					}

					/* Состояние при наведении курсора */
					.wrapper-dropdown-mob .dropdown-mob li:hover a {
						background: #342707;
					}
					.wrapper-dropdown-mob .dropdown-mob li:last-of-type:hover a {
						background: #342707;
						border-bottom-left-radius: 9px;
						border-bottom-right-radius:9px;
					}
					/* активное состояние */
					.wrapper-dropdown-mob.active .dropdown-mob {
						opacity: 0.9;
						pointer-events: auto;
					}

					.wrapper-dropdown-mob.active:after {
						border-color: #fff transparent;
						border-width: 0 6px  6px 6px;
						margin-top: -2px;
					}

					.wrapper-dropdown-mob.active {
					 background:#000 url(../images/mob/city.svg) no-repeat 14px 9px; background-size: 19px;
					 //background: #000 url("data:img/png;base64,iVBORw0KGgoAAAANSUhEUgAAABEAAAAVCAMAAACXIvXeAAAABGdBTUEAALGPC/xhBQAAACBjSFJNAAB6JgAAgIQAAPoAAACA6AAAdTAAAOpgAAA6mAAAF3CculE8AAAA8FBMVEUAAAD///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////8AAADla+TzAAAATnRSTlMAD3PF7l7r/trGf/WCGlrkLQrn9i5rhCWcwLsZLPHp1q5vDrrh4/jPG/fb6OWeAeqgMIb6UpQQl1AEIiPgA1u8uIe3Et94JI/YxECzEWU1vS0VAAAAAWJLR0QAiAUdSAAAAAlwSFlzAAALEgAACxIB0t1+/AAAAPdJREFUGNNNj+lWwlAMhAfKvpWt7KuUAhUrBUFRKpvgyrz/45jS2mN+5JszuclNAIlQWIkwooRD8CMaYzyRTMQZi3pGiulMVpjNpJlyjRzVvP84rzIHFIql8l87yqViARorIqu1eq0qrFBDg02g1SbZbgFNNtDpSqnH/k2fPVHdDjgQ6kNJQ13SgDAU4cgYY2yMRCUnMG+FGqd3UxkK697EjLaI+YKLuQXYnOGBS3eTlb1y8cgnrPXJc7Dhi7FxgFduHd9wtu4VsHbc+86ehyuPbzxdxYmbo1c6q1yu4bxTPQcDP/j5VacZGPL3t1x6+MH/uKgXy1O/t4Ahvf2eRvkAAAAASUVORK5CYII=") no-repeat 14px 9px;
					 border-bottom-left-radius: 0;
					 border-bottom-right-radius: 0;
					}
				p{
					color:white;
					text-align: center;
				}
				p:first-of-type{
					font-style: italic;
				    font-size: 12px;
				    margin-left: -51px;
				    text-decoration: underline;
				    letter-spacing: -0.2px;
				    margin-top: 7px;
				}
				input[type="submit"]{
					border: 0;
					border-radius: 10px;
				    cursor: pointer;
				    font-weight: 700;
				    font-size: 18px;
				    padding-left: 0;
				    padding: 11px 0px 9px 5px;
				    margin-top: 40px;
				    width: 150px;
				    margin-left: 32px;
				    font-style: normal;
				    background: #fec01d;
				    letter-spacing: 0.8px;
				    background:@yellow;
				}
				input[type="submit"]:hover{
					background: white;
					color: black;
				}
			}
			
			p.warrant{
			    font-size: 10px;
				letter-spacing: 0.1px;
			    text-align: left;
			    padding-top: 34px;
			    padding-left: 93px;
			}
			p.warrant:before{
				content: '';
				background: url('../images/mob/war2.svg') no-repeat;
				height: 30px;
			    width: 25px;
			    position: absolute;
			    margin-left: -43px;
				margin-top: 0px;
			}
			.war2{
				position: absolute;
			    left: 50px;
			    top: 714px;
			}
		}
	}
	.bl6{
		h1:first-of-type{
			text-align: center;
		    margin-top: 41px;
		    font-size: 33px;
			//letter-spacing: -2.4px;
		    line-height: 48px;
		    font-weight: 700;
		    overflow: hidden;
		    text-transform: uppercase;
		}
		h1:first-of-type:before,h1:first-of-type:after{
			content: '';
			display: inline-block;
			vertical-align: middle;
			position: absolute;
			width: 100%;
			height: 4px;
			position: relative;
		}
		h1:first-of-type:before{
			margin-left: -100%;
			left: -25px;
			background-color: @red;
			top: 19px;
		}
		h1:first-of-type:after{
			background-color: @yellow;
			margin-right: -100%;
		    right: -9px;
		    top: -29px
		}

		h2{
		    font-weight: 700;
		    font-size: 18px;
		    text-align: center;
		    margin-top: 55px;
		    letter-spacing: 0.4px;
		    line-height: 28px;
		}
		.contacts{
			margin-top: 15px;
		    text-align: center;
		    a{
				font-weight: 700;
			    font-size: 18px;
		    }
		    .dot{
		    	color:@yellow;
		    	font-size: 39px;
			    padding: 0 5px;
			    font-weight: 900;
		    }
		    div:nth-of-type(2){margin-top: 20px;}
		    div:nth-of-type(3){margin-top: 10px;}
		}
		.map-mob{
			height: 450px;
			margin-top: 56px;
		}
	}
	footer{
		height: 110px;
		background:@yellow;
		color: black;
		.wrap{
			div{
				text-align: center;
			    font-size: 12px;
			    letter-spacing: -0.1px;
			    font-weight: 600;
			    a{
	            text-transform: none;
		        }
		        .linktodviga{
		            text-transform: uppercase;
		            text-decoration: underline;
		            color: black;
		            font-weight: 800;
		        }
			}
			div:first-of-type{
				padding-top: 32px;
			}
			div:last-of-type{    padding-top: 18px;}
		}
	}
	
}//mobile styles end

//input::-webkit-input-placeholder       {opacity: 1; transition: opacity 0.3s ease;}
//input::-moz-placeholder                {opacity: 1; transition: opacity 0.3s ease;}
//input:-moz-placeholder                 {opacity: 1; transition: opacity 0.3s ease;}
//input:-ms-input-placeholder            {opacity: 1; transition: opacity 0.3s ease;}
//input:focus::-webkit-input-placeholder {opacity: 0; transition: opacity 0.3s ease;}
//input:focus::-moz-placeholder          {opacity: 0; transition: opacity 0.3s ease;}
//input:focus:-moz-placeholder           {opacity: 0; transition: opacity 0.3s ease;}
//input:focus:-ms-input-placeholder      {opacity: 0; transition: opacity 0.3s ease;}